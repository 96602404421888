import {Fragment} from "react";
import Sales from "../components/Sales/Sales";
import TopNavigation from "../components/TopNavigation/TopNavigation";

export default function SalesPage(){

    return(
        <Fragment>
            <TopNavigation />
            <Sales/>


        </Fragment>
    )
}