import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Delivery from "../components/Delivery/Delivery";
import Customer from "../components/Customer/Customer";

export default function CustomerPage(){

    return(
        <Fragment>
            <TopNavigation />
            <Customer/>

        </Fragment>
    )
}