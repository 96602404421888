import {Fragment} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Password from "../UserProfile/Password";
import ChemicalUse from "./ChemicalUse";
import ChemicalEntry from "./ChemicalEntry";
import Formula from "./Formula";

export default function Chemical(){

    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>
                <h3>Chemical</h3>
                <hr/>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="use" title="Chemical Use">
                            <ChemicalUse/>
                    </Tab>
                    <Tab eventKey="entry" title="Chemical Entry">
                            <ChemicalEntry/>
                    </Tab>
                    <Tab eventKey="formula" title="Formula Entry">
                        <Formula/>
                    </Tab>

                </Tabs>
            </div>
        </Fragment>
    )

}