import {Fragment, useRef, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
import Invoice from "./Invoice";
export default function InvoiceWriteOff(){
    let state=useSelector((state)=> state);
    const ref=useRef();
    const[Data, setData]=useState([]);
    const[Column, setColumn]=useState([{}]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const[InvoiceNo,setInvoiceNo]=useState();

    const[DealerName,setDealerName]=useState();


    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{

            setDealerName(row["Dealer"]);
            setInvoiceNo(row["Invoice_No"]);
           handleShow()


        }
    }

    const invoiceWriteOff=()=>{






        RestClient.PostRequest(AppUrl.updateData,{"sql":"Update Invoices Set Status='1' Where Invoice_No='"+InvoiceNo+"'"}).then(result=> {
            if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}

        }).catch(error=>{ cogoToast.error("Data not saved")});
        RestClient.PostRequest(AppUrl.updateData,{"sql":"Update Invoice_Payment Set Status='1' Where Invoice_No='"+InvoiceNo+"'"}).then(result=> {
            if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}

        }).catch(error=>{ cogoToast.error("Data not saved")});


    }

    const getData=()=>{

            const WriteOffInvoice=document.getElementById("txtWriteOffInvoice").value;

       let InvoiceWriteOffIndex=document.getElementById("cmbInvoiceWriteOffIndex").selectedIndex;

        let  jsonObject="";
        if(InvoiceWriteOffIndex !=0){jsonObject={"sql":"Select * from Invoice_Payment Where Invoice_No='"+WriteOffInvoice+"'"}
        }else{
            jsonObject={"sql":"Select * from Invoices Where Invoice_No='"+WriteOffInvoice+"'"}

        }


        setData([])
        setColumn([{dataField:"NoData",text:"No Data found"}])
        if(WriteOffInvoice==""){cogoToast.error("Please inter Invoice No")}else {
            RestClient.PostRequest(AppUrl.getData, jsonObject).then(result => {
                if (result == null) {
                    cogoToast.error("Data not found")
                } else {
                    setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    setData(result)
                }

            }).catch(error => {
            });
        }



    }



    return(
        <Fragment>
            <div className="purchaseLongDiv text-center">

                <Row>
                    <Col l={2}>
                        <Row>
                            <Col><label>Invoice No</label></Col>
                            <Col><input type="text" id="txtWriteOffInvoice"/></Col>
                        </Row>

                        <Row>
                            <select className="m-2 w-75 " id="cmbInvoiceWriteOffIndex" >
                                <option value="0">Invoice</option>
                                <option value="0">Payment</option>

                            </select>
                        </Row>
                        <Row> <Button className="m-2 w-75" onClick={getData}>Search</Button></Row>


                    </Col>
                    <Col lg={10}>
                        <div className="purchaseDiv overflow-scroll">
                            <BootstrapTable  keyField='Order_No' data={Data} columns={Column} selectRow={selectRow}  id="tbl" > </BootstrapTable>
                        </div>
                    </Col>
                </Row>


            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to Create Invoice ?</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label className="w-25 mt-2">Name:</label><input value={DealerName} disabled={true} className="w-25"/><br/>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={invoiceWriteOff} >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}