import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import Loading from "../Loading/Loading";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";
import RM from "./RM";

export default function RmReceived(){

    let state=useSelector((state)=> state);


    let Columns=[
        {dataField: 'id', text: 'Invoice', sort: true},
        {dataField: 'Rm_Name', text: 'RM_Name', sort: true},
        {dataField: 'Qty', text: 'Qty', sort: true},
        {dataField: 'Qty_Unit', text: 'Unit', sort: true},
        {dataField: 'Seller', text: 'Source ', sort: true},
        {dataField: 'Entry_Date', text: 'Date', sort: true},
        {dataField: 'Comments', text: 'Comments', sort: true},
        {dataField: 'Status', text: 'Status', sort: true},
        {dataField: 'R_Qty', text: 'Received Qty', sort: true},
        {dataField: 'R_Date', text: 'Received Date', sort: true},


    ]
    let ColumnsReceivedPending=[
        {dataField: 'id', text: 'Pending_Id', sort: true},
        {dataField: 'Invoice_No', text: 'Invoice', sort: true},
        {dataField: 'Rm_Name', text: 'RM', sort: true},
        {dataField: 'Qty', text: 'Qty', sort: true},
        {dataField: 'Comments', text: 'Comments', sort: true},



    ]

    const [TotalPurchased, setTotalPurchased] = useState();
    const [TotalReceived, setTotalReceived] = useState();
    const [Invoice, setInvoice] = useState(0);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            let invoice=row['Invoice_No']
           setInvoice(invoice)
            RestClient.PostRequest(AppUrl.getData, {"sql":"SELECT Qty FROM `Rm_Purchase` where id='"+invoice+"' "}).then(result=>{
                if(result!=null){ handleShow();
                    setTotalPurchased(result[0]["Qty"])}else{cogoToast.error("No Data Found"); }
            }).catch(error=>{});

            RestClient.PostRequest(AppUrl.getData, {"sql":"SELECT Sum(Qty)As Qty FROM `Rm_Received`  where Invoice_No='"+invoice+"' "}).then(result=>{
                if(result!=null){setTotalReceived(result[0]["Qty"])}else{cogoToast.error("No Data Found"); }
            }).catch(error=>{});

        }
    }
    const selectRowPurchased={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setInvoice(row['id'])


        }
    }


    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());

    const [totalStock, setTotalStock]=useState(0)
    const [totalAvailbleStcock, setTotalAvailbleStcock]=useState(0)

    const [BtnTotalStock,setBtnTotalStock]=useState("success")
    const [BtnSave,setBtnSave]=useState("success")
    const [BtnAvailableStock,setBtnAvailableStock]=useState("success")
    const [BtnAvailablePack,setBtnAvailablePack]=useState("success")
    const [BtnStockDetailes,setBtnStockDetailes]=useState("success")
    const [InputPartial,setInputPartial]=useState(true)

    const  Loader=false;
    const [Data, setData]=useState([]);
    const [DataReceivedPending, setDataReceivedPending]=useState([]);

    const getPartialInput=()=>{
        const ReceivedType = document.getElementById("cmbReceivedType").selectedIndex;
        if(ReceivedType==2){setInputPartial(false)}else{setInputPartial(true)}
    }



    const changePendingStatus=()=>{

        const TotalPurched=document.getElementById("txtTotalPurchased").value;
        const TotalReceived=document.getElementById("txtTotalReceived").value;

        if(parseInt(TotalPurched)== parseInt(TotalReceived)){
            RestClient.PostRequest(AppUrl.getData, {"sql":"UPDATE Rm_Received_Pending SET Status ='1' WHERE Invoice_No = '"+Invoice+"'"}).then(result=>{

               if(result==null){cogoToast.success("Purchased Status not changed")}else{cogoToast.success("Purchased Status changed")}
            }).catch(error=>{});
            RestClient.PostRequest(AppUrl.getData, {"sql":"UPDATE Rm_Purchase SET Status ='Yes' WHERE id = '"+Invoice+"'"}).then(result=>{
                if(result==null){cogoToast.success("Received Status not changed")}else{cogoToast.success("Received Status changed")
                    handleClose();
                }

            }).catch(error=>{});


        }else{cogoToast.error("Purchased and Received Qty does not matched")}

    }
    const saveRm=()=>{


        const ReceivedType = document.getElementById("cmbReceivedType");
        const ReceivedTypeValue = ReceivedType.options[ReceivedType.selectedIndex].text;
        const Rm = document.getElementById("cmbRm");
        const RmName = Rm.options[Rm.selectedIndex].text;

        const Invoice=document.getElementById("txtInvoice").value;
        const RmQty=document.getElementById("txtQty").value;
        const RmLeftQty=document.getElementById("txtLeftQty").value;


        const Comments=document.getElementById("txtComments").value;
        const User_Name=state.name;


        let jsonObject=
            {
                "sql":"INSERT INTO `Rm_Received` (`id`, `Invoice_No`, `Rm_Name`, `Qty`, `Comments`,`Status`, `User_Name`, `Entry_Date`)" +
                    " VALUES (NULL, '"+Invoice+"', '"+RmName+"', '"+RmQty+"', '"+Comments+"','"+ReceivedTypeValue+"', '"+User_Name+"', current_timestamp()) "
            };

        if(ReceivedType.selectedIndex==0){cogoToast.error('Please Select Received Type');}else{
        if(Rm.selectedIndex==0){cogoToast.error('Please Select Rawmaterials');Rm.focus()}else{
                if(Invoice==""){cogoToast.error('Please insert invoice No');}else{
                if(RmQty==""){cogoToast.error('Insert RM Qty');}else{

                if(Comments==""){cogoToast.error('Comments  Empty');}else{



                 if(ReceivedType.selectedIndex==2){
                     if(RmLeftQty==""){cogoToast.error("Please insert value for left Qty")}else{
                         setBtnSave("secondary")
                         RestClient.PostRequest(AppUrl.saveData, {"sql":"INSERT INTO `Rm_Received_Pending` (`id`, `Invoice_No`, `Rm_Name`, `Qty`, `Comments`, `Status`, `User_Name`, `Entry_date`)" +
                                 " VALUES (NULL, '"+Invoice+"', '"+RmName+"', '"+RmLeftQty+"', '"+Comments+"', '0', '"+User_Name+"', current_timestamp()) "}).then(result=>{}).catch(error=>{});

                         RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                             if(result==1){

                                 setBtnSave("success")
                                 cogoToast.success("RM Saved Successfully");
                                 let sms="RM received: "+"Type: "+ReceivedTypeValue+", "+RmName+" Qty: "+RmQty+" Comments: "+Comments+"  Sent by "+User_Name
                                  AppUtility.sendSMS(sms,"01789307792")
                             }else {
                                 setBtnSave("success")
                                 cogoToast.error("Rm not Saved");
                             }
                         }).catch(error=>{
                             cogoToast.error(error);
                             setBtnSave("success")
                         });

                     }

                 }else{
                     RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                         if(result==1){
                             setBtnSave("success")
                             cogoToast.success("RM Saved Successfully");
                             let sms="RM received: "+"Type: "+ReceivedTypeValue+", "+RmName+" Qty: "+RmQty+" Comments: "+Comments+"  Sent by "+User_Name
                              AppUtility.sendSMS(sms,"01789307792")
                         }else {
                             setBtnSave("success")
                             cogoToast.error("Rm not Saved");
                         }
                     }).catch(error=>{
                         cogoToast.error(error);
                         setBtnSave("success")
                     });
                 }

                }}}}}

    }
    const getRmDetails=()=>{
        const ReportType = document.getElementById("cmbReportType");
        const Rm = document.getElementById("cmbRmDetails");
        const RmName = Rm.options[Rm.selectedIndex].text;

        let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);

        let reportType="";

        if(ReportType.selectedIndex==0){reportType="And  Status='No' "}
        if(ReportType.selectedIndex==1){reportType=" And Status='Yes' "}


        let SQL="";

        if(Rm.selectedIndex==1){
            SQL="SELECT * FROM `Rm_Purchase` where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "+reportType+" "
        }else{
            SQL="SELECT * FROM `Rm_Purchase` Where Rm_Name='"+RmName+"' and Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "+reportType+" "
        }


        let jsonObject=
            {
                "sql":SQL
            };

        if(Rm.selectedIndex==0){cogoToast.error('Please Select Type');Rm.focus()}else{
            if(startDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
                if(startDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{

                    setBtnStockDetailes("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                        setData( result)
                        setBtnStockDetailes("success")

                    }).catch(error=>{
                        setBtnStockDetailes("success")
                        cogoToast.error(error);
                    });

                }}}

    }
    const getRmReceivedPending=()=>{



        let jsonObject={"sql":"SELECT * FROM `Rm_Received_Pending` where Status='0' "};
                            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        setDataReceivedPending( result)
                    }).catch(error=>{

                        cogoToast.error(error);
                    });
    }

    const getPurchasedSummary=()=>{

        const RmSummary = document.getElementById("cmbRmSummary");
        const RmSummaryValue = RmSummary.options[RmSummary.selectedIndex].text;



        let jsonObject=
            {
                "sql":"select sum(Qty) As Total from Rm_Purchase where Rm_Name='"+RmSummaryValue+"'"
            };

        if(RmSummary.selectedIndex==0){cogoToast.error('Please Select RM');RmSummary.focus()}else{

            setBtnTotalStock("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        console.log(result)
                        if(result[0]["Total"]==null){document.getElementById("lblRmSummary").innerText="No RM found";
                            cogoToast.error("No Data Found");
                            setBtnTotalStock("success")
                        }else{
                            document.getElementById("lblRmSummary").innerText=result[0]["Total"];
                            cogoToast.success("Data Found");
                            setBtnTotalStock("success")
                        }
                    }).catch(error=>{
                        setBtnTotalStock("success")
                        cogoToast.error(error);
                    });
                }
    }

    const getAvailableStock=()=>{
        setBtnAvailableStock("Wait...")
        const AvailableStock = document.getElementById("cmbAvailableStock");
        const AvailableStockValue = AvailableStock.options[AvailableStock.selectedIndex].text;

        let jsonObject={"sql":"SELECT sum(Qty)-(select Sum("+AvailableStockValue+") from Coil_Cutting) As Total FROM `Rm_Purchase` WHERE Rm_Name='"+AvailableStockValue+"'"};




        if(AvailableStock.selectedIndex==0){cogoToast.error('Please Select Type');AvailableStock.focus()}else{

            setBtnAvailableStock("secondary")
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                if(result[0]["Total"]==null){
                    cogoToast.error("No Data Found");
                    setBtnAvailableStock("success")
                    document.getElementById("lblAvailableStock").innerText="0";
                }else{
                    document.getElementById("lblAvailableStock").innerText= result[0]["Total"]
                    setBtnAvailableStock("success")

                }
            }).catch(error=>{
                cogoToast.error(error);
                setBtnAvailableStock("success")
            });

    }}

    if(Loader==true){
        return(
            <Fragment>

                <Loading/>



            </Fragment>
        )
    }else{
        return(
            <Fragment>


                <div className="purchaseDiv text-center">
                    <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                    <h3>Raw Materials Received Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>

                            <Col lg={3} md={3} sm={12}>
                                <Row>
                                    <label className="customfontsize">Received Type</label>
                                    <select className="float-start w-100" id="cmbReceivedType"  onChange={getPartialInput}>
                                        <option value="0">-Select Type-</option>
                                        <option value="0">Full</option>
                                        <option value="0">Partial</option>
                                        <option value="0">Pending</option>
                                    </select>
                                </Row>
                                <Row>
                                    <label className="customfontsize">RM Name</label>
                                    <select className="float-start w-100" id="cmbRm"  >
                                        <option value="0">-Select RM-</option>
                                        <option value="0">Wood</option>
                                        <option value="0">CSP</option>
                                        <option value="0">Gum</option>
                                        <option value="0">Tabu</option>
                                        <option value="0">Starch</option>
                                        <option value="0">Color</option>
                                        <option value="0">Fogzol</option>
                                        <option value="0">Dry Oil</option>
                                        <option value="0">CF Oil</option>
                                        <option value="0">Master Carton</option>
                                        <option value="0">Inner Carton</option>
                                        <option value="0">Inner Poli</option>
                                        <option value="0">Stand</option>
                                        <option value="0">Costape</option>
                                    </select>
                                </Row>

                                <Row>
                                    <label className="customfontsize">Invoice No</label>
                                    <input id="txtInvoice" value={Invoice} type="text" className="w-100 customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Quantity</label>
                                    <input id="txtQty" type="text" className="w-100 customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Left Quantity</label>
                                    <input id="txtLeftQty" type="text" disabled={InputPartial} className="w-100 customfontsize"/>
                                </Row>


                                <Row>
                                    <label className="customfontsize">Comments</label>
                                    <textarea id="txtComments" className="customfontsize"></textarea>
                                </Row>

                                <Row >
                                    <Col lg={12} md={12} sm={12}><Button onClick={saveRm}  variant={BtnSave} className="w-100 mt-4"  >Submit</Button></Col>

                                </Row>

                            </Col>
                            <Col lg={4}>

                                    <div className="purchaseDiv text-center"> <label>RM Received Pending Report</label>


                                        <Row >
                                            <Col lg={6} md={6} sm={12}><Button onClick={getRmReceivedPending}   className="w-75 m-4">Report</Button></Col>
                                        </Row>

                                        <div id="ddlDiv" className="overflow-scroll ">
                                            <div className="tableDiv">
                                                <BootstrapTable  keyField='id' data={DataReceivedPending} columns={ColumnsReceivedPending}  selectRow={selectRow} > </BootstrapTable>
                                            </div>

                                        </div>

                                    </div>




                            </Col>

                            <Col lg={5} md={3} sm={12}>
                                <div className="purchaseDiv text-center"> <label>RM Received Detailes Report</label>

                                    <Row className="m-2">
                                        <Col lg={12} sm={12}> <select className="float-start w-50" id="cmbReportType"  >
                                            <option value="0">-Purchased Materials-</option>
                                            <option value="0">-Received Materials-</option>
                                            <option value="0">-All Materials-</option>

                                        </select></Col>

                                    </Row>
                                    <Row className="m-2">
                                        <Col lg={12} sm={12}> <select className="float-start w-50" id="cmbRmDetails"  >
                                            <option value="0">-Select RM-</option>
                                            <option value="0">All RM</option>
                                            <option value="0">Wood</option>
                                            <option value="0">CSP</option>
                                            <option value="0">Gum</option>
                                            <option value="0">Tabu</option>
                                            <option value="0">Starch</option>
                                            <option value="0">Color</option>
                                            <option value="0">Fogzol</option>
                                            <option value="0">Dry Oil</option>
                                            <option value="0">CF Oil</option>
                                            <option value="0">Master Carton</option>
                                            <option value="0">Inner Carton</option>
                                            <option value="0">Inner Poli</option>
                                            <option value="0">Stand</option>
                                            <option value="0">Costape small</option>
                                            <option value="0">Costape Large</option>
                                        </select></Col>

                                    </Row>


                                    <Row>
                                        <Col lg={3}>
                                            <label>Date From</label>
                                        </Col>
                                        <Col lg={4}>
                                            <DatePicker selected={startDateDetailsFrom}
                                                        onChange={(date) =>setStartDateDetailsFrom (date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col lg={3}>
                                            <label >Date To</label>
                                        </Col>
                                        <Col lg={3}>
                                            <DatePicker selected={startDateDetailesTo}
                                                        onChange={(date) => setStartDateDetailesTo(date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>





                                    </Row>


                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={getRmDetails}   className="w-75 m-4">Report</Button></Col>
                                    </Row>

                                    <div id="ddlDiv" className="overflow-scroll ">
                                        <div className="tableDiv">
                                            <BootstrapTable  keyField='id' data={Data} columns={Columns} selectRow={selectRowPurchased} > </BootstrapTable>
                                        </div>

                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </Container>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title> Owner:--</Modal.Title>


                    </Modal.Header>
                    <Modal.Body>

                        <label>Total purchased: </label><input id="txtTotalPurchased" value={TotalPurchased} disabled={true} type="text" className=" w-25"/>

                        <label>Total Received: </label><input id="txtTotalReceived" value={TotalReceived} disabled={true} type="text" className=" w-25"/>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" onClick={changePendingStatus} >
                            Save Order
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }

}