import {Fragment} from "react";
import Delivery from "../components/Delivery/Delivery";
import TopNavigation from "../components/TopNavigation/TopNavigation";

export default function DeliveryPage(){

    return(
        <Fragment>
            <TopNavigation />
            <Delivery/>

        </Fragment>
    )
}