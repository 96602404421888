import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Delivery from "../components/Delivery/Delivery";
import Bazar from "../components/Bazar/Bazar";

export default function BazarPage(){
    return(
        <Fragment>
            <TopNavigation />
            <Bazar/>

        </Fragment>
    )

}