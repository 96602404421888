import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Drying from "../components/Production/Drying";

export default function CoilDryingPage(){

    return (

        <Fragment>
            <TopNavigation />
            <Drying/>
        </Fragment>
    );
}