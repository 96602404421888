import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import Form from 'react-bootstrap/Form';
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
export default function NewUser(){
    const  PlaySms= ()=>{let smsSound=new Audio("/sms.mp3"); smsSound.play()}
    let state=useSelector((state)=> state);
    const[Sex, setSex]=useState("Male");
    const[Data, setData]=useState([]);
    const[ID, setID]=useState();
    const[Name, setName]=useState();
    const[Designation, setDesignation]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let Columns=[
        {dataField: 'id', text: 'ID', sort: true},
        {dataField: 'name', text: 'Designation ', sort: true},
        {dataField: 'user_name', text: 'Name', sort: true},
        {dataField: 'access', text: 'Father', sort: true},

    ]
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row["name"]);
            setID(row["id"]);
            setDesignation(row["access"]);
            handleShow()
        }
    }

    const getUser=()=>{

       let jsonObject={"sql":"Select id,name, user_name, access From Account"};

       RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{setData(result)}).catch(error=>{cogoToast.error(error);});

    }
    const resetPassword=()=>{

        let ResetID=document.getElementById("deletId").value;


        let jsonObject={"sql":"UPDATE `Account` SET `password`='123456789' WHERE id='"+ResetID+"' "};

        if(ResetID==""){cogoToast.error("Please select User ID")}else{
            RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                cogoToast.success("Password reset")
                PlaySms();
                }).catch(error=>{cogoToast.error(error);});

        }

    }

    const deleteUser=()=>{

        let jsonObject={"sql":"DELETE FROM `Account` WHERE id='"+ID+"' "};




        RestClient.PostRequest(AppUrl.deleteData,jsonObject).then(result=>{
               if(result==1){cogoToast.success("Data deleted successfully")
                   RestClient.PostRequest(AppUrl.getData,{"sql":"Select * From Account"}).then(result=>{setData(result)}).catch(error=>{cogoToast.error(error);});
                    handleClose();
                   PlaySms();

               }else{
                   cogoToast.error("Data not delete")
               }
        }).catch(error=>{
            cogoToast.error(error);

        });






    }
    const saveUser=()=>{

        const AccessType = document.getElementById("cmbAccessType");
        const AccessTypeValue = AccessType.options[AccessType.selectedIndex].text;

        const ClientName=document.getElementById("txtClientId").value;
        const UserName=document.getElementById("txtUserName").value;
        const Password=document.getElementById("txtPassword").value;



        let jsonObject={"sql":"INSERT INTO `Account` (`id`, `name`, `user_name`, `password`, `access`)" +
                " VALUES (NULL, '"+ClientName+"', '"+UserName+"', '"+Password+"', '"+AccessTypeValue+"')"};



        if(AccessType.selectedIndex==0){cogoToast.error("Please select Type")}else{
            if(ClientName==""){cogoToast.error("Please Inert Name")}else{
                if(UserName==""){cogoToast.error("Please insert User Name")}else{
                    if(Password==""){cogoToast.error("Please Insert Password")}else{

                                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                            if(result==1){PlaySms(); cogoToast.success("User Saved Saved");}else{cogoToast.warn("Data not Saved");}

                                        }).catch(error=>{
                                            cogoToast.error(error);

                                        });

                                    }}}}




    }

    return(
        <Fragment>
               <Container>
                <Row>
                    <Col lg={4} sm={12}>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Access Type</label></Col>
                            <Col lg={6}>
                                <select className=" w-100 float-right" id="cmbAccessType"  >
                                <option value="0">-Select Type-</option>
                                <option value="0">admin</option>
                                <option value="0">sm</option>
                                <option value="0">sr</option>
                                <option value="0">cm</option>
                                <option value="0">production</option>
                                <option value="0">user</option>
                                <option value="0">accounts</option>
                                <option value="0">stock</option>
                                <option value="0">pack</option>

                            </select>
                            </Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Name</label></Col>
                            <Col lg={6}> <input id="txtClientId"/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">User Name</label></Col>
                            <Col lg={6}> <input id="txtUserName" type="text" /></Col>

                        </Row>

                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Password</label></Col>
                            <Col lg={6}> <input id="txtPassword" type="text" /></Col>

                        </Row>

                        <Row className="mt-2 text-justify">
                            <Col lg={6}> </Col>
                            <Col lg={6}><Button onClick={saveUser} className="w-100">Save</Button></Col>
                        </Row>
                    </Col>
                    <Col lg={8} sm={12}>
                        <div className="purchaseDiv text-center">

                            <Row >

                                <Col lg={6} md={6} sm={6}><Button onClick={getUser}  className="w-50 m-4"  >Search User</Button></Col>
                                <Col lg={6} md={6} sm={6}>
                                    <Row className="m-4">
                                        <Col lg={2}>ID</Col>
                                        <Col lg={3} sm={6}><input id="deletId"   className="mt-1 w-50"/></Col>
                                        <Col lg={7} sm={6}><Button className="mt-1" onClick={resetPassword}>Reset Password</Button></Col>
                                    </Row>


                                </Col>
                            </Row>

                            <div className="tableDiv overflow-scroll">
                                <BootstrapTable  keyField='id' data={Data} columns={Columns} selectRow={selectRow} > </BootstrapTable>
                            </div>





                        </div>
                    </Col>
                </Row>

               </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to delete?</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label className="w-25">ID:</label><input value={ID} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Name:</label><input value={Name} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Access:</label><input value={Designation} disabled={true} className="w-25"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={deleteUser} >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}