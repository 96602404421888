import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import Form from 'react-bootstrap/Form';
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
export default function DealerSupplier(){
    let state=useSelector((state)=> state);
    const[Sex, setSex]=useState("Male");
    const[Data, setData]=useState([]);
    const[Columns, setColumns]=useState([{}]);
    const[ID, setID]=useState();
    const[Name, setName]=useState();
    const[DealerComments, setDealerComments]=useState();
    const[DealerMobile, setDealerMobile]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let ColumnsDealer=[
        {dataField: 'Id', text: 'ID', sort: true},
        {dataField: 'Name', text: 'Name ', sort: true},
        {dataField: 'Address', text: 'Address ', sort: true},
        {dataField: 'Mobile', text: 'Mobile ', sort: true},
        {dataField: 'Comments', text: 'Comments ', sort: true},


    ]
    let ColumnsSupplier=[
        {dataField: 'Id', text: 'ID', sort: true},
        {dataField: 'Name', text: 'Name ', sort: true},
        {dataField: 'Address', text: 'Address ', sort: true},
        {dataField: 'Mobile', text: 'Mobile ', sort: true},
        {dataField: 'Type', text: 'Item ', sort: true},
        {dataField: 'Comments', text: 'Comments ', sort: true},

    ]
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row["Name"]);
            setDealerMobile(row["Mobile"]);
            setID(row["Id"]);
            setDealerComments(row["Comments"]);

            handleShow()
        }
    }

    const getDealerSupplier=()=>{

        const DealerSupplier = document.getElementById("cmbDealerSupplier").selectedIndex;




        if(DealerSupplier==0){
            let jsonObjectDealer={"sql":"Select * From Dealer"};
            RestClient.PostRequest(AppUrl.getData,jsonObjectDealer).then(result=>{ setColumns(ColumnsDealer);setData(result) }).catch(error=>{cogoToast.error(error);});
        }else{
            let jsonObjectSupplier={"sql":"Select * From Supplier"};
            RestClient.PostRequest(AppUrl.getData,jsonObjectSupplier).then(result=>{setColumns(ColumnsSupplier);setData(result)}).catch(error=>{cogoToast.error(error);});

        }


    }


    const saveDealerSupplier=()=>{

        const Type = document.getElementById("cmbType").selectedIndex;

        let Comments=document.getElementById("txtNoComments").value;
        let ItemName=document.getElementById("txtItemName").value;
        let NID=document.getElementById("txtNoNid").value;
        let Mobile=document.getElementById("txtNoMobile").value;
        let Address=document.getElementById("txtNoAddress").value;
        let DealerName=document.getElementById("txtNoName").value;

       let SQL=""

        if(Type==2){
            SQL="INSERT INTO `Supplier` (`Id`, `Name`, `Address`, `Mobile`, `Type`,`Comments`, `Entry_Date`)" +
                " VALUES (NULL, '"+DealerName+"', '"+Address+"', '"+Mobile+"', '"+ItemName+"','"+Comments+"', current_timestamp())"
        }else{
            SQL="INSERT INTO `Dealer` (`Id`, `Name`, `Address`, `Mobile`, `Status`, `Comments`) " +
                "VALUES (NULL, '"+DealerName+"', '"+Address+"', '"+Mobile+"', 'active', '"+Comments+"')"
        }

        let jsonObject={
            sql:SQL
        }

        if(Type==0){cogoToast.error("Please select type")}else{
            if(DealerName==""){cogoToast.error("Please select Name")}else{
                if(Address==""){cogoToast.error("Please select Address")}else{
                    if(Mobile==""){cogoToast.error("Please select Mobile")}else{


                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                            if(result==1){ cogoToast.success("Data Saved");}else{cogoToast.warn("Data not Saved");}

                        }).catch(error=>{
                            cogoToast.error(error);

                        });

                    }
                }
            }
        }



    }

    return(
        <Fragment>
               <Container>
                <Row>
                    <Col lg={4} sm={12}>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Entry Type</label></Col>
                            <Col lg={6}>
                                <select className=" w-100 float-right" id="cmbType"  >
                                <option value="0">-Select Type-</option>
                                <option value="0">-Dealer-</option>
                                <option value="0">-Supplier-</option>

                            </select>
                            </Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Name</label></Col>
                            <Col lg={6}><input id="txtNoName"/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Address</label></Col>
                            <Col lg={6}><input id="txtNoAddress"/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Mobile</label></Col>
                            <Col lg={6}><input id="txtNoMobile"/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">NID</label></Col>
                            <Col lg={6}><input id="txtNoNid"/></Col>

                        </Row>

                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Item Name</label></Col>
                            <Col lg={6}> <input id="txtItemName" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Comments</label></Col>
                            <Col lg={6}><textarea id="txtNoComments"></textarea></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}> </Col>
                            <Col lg={6}><Button onClick={saveDealerSupplier} className="w-100">Save</Button></Col>


                        </Row>
                    </Col>
                    <Col lg={8} sm={12}>
                        <div className="purchaseDiv text-center">
                            <label className="text-danger">Getting Dealer and Supplier</label>
                            <Row >
                                <Col lg={2} md={6} sm={12}><label>Type</label></Col>
                                <Col lg={3} md={6} sm={12}><select className=" w-100 float-right" id="cmbDealerSupplier"  >
                                    <option value="0">-Dealer-</option>
                                    <option value="0">-Supplier-</option>

                                </select><Button onClick={getDealerSupplier}  className="w-100 mt-4"  >Search</Button></Col>

                            </Row>

                            <div className="tableDiv overflow-scroll">
                                <BootstrapTable  keyField='Id' data={Data} columns={Columns} selectRow={selectRow} > </BootstrapTable>
                            </div>





                        </div>
                    </Col>
                </Row>

               </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to delete?</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label className="w-25">ID:</label><input value={ID} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Name:</label><input value={Name} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Mobile:</label><input value={DealerMobile} disabled={true} className="w-25"/>
                    <textarea className="w-100" value={DealerComments} disabled={true}></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success"  >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}