import {useSelector} from "react-redux";
import {Fragment, useState} from "react";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Col, Container, Modal, Nav, Row, Table} from "react-bootstrap";

import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";
export default function OrderCheck(){


    let state=useSelector((state)=> state);

    const [Upazila, setUpazila]=useState([]);
    const [Union, setUnion]=useState([]);
    const [Bazar, setBazar]=useState([]);
    const [Customer, setCustomer]=useState([]);
    const [Loading, setLoading]=useState(true);
    const [OrderList, setOrderList]=useState();
    const [ID, setID]=useState();
    const [ShopOwner, setShopOwner]=useState();
    const [Mobile, setMobile]=useState();




    const [startDateSummeryFrom, setStartDateSummeryFrom] = useState(new Date());
    const [startDateSummeryTo, setStartDateSummeryTo] = useState(new Date());



    const getUpazila=()=>{


        const e = document.getElementById("ddlDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=state.upazila.filter(x => x.district_id==value);
        setUpazila(dt)
        setUnion([])
        setBazar([])

    }
    const getUnion=()=>{
        const e = document.getElementById("ddlUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=state.union.filter(x => x.upazila_id==value);
        setUnion(dt)
        setBazar([])
    }
    const getBazar=()=>{
        const e = document.getElementById("ddlUnion");
        const value = e.options[e.selectedIndex].value;
        const dt=state.bazar.filter(x => x.union_id==value);
        setBazar(dt)



    }

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getCustomerBy=()=>{

        let district = document.getElementById("ddlDistrict");
        let upazila = document.getElementById("ddlUpazila");
        let union = document.getElementById("ddlUnion");
        let bazar = document.getElementById("ddlBazar");
        let OrderStatus = document.getElementById("cmbOrderStatus");

        let districtName=district.options[district.selectedIndex].text;
        let upazilaName=upazila.options[upazila.selectedIndex].text;
        let unionName=union.options[union.selectedIndex].text;

        let bazarName=bazar.options[bazar.selectedIndex].text;
        let bazarId=bazar.options[bazar.selectedIndex].value;
        let OrderStatusValue=OrderStatus.options[OrderStatus.selectedIndex].text;

        let DateFrom=AppUtility.getDate(startDateSummeryFrom)
        let DateTo=AppUtility.getDate(startDateSummeryTo)



        let jsonObject=
            {

                "sql":"Select Shop_Status,Status,Comments,Upazila,Bazar,Sr_Name, Entry_Date,Shop_Owner,Shop_Name,Mobile,id  from Customer where Shop_Status='"+OrderStatusValue+"' and Entry_Date Between '"+DateFrom+"' and '"+DateTo+"' "


            };




                        RestClient.PostRequest(AppUrl.getCustomerByDistrict,jsonObject).then(result=>{

                            document.getElementById("tableID").style.visibility="visible";
                            setCustomer(result)

                        });

    }
    const getCustomerByComments=()=>{

        let district = document.getElementById("ddlDistrict");
        let upazila = document.getElementById("ddlUpazila");
        let union = document.getElementById("ddlUnion");
        let bazar = document.getElementById("ddlBazar");
        let OrderComments = document.getElementById("cmbOrderComments");

        let districtName=district.options[district.selectedIndex].text;
        let upazilaName=upazila.options[upazila.selectedIndex].text;
        let unionName=union.options[union.selectedIndex].text;

        let bazarName=bazar.options[bazar.selectedIndex].text;
        let bazarId=bazar.options[bazar.selectedIndex].value;
        let OrderCommentsValue=OrderComments.options[OrderComments.selectedIndex].text;


        let DateFrom=AppUtility.getDate(startDateSummeryFrom)
        let DateTo=AppUtility.getDate(startDateSummeryTo)


        let jsonObject=
            {

                "sql":"Select Shop_Status,Comments,Status,Upazila,Bazar,Sr_Name, Entry_Date,Shop_Owner,Shop_Name,Mobile,id  from Customer where Comments='"+OrderCommentsValue+"' and Entry_Date Between '"+DateFrom+"' and '"+DateTo+"' "


            };




        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            document.getElementById("tableID").style.visibility="visible";
            setCustomer(result)

        });

    }
    const columns=[
        {dataField: 'Shop_Status', text: 'Shop_Status',sort:true},
        {dataField: 'Comments', text: 'Comments',sort:true},

        {dataField: 'Upazila', text: 'Upazila',sort:true},
        {dataField: 'Bazar', text: 'Bazar',sort:true},
        {dataField: 'Sr_Name', text: 'SR',sort:true},
        {dataField: 'Entry_Date', text: 'Entry_Date',sort:true},
        {dataField: 'Status', text: 'Order',sort:true},
        {dataField: 'Shop_Owner', text:'Owner',sort:true},
        {dataField: 'Shop_Name', text: 'Shop',sort:true},
        {dataField: 'Mobile', text: 'Mobile',sort:true},
        {dataField: 'id', text: 'ID',sort:true},

    ]
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{

            setID(row['id'])

            setShopOwner(row['Shop_Owner'])
            setMobile(row['Mobile'])


            handleShow();
        }
    }

    const updateCustomer=()=>{
        let ModalStatus = document.getElementById("cmbModalStatus");
        let ModalStatusValue=ModalStatus.options[ModalStatus.selectedIndex].text;


            RestClient.PostRequest(AppUrl.customerUpdate,{"sql":"Update Customer set Shop_Status='Checked',Comments='"+ModalStatusValue+"', Sr_Name='"+state.name+"', Entry_Date= current_timestamp() where id='"+ID+"'"}).then(result=>{
                if(result==1){cogoToast.success("Data saved successfully")}else{cogoToast.error("Data not saved")}
            });
            handleClose();


    }







    return (

        <Fragment>
            <Container className="animated zoomIn">
                <div className="purchaseDiv text-center">


                    <Container>
                        <Row>
                            <Col lg={3} md={3} sm={12} >
                                <Row>
                                    <select className="float-start mt-2 w-100 customfontsize" id="ddlDistrict"  onChange={getUpazila} >
                                        <option value="0">-Select District-</option>

                                        {


                                            state.district.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Row>

                                <Row>
                                    <select className="float-start w-100 mt-2 customfontsize" id="ddlUpazila" onChange={getUnion}>
                                        <option value="0">-Select Upazila-</option>

                                        {
                                            Upazila.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </Row>

                                <Row>
                                    <select className="float-start mt-2 w-100 customfontsize" id="ddlUnion"  onChange={getBazar} >
                                        <option>-Select Union-</option>

                                        {

                                            Union.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Row>

                                <Row>
                                    <select className="float-start mt-2 w-100 customfontsize" id="ddlBazar"  >
                                        <option>-Select Bazar-</option>

                                        {

                                            Bazar.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.id}>{ctr.name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Row>

                                <Row>
                                    <select className="float-start w-100 customfontsize m-1" id="cmbOrderStatus"  >
                                        <option>Shop close</option>
                                        <option>Coils have</option>
                                        <option>Not Ordered</option>
                                        <option>Complain</option>
                                        <option>No Interest</option>
                                        <option>Checked</option>

                                    </select>
                                </Row>
                                <Row>
                                    <select className="float-start w-100 customfontsize m-1" id="cmbOrderComments"  >

                                        <option>Checked</option>
                                        <option>Complain SR</option>
                                        <option>False Info</option>
                                    </select>
                                </Row>

                                <Row>
                                    <Col lg={3}>
                                        <label>From</label>
                                    </Col>
                                    <Col lg={4}>
                                        <DatePicker selected={startDateSummeryFrom}
                                                    onChange={(date) => setStartDateSummeryFrom(date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}>
                                        <label >To</label>
                                    </Col>
                                    <Col lg={4}>
                                        <DatePicker selected={startDateSummeryTo}
                                                    onChange={(date) => setStartDateSummeryTo(date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>

                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={getCustomerBy} variant="success" className="w-100 mt-4">Customer</Button></Col>
                                    <Col lg={6} md={6} sm={12}><Button onClick={getCustomerByComments} variant="danger" className="w-100 mt-4">Comments</Button></Col>

                                </Row>
                            </Col>
                            <Col lg={9} sm={12} md={6}>
                                <div id="tableID" className="overflow-scroll purchaseDiv">


                                        <BootstrapTable
                                            keyField='id'
                                            data={Customer}
                                            columns={ columns }
                                            selectRow={selectRow}

                                        >
                                        </BootstrapTable>

                                </div>
                            </Col>







                        </Row>

                    </Container>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title> Owner:--{ShopOwner}</Modal.Title>


                        </Modal.Header>
                        <Modal.Body>

                            <select className="float-start w-50 customfontsize m-1" id="cmbModalStatus"  >
                                <option>Checked</option>
                                <option>Complain SR</option>
                                <option>False Info</option>

                            </select>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="success" onClick={updateCustomer} >
                                Save Order
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>




            </Container>



        </Fragment>





    );

}