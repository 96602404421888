import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Delivery from "../components/Delivery/Delivery";
import Order from "../components/Order/Order";
import {Button, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import NewEmployee from "../components/HR/NewEmployee";
import DealerSupplier from "../components/HR/DealerSupplier";
import NewUser from "../components/HR/NewUser";
import Expenditure from "../components/Accounts/Expenditure";
import OrderCheck from "../components/Order/OrderCheck";

export default function OrderCheckingPage(){

    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>
                <h3>Order Management</h3>
                <hr/>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Home"><OrderCheck/></Tab>
                    <Tab eventKey="newEmployee" title="New Employee"></Tab>
                    <Tab eventKey="dealer" title="Dealer/Supplier">

                    </Tab>
                    <Tab eventKey="absent" title="Absent"></Tab>
                    <Tab eventKey="User" title="User Creation"></Tab>

                </Tabs>
            </div>
        </Fragment>
    )


}