import {Fragment, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import Loading from "../Loading/Loading";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";

export default function CoilCutting(){

    let state=useSelector((state)=> state);
    let [Columns, setColumns]=useState([{}])
    let [ProductionNo, setProductionNo]=useState()

    let ColumnsFormula=[
        {dataField: 'Entry_Date', text: 'Date', sort: true},
        {dataField: 'id', text: 'Formula_No', sort: true},
        {dataField: 'Wood', text: 'Wood', sort: true},
        {dataField: 'Gum', text: 'Gum', sort: true},
        {dataField: 'CSP', text: 'CSP', sort: true},
        {dataField: 'Tabu', text: 'Tabu ', sort: true},
        {dataField: 'Starch', text: 'Starch', sort: true},
        {dataField: 'Color', text: 'Color', sort: true},
        {dataField: 'Comments', text: 'Comments_By_The_User', sort: true},

    ]
    let ColumnsProduction=[
        {dataField: 'id', text: 'Product_No', sort: true},
        {dataField: 'Entry_Date', text: 'P_Date', sort: true},
        {dataField: 'Coil_Type', text: 'Type', sort: true},
        {dataField: 'Formula_No', text: 'Formula', sort: true},
        {dataField: 'Wood', text: 'Wood', sort: true},
        {dataField: 'Gum', text: 'Gum', sort: true},
        {dataField: 'CSP', text: 'CSP ', sort: true},
        {dataField: 'Tabu', text: 'Tabu', sort: true},
        {dataField: 'Starch', text: 'Starch', sort: true},
        {dataField: 'Color', text: 'Color', sort: true},
        {dataField: 'Mixer', text: 'Mixer', sort: true},
        {dataField: 'Tray', text: 'Tray', sort: true},
        {dataField: 'Status', text: 'Dry', sort: true},
        {dataField: 'Comments', text: 'Comments_by_the_user', sort: true},

    ]
    let ColumnsChemical=[

        {dataField: 'Production_No', text: 'Production_No', sort: true},
        {dataField: 'Total_Batch', text: 'Total_Batch ', sort: true},
        {dataField: 'Entry_Date', text: 'Entry_Date', sort: true},
        {dataField: 'Status', text: 'Used', sort: true},


    ]
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
                    setProductionNo(row["Production_No"])

            }
    }

    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());




    const [BtnSave,setBtnSave]=useState("success")
    const [BtnSaveFormula,setBtnSaveFormula]=useState("success")
    const [BtnReport,setBtnReport]=useState("success")
    const [BtnChemical,setBtnChemical]=useState("success")



    const  Loader=false;


    const [Data, setData]=useState([]);
    const [DataChemical, setDataChemical]=useState([]);




    const saveCutting=()=>{




        const CoilType = document.getElementById("cmbCoilType");
        const CoilTypeValue = CoilType.options[CoilType.selectedIndex].text;

        const Production=document.getElementById("txtProductionNo").value;
        const Formula=document.getElementById("txtFormula").value;
        const Wood=document.getElementById("txtWood").value;
        const Gum=document.getElementById("txtGum").value;
        const CSP=document.getElementById("txtCsp").value;
        const Tabu=document.getElementById("txtTabu").value;
        const Starch=document.getElementById("txtStarch").value;
        const Color=document.getElementById("txtColor").value;
        const Mixer=document.getElementById("txtMixer").value;
        const Trolly=document.getElementById("txtTray").value;
        const Comments=document.getElementById("txtComments").value;
        const User_Name=state.name;


        let jsonObject=
            {
                "sql": "INSERT INTO `Coil_Cutting` (`id`,  `Coil_Type`,`Production_No`, `Formula_No`, `Wood`, `Gum`, `CSP`, `Tabu`, `Starch`,`Color`, `Mixer`, `Tray`, `Comments`, `Status`, `Entry_User`, `Entry_Date`)" +
                    " VALUES (NULL,'"+CoilTypeValue+"', '"+Production+"','"+Formula+"', '"+Wood+"', '"+Gum+"', '"+CSP+"', '"+Tabu+"', '"+Starch+"','"+Color+"', '"+Mixer+"', '"+Trolly+"', '"+Comments+"', 'No', '"+User_Name+"', current_timestamp()) "
            };

            if(CoilType.selectedIndex==0){cogoToast.error("Please insert Coil Type")}else{
            if(Formula==""){cogoToast.error("Please insert Formula No")}else{
            if(Production==""){cogoToast.error("Please insert Formula No")}else{
                if(Wood==""){cogoToast.error("Please insert Wood Qty")}else{
                    if(Gum==""){cogoToast.error("Please insert GUM Qty")}else{
                        if(CSP==""){cogoToast.error("Please insert CSP")}else{
                            if(Tabu==""){cogoToast.error("Please insert Tabu")}else{
                                if(Starch==""){cogoToast.error("Please insert Starch")}else{
                                if(Color==""){cogoToast.error("Please insert Color")}else{
                                    if(Mixer==""){cogoToast.error("Please insert Mixer No")}else{
                                        if(Trolly==""){cogoToast.error("Please insert Trolly No")}else{
                                            if(Comments==""){cogoToast.error("Please insert Comments")}else{

                                                setBtnSave("secondary")
                                                RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                                    if(result==1){
                                                        RestClient.PostRequest(AppUrl.getData, {"sql":"UPDATE Chemical_Use SET Status ='Yes' WHERE Production_No = '"+Production+"'"}).then(result=>{}).catch(error=>{});
                                                        let number="01789307792";
                                                        let sms="Coil Cuttin: Total Mixer: "+Mixer+" and Trolly: "+Trolly+" Comments: "+Comments+" Sent by: "+User_Name
                                                        AppUtility.sendSMS(sms,number)
                                                        cogoToast.success("Data saved successfully")
                                                        setBtnSave("success")
                                                    }else{cogoToast.success("Data  not Saved");
                                                        setBtnSave("success")}

                                                }).catch(error=>{
                                                    setBtnSave("success")
                                                    cogoToast.error(error);
                                                });


                                            }}}}}}}}}}}}
    }


    const getReport=()=>{
        if(document.getElementById("cmbReportType").selectedIndex==0){cogoToast.error("Please select report type")}else{
            if(document.getElementById("cmbReportType").selectedIndex==1){getProduction();}
            if(document.getElementById("cmbReportType").selectedIndex==2){getFormula();}

        }


    }
    const getProduction=()=>{




        let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);
        let jsonObjectProduction=
            {
                "sql":"select id, Entry_Date,Coil_Type,Formula_No,Wood,Gum,CSP,Tabu,Starch,Color,Mixer,Tray,Status,Comments from Coil_Cutting where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
              };
                    setBtnReport("secondary")
        RestClient.PostRequest(AppUrl.getData,jsonObjectProduction).then(result=>{
            if(result==""){cogoToast.error("Data not found");setBtnReport("success")}else{

                setColumns(ColumnsProduction)
                setData(result)
                setBtnReport("success")

            }

            console.log(result);setBtnReport("success")


        }).catch(error=>{
            cogoToast.error(error);

        });

    }
    const getFormula=()=>{

        let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);
        let jsonObjectProduction=
            {
                "sql":"select id,Entry_Date,Wood,Gum,CSP,Tabu,Starch,Color,Comments from Formula where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
            };

        RestClient.PostRequest(AppUrl.getData,jsonObjectProduction).then(result=>{
            if(result==""){cogoToast.error("Data not found")}else{

                setColumns(ColumnsFormula)
                setData(result)

            }

            console.log(result)


        }).catch(error=>{
            cogoToast.error(error);

        });

    }
    const getChemical=()=>{


        let jsonObject={"sql":"SELECT Production_No, Total_Batch, Entry_Date, Status FROM `Chemical_Use` where Status='No' "};
        setBtnChemical("secondary")
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found");setBtnChemical("success")}else{

                setDataChemical(result)
                setBtnChemical("success")

            }




        }).catch(error=>{
            setBtnChemical("success");
            cogoToast.error(error);

        });

    }



    if(Loader==true){
        return(
            <Fragment>

                <Loading/>



            </Fragment>
        )
    }else{
        return(
            <Fragment>


                <div className="purchaseDiv text-center">
                    <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                    <h3>Coil Cutting Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>

                            <Col lg={3} md={3} sm={12}>
                                <Row className="mt-2">
                                    <Col lg={12}><label className="customfontsize text-danger">Daily Coil Cutting Detailes</label></Col>



                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}><label className="customfontsize">Coil Type</label></Col>
                                    <Col lg={6}> <select className=" w-75 float-right" id="cmbCoilType"  >
                                        <option value="0">-Select Type-</option>
                                        <option value="0">Round</option>
                                        <option value="0">Angel</option>

                                    </select></Col>


                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}><label className="customfontsize">Production No</label></Col>
                                    <Col lg={6}><input id="txtProductionNo" type="text" disabled={true} value={ProductionNo} className="w-100 customfontsize"/></Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}><label className="customfontsize">Formula No</label></Col>
                                    <Col lg={6}><input id="txtFormula" type="text" className="w-100 customfontsize"/></Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}> <label className="customfontsize">Wood Powder</label></Col>
                                    <Col lg={6}><input id="txtWood" type="text" className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}><label className="customfontsize">Gum Powder</label></Col>
                                    <Col lg={6}><input id="txtGum" type="text" className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row  className="mt-2">
                                    <Col lg={6}><label className="customfontsize">CSP</label></Col>
                                    <Col lg={6}><input id="txtCsp" type="text"  className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}><label className="customfontsize">Tabu Powder</label></Col>
                                    <Col lg={6}><input id="txtTabu" type="text"  className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}> <label className="customfontsize">Starch</label></Col>
                                    <Col lg={6}><input id="txtStarch" type="text"  className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-2">
                                    <Col lg={6}> <label className="customfontsize">Color</label></Col>
                                    <Col lg={6}><input id="txtColor" type="text"  className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-4">
                                    <Col lg={6}><label className="customfontsize">Total Mixer</label></Col>
                                    <Col lg={6}><input id="txtMixer" type="text"  className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-4">
                                    <Col lg={6}> <label className="customfontsize">Total Tray</label></Col>
                                    <Col lg={6}><input id="txtTray" type="text"  className="w-100 customfontsize"/></Col>


                                </Row>

                                <Row>
                                    <label className="customfontsize">Comments</label>
                                    <textarea id="txtComments" className="customfontsize"></textarea>
                                </Row>
                                <Row >
                                    <Col lg={12} md={6} sm={12}><Button onClick={saveCutting}  variant={BtnSave} className="w-100 mt-4"  >Submit</Button></Col>

                                </Row>

                            </Col>
                            <Col lg={4} md={3} sm={12}>
                                <div className="purchaseDiv text-center">
                                    <label className="text-danger">Getting Chemical No</label>
                                    <Row >
                                        <Col lg={12} md={6} sm={12}><Button onClick={getChemical}   variant={BtnChemical} className="w-100 mt-4"  >Search</Button></Col>

                                    </Row>

                                        <div className="tableDiv overflow-scroll">
                                            <BootstrapTable  keyField='Production_No' data={DataChemical} columns={ColumnsChemical} selectRow={selectRow} > </BootstrapTable>
                                        </div>





                                </div>




                            </Col>
                            <Col lg={5} md={3} sm={12}>
                                <div className="purchaseDiv text-center"> <label className="text-danger">Stock Detailes Report</label>

                                    <Row>
                                        <select className="m-4 w-75 float-right" id="cmbReportType"  >
                                            <option value="0">-Select Report-</option>
                                            <option value="0">Production Report</option>
                                            <option value="0">Formula report</option>

                                        </select>
                                    </Row>

                                    <Row>
                                        <Col lg={3}>
                                            <label>Date From</label>
                                        </Col>
                                        <Col lg={4}>
                                            <DatePicker selected={startDateDetailsFrom}
                                                        onChange={(date) =>setStartDateDetailsFrom (date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col lg={3}>
                                            <label >Date To</label>
                                        </Col>
                                        <Col lg={3}>

                                            <DatePicker selected={startDateDetailesTo}
                                                        onChange={(date) => setStartDateDetailesTo(date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>





                                    </Row>


                                    <Row >
                                        <Col lg={12} md={6} sm={12}><Button onClick={getReport}  variant={BtnReport} className="w-75 m-4">Report</Button></Col>
                                    </Row>


                                        <div className="tableDiv overflow-scroll">
                                            <BootstrapTable  keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                                        </div>

                                </div>

                            </Col>
                        </Row>

                    </Container>
                </div>


            </Fragment>
        )
    }

}