import {Fragment, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import Loading from "../Loading/Loading";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";

export default function Godown(){

    let state=useSelector((state)=> state);


    let Columns=[
        {dataField: 'Entry_Date', text: 'Date', sort: true},
        {dataField: 'Production_No', text: 'P_No', sort: true},
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco Plus', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema ', sort: true},
        {dataField: 'Type', text: 'Type', sort: true},

    ]
    let ColumnsPendingStock=[
        {dataField: 'Entry_Date', text: 'P_Date', sort: true},
        {dataField: 'Production_No', text: 'P_No', sort: true},
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco+', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema+ ', sort: true},
        {dataField: 'Total_Coil', text: 'Total_Carton', sort: true},
        {dataField: 'Comments', text: 'Comments', sort: true},

    ]



    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());

    const [totalStock, setTotalStock]=useState(0)
    const [totalAvailbleStcock, setTotalAvailbleStcock]=useState(0)

    const [BtnTotalStock,setBtnTotalStock]=useState("success")
    const [BtnSave,setBtnSave]=useState("success")
    const [BtnAvailableStock,setBtnAvailableStock]=useState("success")
    const [BtnStockDetailes,setBtnStockDetailes]=useState("success")


    const  Loader=false;


    const [Data, setData]=useState([]);
    const [DataPending, setDataPending]=useState([]);




    const saveAmount=()=>{


        const typeAccounts = document.getElementById("ddlType");
        const typeAccountsValue = typeAccounts.options[typeAccounts.selectedIndex].text;
        const ProductionNo=document.getElementById("txtProduction").value;
        const Farco=document.getElementById("txtFarco").value;
        const FarcoPlus=document.getElementById("txtFarcoPlus").value;
        const Beema=document.getElementById("txtBeema").value;
        const BeemaPlus=document.getElementById("txtBeemaPlus").value;
        const Total=document.getElementById("txtTotal").value;
        const Comments=document.getElementById("txtComments").value;
        const User_Name=state.name;


        let jsonObject=
            {
                "sql":"Insert into Stock(Type,Production_No, Farco,Farco_Plus,Beema,Beema_Plus,Total,Comments,Entry_User )Values('"+typeAccountsValue+"','"+ProductionNo+"','"+Farco+"','"+FarcoPlus+"','"+Beema+"','"+BeemaPlus+"','"+Total+"','"+Comments+"','"+User_Name+"')"
            };

        if(typeAccounts.selectedIndex==0){cogoToast.error('Please Select Type');typeAccounts.focus()}else{
            if(ProductionNo==""){cogoToast.error('Production No  Empty');}else{
            if(Farco==""){cogoToast.error('Farco  Empty');}else{
                if(FarcoPlus==""){cogoToast.error('Farco Plus  Empty');}else{
                if(Beema==""){cogoToast.error('Beema  Empty');}else{
                if(BeemaPlus==""){cogoToast.error('Beema Plus  Empty');}else{
                if(Total==""){cogoToast.error('Total  Empty');}else{
                if(Comments==""){cogoToast.error('Comments  Empty');}else{

                    setBtnSave("secondary")

                    RestClient.PostRequest(AppUrl.insertStock,jsonObject).then(result=>{
                        if(result==1){
                            RestClient.PostRequest(AppUrl.getData, {"sql":"UPDATE Coil_Packaging SET Status ='Yes' WHERE Production_No = '"+ProductionNo+"'"}).then(result=>{}).catch(error=>{});
                            setBtnSave("success")
                            let number="01789307792"
                            cogoToast.success("Stock Saved Successfully");
                        }else {
                            setBtnSave("success")
                            cogoToast.error("Stock not Saved");
                        }
                    }).catch(error=>{
                        cogoToast.error(error);
                        setBtnSave("success")
                    });
                }}}}}}}}

    }
    const getDetails=()=>{

        const typeAccountsDetailes = document.getElementById("typeAccountsDetailes");
        const typeAccountsDetailesValue = typeAccountsDetailes.options[typeAccountsDetailes.selectedIndex].text;

        let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);

        let jsonObject=
            {
                "sql":"select Production_No, Farco, Farco_Plus,Beema, Beema_Plus,Entry_Date,Type from Stock where Type='"+typeAccountsDetailesValue+"' and Entry_Date between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
            };

        if(typeAccountsDetailes.selectedIndex==0){cogoToast.error('Please Select Type');typeAccountsDetailes.focus()}else{
            if(startDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
                if(startDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{

                    setBtnStockDetailes("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                        setData( result)
                        setBtnStockDetailes("success")

                    }).catch(error=>{
                        setBtnStockDetailes("success")
                        cogoToast.error(error);
                    });

                }}}

    }
    const getPendingStock=()=>{


        let jsonObject=
            {
                "sql":"SELECT * FROM `Coil_Packaging` where Status='No' "
            };



                    setBtnStockDetailes("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                        setDataPending( result)
                        setBtnStockDetailes("success")

                    }).catch(error=>{
                        setBtnStockDetailes("success")
                        cogoToast.error(error);
                    });

    }

    const getStockSummary=()=>{

        const typeAccountsSummary = document.getElementById("typeAccountsSummary");
        const typeAccountsSummaryValue = typeAccountsSummary.options[typeAccountsSummary.selectedIndex].text;
        let jsonObject;
        let AllStock="";
            if(typeAccountsSummaryValue=="All Stock"){




                 jsonObject=
                    {
                        "sql":"select sum(Farco)+Sum(Farco_Plus)+sum(Beema)+Sum(Beema_Plus) As Total from Stock"
                    };
            }else{
                 jsonObject=
                    {
                        "sql":"select sum("+typeAccountsSummaryValue+") As Total from Stock  "
                    };
            }



        if(typeAccountsSummary.selectedIndex==0){cogoToast.error('Please Select Type');typeAccountsSummary.focus()}else{

            setBtnTotalStock("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        if(result[0]["Total"]==null){document.getElementById("lblAmount").innerText="No Amount found";
                            cogoToast.error("No Data Found");
                            setBtnTotalStock("success")
                        }else{
                            document.getElementById("lblAmount").innerText=result[0]["Total"];
                            cogoToast.success("Data Found");
                            setBtnTotalStock("success")
                            setTotalStock("Total Stock: "+typeAccountsSummaryValue)
                        }
                    }).catch(error=>{
                        setBtnTotalStock("success")
                        cogoToast.error(error);
                    });
                }
    }

    const getAvailableStock=()=>{
        setBtnAvailableStock("Wait...")
        const cmbAvailableStock = document.getElementById("cmbAvailableStock");
        const cmbAvailableStockValue = cmbAvailableStock.options[cmbAvailableStock.selectedIndex].text;
        let jsonObject;
        let AllStock="";
        if(cmbAvailableStockValue=="All Stock"){

            jsonObject={"sql":"SELECT sum(Stock.Farco)+Sum(Stock.Farco_Plus)+sum(Stock.Beema)+Sum(Stock.Beema_Plus)-(select sum(Delivery.Farco)+Sum(Delivery.Farco_Plus)+sum(Delivery.Beema)+Sum(Delivery.Beema_Plus) FROM Delivery) AS Total FROM Stock"};

        }else{
            jsonObject={"sql":"SELECT Sum(Stock."+cmbAvailableStockValue+")-(select sum(Delivery."+cmbAvailableStockValue+") FROM Delivery) AS Total FROM Stock"};

        }



        if(cmbAvailableStock.selectedIndex==0){cogoToast.error('Please Select Type');cmbAvailableStock.focus()}else{

            setBtnAvailableStock("secondary")
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                if(result[0]["Total"]==null){
                    cogoToast.error("No Data Found");setBtnAvailableStock("success")
                }else{
                    document.getElementById("lblAvailableStock").innerText= result[0]["Total"]
                    setBtnAvailableStock("success")

                }
            }).catch(error=>{
                cogoToast.error(error);setBtnAvailableStock("success")
            });

    }}

    if(Loader==true){
        return(
            <Fragment>

                <Loading/>



            </Fragment>
        )
    }else{
        return(
            <Fragment>


                <div className="purchaseDiv text-center">
                    <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                    <h3>Stock Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>

                            <Col lg={4} md={3} sm={12}>
                                <Row>
                                    <Col lg={3} sm={12}><label className="customfontsize">Type</label></Col>
                                    <Col lg={3} sm={12}><select className="float-start w-100" id="ddlType"  >
                                        <option value="0">-Select-</option>
                                        <option value="0">New</option>
                                        <option value="0">Return</option>
                                    </select></Col>
                                    <Col lg={3} sm={12}><label className="customfontsize">Production</label></Col>
                                    <Col lg={3} sm={12}> <input id="txtProduction" type="text" className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3} sm={12}><label className="customfontsize">Farco</label></Col>
                                    <Col lg={3} sm={12}><input id="txtFarco" type="text" className="w-100 customfontsize"/></Col>
                                    <Col lg={3} sm={12}><label className="customfontsize text-danger">Farco+</label></Col>
                                    <Col lg={3} sm={12}><input id="txtFarcoPlus" type="text"  className="w-100 customfontsize"/></Col>


                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3} sm={12}> <label className="customfontsize">Beema</label></Col>
                                    <Col lg={3} sm={12}> <input id="txtBeema" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3} sm={12}> <label className="customfontsize text-danger">Beema+</label></Col>
                                    <Col lg={3} sm={12}><input id="txtBeemaPlus" type="text"  className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3} sm={12}><label className="customfontsize">Total</label></Col>
                                    <Col lg={3} sm={12}> <input id="txtTotal" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3} sm={12}><label className="customfontsize">Comments</label></Col>

                                </Row>
                                <Row>

                                </Row>
                                <Row className="mt-2">

                                    <textarea id="txtComments" className="customfontsize"></textarea>
                                </Row>

                                <Row >
                                    <Col lg={12} md={6} sm={12}><Button onClick={saveAmount} variant={BtnSave} className="w-100 mt-4"  >Submit</Button></Col>
                                   </Row>
                                <Row className="mt-4"><hr/></Row>
                                <Row >
                                    <Col lg={12} md={6} sm={12}><Button onClick={getPendingStock}  variant="danger" className="w-100 "  >Pending Stock</Button></Col>
                                </Row>
                                <div id="ddlDiv" className="overflow-scroll ">
                                    <div className="tableDiv">
                                        <BootstrapTable  keyField='id' data={DataPending} columns={ColumnsPendingStock} > </BootstrapTable>
                                    </div>

                                </div>

                            </Col>
                            <Col lg={3} md={3} sm={12}>
                                <div className="purchaseDiv text-center">
                                    <label>Total Stock Received Summary</label>
                                    <Row>
                                        <select className="m-4 w-75 float-right" id="typeAccountsSummary"  >
                                            <option value="0">-Select Type-</option>
                                            <option value="0">Farco</option>
                                            <option value="0">Farco_Plus</option>
                                            <option value="0">Beema</option>
                                            <option value="0">Beema_Plus</option>
                                            <option value="0">All Stock</option>

                                        </select>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={5}>
                                            <label  >Total Production</label>
                                        </Col>
                                        <Col lg={5} >
                                            <label className="customfontsize " id="lblAmount" ></label>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={12} md={6} sm={12}><Button variant={BtnTotalStock} onClick={getStockSummary}  className="w-75 m-4">Report</Button></Col>
                                       </Row>
                                    <hr />
                                    <label>Available Stock Summary</label>
                                    <Row>
                                        <select className="m-4 w-75 float-right" id="cmbAvailableStock"  >
                                            <option value="0">-Select Type-</option>
                                            <option value="0">Farco</option>
                                            <option value="0">Farco_Plus</option>
                                            <option value="0">Beema</option>
                                            <option value="0">Beema_Plus</option>
                                            <option value="0">All Stock</option>

                                        </select>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={5}>
                                            <label>{}</label>
                                        </Col>
                                        <Col lg={5} >
                                            <label className="customfontsize " id="lblAvailableStock" ></label>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={12} md={6} sm={12}><Button variant={BtnAvailableStock} onClick={getAvailableStock}  className="w-75 m-4">Available Stock</Button></Col>
                                         </Row>

                                </div>




                            </Col>
                            <Col lg={5} md={3} sm={12}>
                                <div className="purchaseDiv text-center"> <label>Stock Detailes Report</label>

                                    <Row>
                                        <select className="m-4 w-75 float-right" id="typeAccountsDetailes"  >
                                            <option value="0">-Select Type-</option>
                                            <option value="0">New</option>
                                            <option value="0">Return</option>

                                        </select>
                                    </Row>

                                    <Row>
                                        <Col lg={3}>
                                            <label>Date From</label>
                                        </Col>
                                        <Col lg={4}>
                                            <DatePicker selected={startDateDetailsFrom}
                                                        onChange={(date) =>setStartDateDetailsFrom (date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col lg={3}>
                                            <label >Date To</label>
                                        </Col>
                                        <Col lg={3}>

                                            <DatePicker selected={startDateDetailesTo}
                                                        onChange={(date) => setStartDateDetailesTo(date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>





                                    </Row>


                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={getDetails}  variant={BtnStockDetailes} className="w-75 m-4">Report</Button></Col>
                                    </Row>

                                    <div id="ddlDiv" className="overflow-scroll ">
                                        <div className="tableDiv">
                                            <BootstrapTable  keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                                        </div>

                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </Container>
                </div>


            </Fragment>
        )
    }

}