
import React, {Component, Fragment, useEffect, useState} from 'react';
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useDispatch,useSelector} from "react-redux";

export default function Login(){


    let audio=new Audio("/Button.mp3");
    const  playSound= ()=>{
        audio.play()
    }
    let smsSound=new Audio("/sms.mp3");
    const  PlaySms= ()=>{
        smsSound.play()
    }




    let dispatch=useDispatch();
    let state=useSelector((state)=> state);


    const[user,setUser]=useState('');
    const[redirect,setRedirect]=useState('');

    const [BtnChange,setBtnChange]=useState("Login")
    const [BtnColor,setBtnColor]=useState("success")

    const[Access,SetAccess]=useState("admin")
   const getLogin=()=>{
       playSound();
        let UserName= document.getElementById("UserName").value;
        let Password = document.getElementById("Password").value;


        setUser(UserName)



        let jsonObject=
            {
                UserName:UserName,
                Password:Password,
            };


        if(UserName==""){cogoToast.error("Please inser User Name")}else{
            if(Password==""){cogoToast.error("Please inser Password")}else{
                setBtnColor("secondary")
                setBtnChange("Wait...")
                RestClient.PostRequest(AppUrl.getAccount,jsonObject).then(result=>{

                    if(result=="admin" || result=="accounts" || result=="sr" || result=="sm" || result=="cm" || result=="production" || result=="pack" || result=="stock" || result=="rm" || result=="hr" || result=="worker" || result=="user"){
                        cogoToast.success("Login Succed");
                        PlaySms();
                        dispatch({type:"access",payload:result});
                        dispatch({type:"name",payload:UserName});

                        setRedirect('/home')
                        setBtnChange("Login")
                        setBtnColor("success")

                        RestClient.PostRequest(AppUrl.getDistrict,{"sql":"select * from District_Tbl"}).then(result=>{dispatch({type:"district",payload:result}); });
                        RestClient.PostRequest(AppUrl.getUpazila,{"sql":"select * from Upazila_Tbl"}).then(result=>{dispatch({type:"upazila",payload:result}); });
                        RestClient.PostRequest(AppUrl.getUnion,{"sql":"select * from Union_Tbl"}).then(result=>{dispatch({type:"union",payload:result});});
                        RestClient.PostRequest(AppUrl.getBazar,{"sql":"select * from Bazar_Tbl"}).then(result=>{dispatch({type:"bazar",payload:result}); });
                        RestClient.PostRequest(AppUrl.getData,{"sql":"select * from Hr_Category "}).then(result=>{dispatch({type:"hrCategory",payload:result}); });
                        RestClient.PostRequest(AppUrl.getData,{"sql":"select * from Supplier "}).then(result=>{dispatch({type:"supplier",payload:result}); });

                    }else{
                        cogoToast.error("Login Failed")
                        setBtnChange("Login")
                        setBtnColor("success")
                    }


                }).catch(error=>{
                    cogoToast.error(error);
                    setBtnChange("Login")
                    setBtnColor("success")
                });
            }
        }





    }
    const checkLogin=()=>{



        if(user==""){
            cogoToast.error("Please Login First")
            playSound();
        }else{
            playSound();
        }
    }
    return(
        <Fragment>


            <div className="purchaseDiv text-center">
                <Nav.Link className="navItem "><NavLink to="/"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                <h3>Login Form</h3>
                <hr/>
                <Container >


                    <Row>
                        <Col lg={4}>

                        </Col>

                        <Col lg={4}>
                            <Row>
                                <label className="customfontsize">User name</label>
                                <input id="UserName" type="text" className="w-100 customfontsize ml-1"/>
                            </Row>
                            <Row>
                                <label className="customfontsize">Password</label>
                                <input id="Password" type="password" className="w-100  customfontsize"/>
                            </Row>


                            <Row >
                                <Col lg={6} md={6} sm={12}>

                                    <Button onClick={getLogin} variant={BtnColor} className="w-100 mt-4">{BtnChange}</Button>

                                </Col>
                                <Col  lg={6} md={6} sm={12}>
                                    <NavLink to={redirect} state={{ Access: "Manager" }}>
                                        <Button onClick={checkLogin}  variant="danger" className="w-100 mt-4" >Go to Home</Button>
                                    </NavLink>



                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Container>
            </div>



        </Fragment>
    )
}