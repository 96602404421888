import {Fragment} from "react";

import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NewEmployee from "./NewEmployee";
import DealerSupplier from "./DealerSupplier";
import NewUser from "./NewUser";


export default function Employee(){

    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Home"></Tab>
                    <Tab eventKey="newEmployee" title="New Employee"><NewEmployee/></Tab>
                    <Tab eventKey="dealer" title="Dealer/Supplier">
                        <DealerSupplier/>
                    </Tab>
                    <Tab eventKey="absent" title="Absent"></Tab>
                    <Tab eventKey="User" title="User Creation"><NewUser/></Tab>

                </Tabs>
            </div>
        </Fragment>
    )
}