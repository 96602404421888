import {Fragment} from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import NewEmployee from "../components/HR/NewEmployee";
import DealerSupplier from "../components/HR/DealerSupplier";
import NewUser from "../components/HR/NewUser";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import DB from "../components/Setting/DB";

export default function DbPage(){
    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center">
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Home"><DB/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}