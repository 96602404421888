import {Fragment, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";


export default function ChemicalEntry(){
    let state=useSelector((state)=> state);
    const [BtnSave,setBtnsave]=useState("success");
    const saveChemical=()=>{
            let ChemicalName=document.getElementById("txtName").value;
            let ChemicalQty=document.getElementById("txtNoQty").value;
            let Drum=document.getElementById("txtDrum").value;
            let NoText=document.getElementById("txtNoText").value;

            let UserName=state.name;


        let jsonObject= {
            "sql":"INSERT INTO `Chemical_Entry` (`Chemical_No`, `Chemical_Name`, `Qty`, `Drum_Colour`,`Comments`, `Entry_User`, `Entry_Date`)" +
                " VALUES (NULL, '"+ChemicalName+"','"+ChemicalQty+"', '"+Drum+"', '"+NoText+"', '"+UserName+"', current_timestamp()) "};



        if(ChemicalName==""){cogoToast.error("Please insert Chemical Name")}else{
            if(ChemicalQty==""){cogoToast.error("Please insert Chemical Qty")}else{
            if(Drum==""){cogoToast.error("Please insert Drum Color")}else{
                if(NoText==""){cogoToast.error("Please insert Comments")}else{
                    setBtnsave("secondary");
                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                        if(result==1){cogoToast.success("Data Saved")
                            setBtnsave("success")
                        }else{setBtnsave("success"); cogoToast.error("Data not saved")}


                    }).catch(error=>{
                        cogoToast.error(error);setBtnsave("success")

                    });
                }}
            }
        }




    }


    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Container>
                    <Row>

                        <Col lg={5} md={3} sm={12}>
                            <Row className="mt-2">
                                <Col lg={12}><label className="customfontsize text-danger">Chemical Entry Form</label></Col>

                            </Row>

                            <Row  className="mt-2">
                                <Col lg={6}><label className="customfontsize">Chemical Name</label></Col>
                                <Col lg={6}><input id="txtName" type="text"  className="w-100 customfontsize"/></Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={6}><label className="customfontsize">Chemical Qty</label></Col>
                                <Col lg={6}><input id="txtNoQty" type="text"  className="w-100 customfontsize"/></Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={6}><label className="customfontsize">Drum Color</label></Col>
                                <Col lg={6}><input id="txtDrum" type="text"  className="w-100 customfontsize"/></Col>
                            </Row>



                            <Row>
                                <label className="customfontsize">Comments</label>
                                <textarea id="txtNoText" className="customfontsize"></textarea>
                            </Row>
                            <Row >
                                <Col lg={12} md={6} sm={12}><Button variant={BtnSave} onClick={saveChemical}  className="w-100 mt-4"  >Submit</Button></Col>

                            </Row>

                        </Col>

                        <Col lg={7} md={3} sm={12}>
                            <div className="purchaseDiv text-center"> <label className="text-danger">Chemical Detailes Report</label>


                            </div>

                        </Col>
                    </Row>

                </Container>

            </div>
        </Fragment>
    )
}