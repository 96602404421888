import {Fragment, useRef, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
export default function InvoicePayment(){
    let state=useSelector((state)=> state);
    const ref=useRef();
    const[Data, setData]=useState([]);
    const[Column, setColumn]=useState([{}]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const[OrderNo,setOrderNo]=useState();
    const[DeliveryNo,setDeliveryNo]=useState();
    const[DealerName,setDealerName]=useState();
    const[InvoiceNo,setInvoiceNo]=useState();


    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setInvoiceNo(row["Invoice_No"]);
            setOrderNo(row["Order_No"]);
            setDeliveryNo(row["Delivery_No"]);
            setDealerName(row["Dealer"]);
           handleShow()


        }
    }

    const invoicePayment=()=>{
        let InvoiceAmount=document.getElementById("txtInvoiceAmount").value;
        let InvoiceComments=document.getElementById("txtInvoiceComments").value;




        let jsonObject={"sql":"INSERT INTO `Invoice_Payment` (`Payment_No`, `Order_No`, `Delivery_No`, `Invoice_No`, `Dealer`, `Amount`, `Status`, `Comments`, `Entry_User`, `Entry_Date`)" +
                " VALUES (NULL, '"+OrderNo+"', '"+DeliveryNo+"', '"+InvoiceNo+"', '"+DealerName+"', '"+InvoiceAmount+"', '0', '"+InvoiceComments+"','"+state.name+"', current_timestamp())"}
        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=> {

            if (result ==0) {
                cogoToast.error("Data not Saved")
            } else {cogoToast.success("Data saved")

            }


        }).catch(error=>{ cogoToast.error("Data not saved")});


    }

    const getData=()=>{

       let InvoicePayment=document.getElementById("cmbInvoicePaymentType").selectedIndex;

        let  jsonObject="";
        if(InvoicePayment==1){jsonObject={"sql":"Select * from Invoice_Payment where status=0 "}
        }else{
            jsonObject={"sql":"Select * from Invoices where Status='0'"}

        }


        setData([])
        setColumn([{dataField:"NoData",text:"No Data found"}])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=> {

            if (result == null) {
                cogoToast.error("Data not found")
            } else {
            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)
        }

        }).catch(error=>{ });



    }



    return(
        <Fragment>
            <div className="purchaseLongDiv text-center">

                <Row>
                    <Col l={2}>
                        <Row>
                            <select className="m-2 w-100 " id="cmbInvoicePaymentType" >
                                <option value="0">N0n-WriteOffInvoice</option>
                                <option value="0">Non-WriteOffPayment</option>

                            </select>
                        </Row>
                        <Row> <Button className="m-2 w-75" onClick={getData}>Search</Button></Row>


                    </Col>
                    <Col lg={10}>
                        <div className="purchaseDiv overflow-scroll">
                            <BootstrapTable  keyField='Order_No' data={Data} columns={Column} selectRow={selectRow}  id="tbl" > </BootstrapTable>
                        </div>
                    </Col>
                </Row>


            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to Complete Payment</Modal.Title>


                </Modal.Header>
                <Modal.Body>


                    <label className="w-25 mt-2">Name:</label><input value={DealerName} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Amount:</label><input  id="txtInvoiceAmount" className="w-25"/><br/>
                    <label className="w-25 mt-2">Comments:</label><input id="txtInvoiceComments"  className="w-100"/><br/>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={invoicePayment} >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}