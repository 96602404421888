import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Packageing from "../components/Production/Packageing";

export default function ProductionPage(){

    return (

        <Fragment>
            <TopNavigation />
            <Packageing/>
        </Fragment>
    );
}