import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import ExpensesIssue from "../components/Accounts/ExpensesIssue";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import NewEmployee from "../components/HR/NewEmployee";
import DealerSupplier from "../components/HR/DealerSupplier";
import NewUser from "../components/HR/NewUser";
import SalaryIssue from "../components/Accounts/SalaryIssue";

export default function ExpensIssuePage (){



    return(
        <Fragment>

            <TopNavigation/>
            <div className="purchaseDiv">
            <Tabs
                defaultActiveKey="home"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title="Home"></Tab>
                <Tab eventKey="newEmployee" title="Expese Issue"><ExpensesIssue/></Tab>
                <Tab eventKey="dealer" title="Salary Issue">
                            <SalaryIssue/>
                </Tab>


            </Tabs>
            </div>
        </Fragment>
    )
}