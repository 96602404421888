import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Order from "../components/Order/Order";
import Report from "../components/Report/Report";

export default function ReportPage(){

    return(
        <Fragment>
            <TopNavigation />
            <Report/>

        </Fragment>
    )

}