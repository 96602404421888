import React, {Fragment, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import AppUtility from "../../RestAPI/AppUtility";


export default function Formula(){
    let state=useSelector((state)=> state);
    const [BtnSaveFormula,setBtnSaveFormula]=useState("success");

    const saveFormula=()=>{


        const WoodF=document.getElementById("txtWoodF").value;
        const GumF=document.getElementById("txtGumF").value;
        const CSPF=document.getElementById("txtCspF").value;
        const TabuF=document.getElementById("txtTabuF").value;
        const StarchF=document.getElementById("txtStarchF").value;
        const ColorF=document.getElementById("txtColorF").value;
        const CommentsF=document.getElementById("txtCommentsF").value;
        const User_Name=state.name;


        let jsonObject=
            {
                "sql":"INSERT INTO `Formula` (`id`, `Wood`, `Gum`, `CSP`, `Tabu`, `Starch`, `Color`, `Comments`, `Entry_User`, `Entry_date`)" +
                    " VALUES (NULL, '"+WoodF+"', '"+GumF+"', '"+CSPF+"',  '"+TabuF+"','"+StarchF+"', '"+ColorF+"', '"+CommentsF+"', '"+User_Name+"', current_timestamp()) "
            };


        if(WoodF==""){cogoToast.error("Please insert Wood Qty")}else{
            if(GumF==""){cogoToast.error("Please insert GUM Qty")}else{
                if(CSPF==""){cogoToast.error("Please insert CSP")}else{
                    if(TabuF==""){cogoToast.error("Please insert Tabu")}else{
                        if(StarchF==""){cogoToast.error("Please insert Starch")}else{
                            if(ColorF==""){cogoToast.error("Please insert Color")}else{

                                if(CommentsF==""){cogoToast.error("Please insert Comments")}else{

                                    setBtnSaveFormula("secondary")
                                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                        if(result==1){

                                            let number="01789307792";
                                            let sms="NewFormula: "+"Wood: "+WoodF+" Gum: "+GumF+" CSP: "+CSPF+" Tabu: "+TabuF+" Starch: "+StarchF+" Color: "+ColorF+" Comments: "+CommentsF+" Sent by: "+User_Name
                                            AppUtility.sendSMS(sms,number)
                                            cogoToast.success("Data saved successfully")
                                            setBtnSaveFormula("success")
                                        }else{cogoToast.success("Data  not Saved");
                                            setBtnSaveFormula("success")}

                                    }).catch(error=>{
                                        setBtnSaveFormula("success")
                                        cogoToast.error(error);
                                    });


                                }}}}}}}
    }

    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Container>
                    <Row>



                        <Col lg={4} md={3} sm={12}>
                                    <div className="purchaseDiv text-center">
                                        <label className="text-danger">Formula Entry</label>


                                        <Row className="mt-4">
                                            <Col lg={6}> <label className="customfontsize">Wood Powder</label></Col>
                                            <Col lg={6}><input id="txtWoodF" type="text" className="w-100 customfontsize"/></Col>


                                        </Row>
                                        <Row className="mt-4">
                                            <Col lg={6}><label className="customfontsize">Gum Powder</label></Col>
                                            <Col lg={6}><input id="txtGumF" type="text" className="w-100 customfontsize"/></Col>


                                        </Row>
                                        <Row  className="mt-4">
                                            <Col lg={6}><label className="customfontsize">CSP</label></Col>
                                            <Col lg={6}><input id="txtCspF" type="text"  className="w-100 customfontsize"/></Col>


                                        </Row>
                                        <Row className="mt-4">
                                            <Col lg={6}><label className="customfontsize">Tabu Powder</label></Col>
                                            <Col lg={6}><input id="txtTabuF" type="text"  className="w-100 customfontsize"/></Col>


                                        </Row>
                                        <Row className="mt-4">
                                            <Col lg={6}> <label className="customfontsize">Starch</label></Col>
                                            <Col lg={6}><input id="txtStarchF" type="text"  className="w-100 customfontsize"/></Col>


                                        </Row>
                                        <Row className="mt-4">
                                            <Col lg={6}> <label className="customfontsize">Color</label></Col>
                                            <Col lg={6}><input id="txtColorF" type="text"  className="w-100 customfontsize"/></Col>


                                        </Row>


                                        <Row>
                                            <label className="customfontsize">Comments</label>
                                            <textarea id="txtCommentsF" className="customfontsize m-4 w-75"></textarea>
                                        </Row>
                                        <Row  >
                                            <Col lg={12} md={6} sm={12}><Button onClick={saveFormula} variant={BtnSaveFormula} className="w-100 "  >Submit</Button></Col>

                                        </Row>

                                    </div>




                                </Col>

                    </Row>

                </Container>

            </div>
        </Fragment>
    )
}