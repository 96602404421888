import {Fragment, useEffect, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";
import DealerDetailes from "../Invoice/DealerDetailes";

export default function Sales(props){
    let state=useSelector((state)=> state);

    const [Dealer, setDealer]=useState([]);
    const getDealer=()=>{
        let jsonObject={"sql":"select Id, Name, Address, Mobile from Dealer Order by Name ASC"};

        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{setDealer(result)}).catch(error=>{cogoToast.error(error);});
    }

    useEffect(() => {
        getDealer();
    }, []);

    let ColumnsDetailes=[
        {dataField: 'Entry_Date', text: 'Date', sort: true},
        {dataField: 'Dealer', text: 'Dealer', sort: true},
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco+', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema+ ', sort: true},
        {dataField: 'Total', text: 'TQ', sort: true},
        {dataField: 'Total_Price', text: 'TP', sort: true},
        {dataField: 'Status', text: 'D_Status', sort: true},

    ]
    let ColumnsSummary=[
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco+', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema+ ', sort: true},
        {dataField: 'TQ', text: 'TQ', sort: true},
        {dataField: 'TP', text: 'TP', sort: true},

    ]
    const sendSMS=(Type, Amount, Comments,number,user)=>{
        const date=new Date();
        let day=date.getDate();
        let month=date.getMonth()+1;
        let year=date.getFullYear();
        let h=date.getHours();
        let m=date.getMinutes();
        let s=date.getSeconds();

        let time= day+"/"+month+"/"+year+" "+h+":"+m+":"+s;

        let sms=""+Type+": "+Amount+" Carton, Comments: "+Comments+" Date: "+time+" sent by:'"+user+"'";
        let jsonObject=
            {
                "api_key" :"Y9FCgQ7ADxvFfwdAL2qm",
                "senderid" : "8809617611096",
                "number" : number,
                "message" :sms,

            };

        RestClient.PostRequest(AppUrl.sendSms,jsonObject).then(result=>{

        });



    }

    const [startDateSummeryFrom, setStartDateSummeryFrom] = useState(new Date());
    const [startDateSummeryTo, setStartDateSummeryTo] = useState(new Date());
    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());

    const [totalStock, setTotalStock]=useState('Total Stock')

    const [Address, setAddress]=useState("");
    const [Mobile, setMobile]=useState("");
    const [Columns, setColumns]=useState([{}]);
    const [Data, setData]=useState([]);


    const [BtnSave, setBtnSave] = useState("success");
    const [BtnReport, setBtnReport] = useState("success");

    const getDate=(date)=>{
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let sazzad=[year, month, day].join('-');
        return sazzad;
    }


    const saveDemand=()=>{
        const cmbDealerName = document.getElementById("cmbDealerName");
        const DealerName = cmbDealerName.options[cmbDealerName.selectedIndex].text;
        const Farco=document.getElementById("txtFarco").value;
        const FarcoPrice=document.getElementById("txtFarcoPrice").value;
        const FarcoTp=document.getElementById("txtFarcoTp").value;

        const FarcoPlus=document.getElementById("txtFarcoPlus").value;
        const FarcoPlusPrice=document.getElementById("txtFarcoPlusPrice").value;
        const FarcoPlusTp=document.getElementById("txtFarcoPlusTp").value;

        const Beema=document.getElementById("txtBeema").value;
        const BeemaPrice=document.getElementById("txtBeemaPrice").value;
        const BeemaTp=document.getElementById("txtBeemaTp").value;

        const BeemaPlus=document.getElementById("txtBeemaPlus").value;
        const BeemaPlusPrice=document.getElementById("txtBeemaPlusPrice").value;
        const BeemaPlusTp=document.getElementById("txtBeemaPlusTp").value;

        const TotalPrice=document.getElementById("txtTotalPrice").value;
        const TotalCarton=document.getElementById("txtTotalCarton").value;
        const Comments=document.getElementById("txtComments").value;
        const Status="Ok";
        const User_Name=state.name;
        let jsonObject=
            {
                "sql":"INSERT INTO `Sales_Demand` (`Id`, `Dealer`, `Address`, `Mobile`, `Farco`, `Farco_Price`, `Farco_TP`, `Farco_Plus`, `Farco_Plus_Price`, `Farco_Plus_TP`, `Beema`, `Beema_Price`, `Beema_TP`, `Beema_Plus`, `Beema_Plus_Price`, `Beema_Plus_TP`, `Total_Price`, `Total_Carton`, `Comments`, `Status`, `Entry_Date`, `Entry_User`) " +
                    "VALUES (NULL, '"+DealerName+"', '"+Address+"', '"+Mobile+"', '"+Farco+"', '"+FarcoPrice+"', '"+FarcoTp+"', '"+FarcoPlus+"', '"+FarcoPlusPrice+"', '"+FarcoPlusTp+"', '"+Beema+"', '"+BeemaPrice+"', '"+BeemaTp+"', '"+BeemaPlus+"', '"+BeemaPlusPrice+"', '"+BeemaPlusTp+"', '"+TotalPrice+"', '"+TotalCarton+"', '"+Comments+"', 'No', current_timestamp(), '"+User_Name+"') "}
                if(cmbDealerName.selectedIndex==0){cogoToast.warn("Please select Delear Name")}else{
                    if(Farco==""){cogoToast.warn("Please Insert Farco Qty")}else{
                    if(FarcoPrice==""){cogoToast.warn("Please Insert Farco Price")}else{
                    if(FarcoTp==""){cogoToast.warn("Please Insert Farco Total Price")}else{
                    if(FarcoPlus==""){cogoToast.warn("Please Insert Farco+ Qty")}else{
                    if(FarcoPlusPrice==""){cogoToast.warn("Please Insert Farco+ Price")}else{
                    if(FarcoPlusTp==""){cogoToast.warn("Please Insert Farco Plus Total Price")}else{
                    if(Beema==""){cogoToast.warn("Please Insert Beema Qty")}else{
                    if(BeemaPrice==""){cogoToast.warn("Please Insert Beema Price")}else{
                    if(BeemaTp==""){cogoToast.warn("Please Insert Beema Total price")}else{
                    if(BeemaPlus==""){cogoToast.warn("Please Insert Beema+ Qty")}else{
                    if(BeemaPlusPrice==""){cogoToast.warn("Please Insert Beema+ Price")}else{
                    if(BeemaPlusTp==""){cogoToast.warn("Please Insert Beema+ Total Price")}else{
                    if(TotalPrice==""){cogoToast.warn("Please Insert Total Price")}else{
                    if(TotalCarton==""){cogoToast.warn("Please Insert Total Carton")}else{
                    if(Comments==""){cogoToast.warn("Please Insert Comments")}else{

                        setBtnSave("secondary")

                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                if(result==1){
                                    cogoToast.success("Sales Demand Saved Successfully");
                                    let Number="01789307792,01722750101,01738735422"
                                    let sms="Sales demand:"+" Dealer Name: "+DealerName+" B:"+Beema+" B+:"+BeemaPlus+" F:"+Farco+" F+:"+FarcoPlus+" Total carton: "+TotalCarton+" TP: "+TotalPrice+" Comments: "+Comments+" sent by: "+User_Name
                                    AppUtility.sendSMS(sms,Number)
                                     setBtnSave("success")
                                }else{
                                    cogoToast.success("Sales Demand not Saved");
                                     setBtnSave("success")
                                }

                        }).catch(error=>{
                            cogoToast.error(error);
                            setBtnSave("success")
                        });
                    }}}}}}}}}}}}}}}
                }





    }
    const getDetails=()=>{

        const ReportType = document.getElementById("cmbReportType");
        let DateDetailesFrom= getDate(startDateDetailsFrom);
        let DateDetailesTo= getDate(startDateDetailesTo);

        let jsonObject="";
        if(ReportType.selectedIndex==0){
            jsonObject={"sql":"select Dealer,Farco, Farco_Plus,Beema, Beema_Plus,Entry_Date,Total_Carton As Total,Total_Price, Status from Sales_Demand where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "};
            setColumns(ColumnsDetailes)
        }else{
            jsonObject={"sql":"SELECT sum(Farco)AS Farco, SUM(Farco_Plus)AS Farco_Plus,sum(Beema)AS Beema, SUM(Beema_Plus)AS Beema_Plus ,sum(Total_Carton)AS TQ, SUM(Total_Price)AS TP FROM `Sales_Demand` where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "};
            setColumns(ColumnsSummary)
        }

            if(startDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
                if(startDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{
                        setBtnReport("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                        if(result==""){cogoToast.error("Data not found")}else{

                        }

                        setData( result)
                        setBtnReport("success")

                    }).catch(error=>{
                        cogoToast.error(error);
                        setBtnReport("success")
                    });

                }}

    }

const getDelaerDetailes=()=>{
    const typeAccountsDetailes = document.getElementById("cmbDealerName");
    const typeAccountsDetailesValue = typeAccountsDetailes.options[typeAccountsDetailes.selectedIndex].text;
    if(typeAccountsDetailes.options[typeAccountsDetailes.selectedIndex].value==0){
        setMobile("");setAddress("")
    }else{
        let jsonObject=
            {
                "sql":"select Address,Mobile from Dealer where Name='"+typeAccountsDetailesValue+"' "
            };
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            setAddress(result[0]["Address"])
            setMobile(result[0]["Mobile"])
        }).catch(error=>{
            cogoToast.error(error);
        });
    }




}

    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                <h3>Sales Demand Form</h3>
                <hr/>

                <Container>
                    <Row>

                        <Col lg={6} md={3} sm={12}>
                            <div className="purchaseDiv text-center">
                                <Row >
                                    <Col lg={3} sm={6}><label className="customfontsize m-4">Name</label></Col>
                                    <Col lg={9} sm={6}>
                                    <select className="m-4 w-75 float-right" id="cmbDealerName" onChange={getDelaerDetailes} >
                                        <option value="0">-Select Type-</option>
                                        {


                                            Dealer.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>

                                    </Col>
                                </Row>
                            <Row className="mr-3">
                                <Col lg={3} sm={6}>  <label className="customfontsize w-75">Address</label></Col>
                                <Col lg={9} sm={6}> <label id="lblAddress" className="customfontsize w-75">{Address}</label></Col>

                            </Row>
                            <Row className="mt-4">
                                <Col lg={3} sm={6}>  <label className="customfontsize w-75">Mobile</label></Col>
                                <Col lg={9} sm={6}> <label id="lblMobile" className="customfontsize w-75">{Mobile}</label></Col>

                            </Row>
                            <Row className="mt-4">

                                <Col lg={2} sm={6}>  <label className="customfontsize text-danger w-75">Farco</label></Col>
                                <Col lg={2} sm={6}> <input id="txtFarco" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize ">Price</label></Col>
                                <Col lg={2} sm={6} > <input id="txtFarcoPrice" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize text-success"> T.P</label></Col>
                                <Col lg={2} sm={6} > <input id="txtFarcoTp" type="text" className="w-100 customfontsize"/></Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={2} sm={6}>  <label className="customfontsize text-danger w-75">Farco+</label></Col>
                                <Col lg={2} sm={6}> <input id="txtFarcoPlus" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize">Price</label></Col>
                                <Col lg={2} sm={6} > <input id="txtFarcoPlusPrice" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize text-success"> T.P</label></Col>
                                <Col lg={2} sm={6} > <input id="txtFarcoPlusTp" type="text" className="w-100 customfontsize"/></Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={2} sm={6}>  <label className="customfontsize text-danger w-75">Beema</label></Col>
                                <Col lg={2} sm={6}> <input id="txtBeema" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize">Price</label></Col>
                                <Col lg={2} sm={6} > <input id="txtBeemaPrice" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize text-success">T.P</label></Col>
                                <Col lg={2} sm={6} > <input id="txtBeemaTp" type="text" className="w-100 customfontsize"/></Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={2} sm={6}>  <label className="customfontsize text-danger w-75">Beema+</label></Col>
                                <Col lg={2} sm={6}> <input id="txtBeemaPlus" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize">Price</label></Col>
                                <Col lg={2} sm={6} > <input id="txtBeemaPlusPrice" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={2} sm={6} >   <label className="customfontsize text-success">T.P</label></Col>
                                <Col lg={2} sm={6} > <input id="txtBeemaPlusTp" type="text" className="w-100 customfontsize"/></Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} sm={6}>  <label className="customfontsize text-danger w-100">Total Price</label></Col>
                                <Col lg={3} sm={6}> <input id="txtTotalPrice" type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={4} sm={6}>  <label className="customfontsize text-danger w-100">Total Carton</label></Col>
                                <Col lg={2} sm={6}> <input id="txtTotalCarton" type="text" className="w-100 customfontsize"/></Col>

                            </Row>
                            <Row>

                            </Row>
                            <Row>
                                <label className="customfontsize">Comments</label>
                                <textarea id="txtComments" className="customfontsize m-4 w-75"></textarea>
                            </Row>

                            <Row >
                                <Col lg={6} md={6} sm={12}><Button onClick={saveDemand} variant={BtnSave} className="w-100 mt-4">Save</Button></Col>

                            </Row>
                            </div>
                        </Col>

                        <Col lg={6} md={3} sm={12}>
                            <div className="purchaseDiv text-center "> <label className="mb-2">Daily sales Demand Report</label>

                                <Row>
                                    <Col lg={3} sm={12}><label className="customfontsize">Type</label></Col>
                                    <Col lg={3} sm={12}><select className="float-start w-100" id="cmbReportType"  >

                                        <option value="0">Details Sales</option>
                                        <option value="0">Summary</option>
                                    </select></Col>
                                </Row>


                                <Row>
                                    <Col lg={3}>
                                        <label>Date From</label>
                                    </Col>
                                    <Col lg={4}>
                                        <DatePicker selected={startDateDetailsFrom}
                                                    onChange={(date) =>setStartDateDetailsFrom (date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}>
                                        <label >Date To</label>
                                    </Col>
                                    <Col lg={3}>

                                        <DatePicker selected={startDateDetailesTo}
                                                    onChange={(date) => setStartDateDetailesTo(date)}
                                                    dateFormat='dd-MM-yyyy'
                                                    seletable
                                                    maxDate={new Date()}
                                                    isClearable
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                        />
                                    </Col>





                                </Row>


                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={getDetails}  variant={BtnReport} className="w-75 m-4">Report</Button></Col>
                                </Row>

                                <div id="ddlDiv" className="overflow-scroll ">
                                    <div className="tableDiv">
                                        <BootstrapTable  keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                                    </div>

                                </div>

                            </div>

                        </Col>
                    </Row>
                    <hr/>
                    <label className="m-4 fs-4 text-danger">Dealer wise Report</label>
                        <DealerDetailes/>
                </Container>
            </div>
        </Fragment>
    )
}
