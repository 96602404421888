import {Fragment, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import Loading from "../Loading/Loading";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";

export default function Drying(){

    let state=useSelector((state)=> state);
    let [Columns, setColumns]=useState([{}])

    let ColumnsDrying=[
        {dataField: 'Entry_Date', text: 'Dry_Date', sort: true},
        {dataField: 'Coil_Type', text: 'Type', sort: true},
        {dataField: 'Production_No', text: 'P_No', sort: true},
        {dataField: 'Dryer_Man', text: 'Dry_Man', sort: true},
        {dataField: 'Total_Hour', text: 'Hour', sort: true},
        {dataField: 'Total_Tray', text: 'Tray ', sort: true},
        {dataField: 'Total_Wood', text: 'Wood', sort: true},
        {dataField: 'Generator', text: 'Generator', sort: true},
        {dataField: 'Status', text: 'Packageing', sort: true},
        {dataField: 'Comments', text: 'Comments_By_The_User', sort: true},

    ]
    let ColumnsCutting=[
        {dataField: 'Production_No', text: 'Production', sort: true},
        {dataField: 'Entry_Date', text: 'P_Date', sort: true},
        {dataField: 'Coil_Type', text: 'Type', sort: true},
        {dataField: 'Tray', text: 'Tray', sort: true},
        {dataField: 'Comments', text: 'Comments_by_the_user', sort: true},

    ]



    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());




    const [BtnSave,setBtnSave]=useState("success")
    const [BtnSaveFormula,setBtnSaveFormula]=useState("success")
    const [BtnReport,setBtnReport]=useState("success")



    const  Loader=false;


    const [Data, setData]=useState([]);




    const saveDrying=()=>{




        const CoilType = document.getElementById("cmbCoilType");
        const CoilTypeValue = CoilType.options[CoilType.selectedIndex].text;

        const ProductionNo=document.getElementById("txtProduction").value;
        const DryerRoom=document.getElementById("txtDryer").value;

        const DryerMan = document.getElementById("cmbDryerMan");
        const DryerManValue = DryerMan.options[DryerMan.selectedIndex].text;

        const StartTime=document.getElementById("txtStartTime").value;
        const EndTime=document.getElementById("txtEndTime").value;
        const TotalHour=document.getElementById("txtTotalHour").value;
        const TotalTray=document.getElementById("txtTotalTray").value;
        const TotalWood=document.getElementById("txtTotalWood").value;
        const Generator=document.getElementById("txtGenerator").value;
        const Comments=document.getElementById("txtComments").value;
        const User_Name=state.name;


        let jsonObject=
            {
                "sql":"INSERT INTO `Coil_Drying` (`Id`, `Coil_Type`, `Production_No`, `Dryer_Room`, `Dryer_Man`, `Start_Time`, `End_Time`, `Total_Hour`, `Total_Tray`, `Total_Wood`, `Generator`, `Comments`, `Status`, `Entry_User`, `Entry_Date`)" +
                    " VALUES (NULL, '"+CoilTypeValue+"', '"+ProductionNo+"', '"+DryerRoom+"', '"+DryerManValue+"', '"+StartTime+"', '"+EndTime+"', '"+TotalHour+"', '"+TotalTray+"', '"+TotalWood+"', '"+Generator+"', '"+Comments+"','No', '"+User_Name+"', current_timestamp())"
            };

            if(CoilType.selectedIndex==0){cogoToast.error("Please insert Coil Type")}else{
            if(ProductionNo==""){cogoToast.error("Please insert Production No")}else{
                if(DryerRoom==""){cogoToast.error("Please insert Dryer Room")}else{
                    if(DryerMan.selectedIndex==0){cogoToast.error("Please insert Dryer Man")}else{
                        if(StartTime==""){cogoToast.error("Please insert Start Time")}else{
                            if(EndTime==""){cogoToast.error("Please insert End Time")}else{
                                if(TotalHour==""){cogoToast.error("Please insert Total Hour")}else{
                                if(TotalTray==""){cogoToast.error("Please insert Total Tray")}else{
                                    if(TotalWood==""){cogoToast.error("Please insert Total Wood")}else{
                                        if(Generator==""){cogoToast.error("Please insert Generator Hour")}else{
                                            if(Comments==""){cogoToast.error("Please insert Comments")}else{

                                                setBtnSave("secondary")
                                                RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                                    if(result==1){
                                                        RestClient.PostRequest(AppUrl.getData, {"sql":"UPDATE Coil_Cutting SET Status ='Yes' WHERE Production_No = '"+ProductionNo+"'"}).then(result=>{}).catch(error=>{});
                                                        let number="01789307792";
                                                        let sms="Coil Drying: Total Hour: "+TotalHour+" and Tray: "+TotalTray+" Total Wood: "+TotalWood+" Gen: "+Generator+" Comments: "+Comments+" Sent by: "+User_Name
                                                        AppUtility.sendSMS(sms,number)
                                                        cogoToast.success("Data saved successfully")
                                                        setBtnSave("success")
                                                    }else{cogoToast.success("Data  not Saved");
                                                        setBtnSave("success")}

                                                }).catch(error=>{
                                                    setBtnSave("success")
                                                    cogoToast.error(error);
                                                });


                                            }}}}}}}}}}}
    }


    const getReport=()=>{
        if(document.getElementById("cmbReportType").selectedIndex==0){cogoToast.error("Please select report type")}else{
            if(document.getElementById("cmbReportType").selectedIndex==1){getCutting();}
            if(document.getElementById("cmbReportType").selectedIndex==2){getDryReport();}

        }


    }
    const getCutting=()=>{




        let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);
        let jsonObjectProduction=
            {
                "sql":"select Production_No, Entry_Date,Coil_Type,Tray,Comments from Coil_Cutting where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' and Status='No' "
              };
                    setBtnReport("secondary")
        RestClient.PostRequest(AppUrl.getData,jsonObjectProduction).then(result=>{
            if(result==""){cogoToast.error("Data not found");setData([]);setColumns(ColumnsCutting);setBtnReport("success")}else{

                setColumns(ColumnsCutting)
                setData(result)
                setBtnReport("success")

            }

            console.log(result);setBtnReport("success")


        }).catch(error=>{
            cogoToast.error(error);

        });

    }
    const getDryReport=()=>{

        let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);
        let jsonObjectProduction=
            {
                "sql":"select * from Coil_Drying where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' and Status='No'"
            };

        RestClient.PostRequest(AppUrl.getData,jsonObjectProduction).then(result=>{
            if(result==""){cogoToast.error("Data not found");setData([]);setColumns(ColumnsDrying)}else{

                setColumns(ColumnsDrying)
                setData(result)

            }

            console.log(result)


        }).catch(error=>{
            cogoToast.error(error);

        });

    }



    if(Loader==true){
        return(
            <Fragment>

                <Loading/>



            </Fragment>
        )
    }else{
        return(
            <Fragment>


                <div className="purchaseDiv text-center">
                    <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                    <h3>Coil Drying Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>

                            <Col lg={5} md={3} sm={12}>
                                <Row className="mt-2">
                                    <Col lg={12}><label className="customfontsize text-danger">Daily Coil Drying Detailes</label></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}><label className="customfontsize">Coil Type</label></Col>
                                    <Col lg={3}>
                                        <select className=" w-100 float-right" id="cmbCoilType"  >
                                        <option value="0">-Select Type-</option>
                                        <option value="0">Round</option>
                                        <option value="0">Angel</option>

                                    </select>
                                    </Col>
                                    <Col lg={3}><label className="customfontsize">Poducton_No</label></Col>
                                    <Col lg={3}><input id="txtProduction" type="text" className="w-100 customfontsize"/></Col>

                                </Row>

                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Dryer Room</label></Col>
                                    <Col lg={3}><input id="txtDryer" type="text" className="w-100 customfontsize"/></Col>
                                    <Col lg={3}><label className="customfontsize">Dryer Man</label></Col>
                                    <Col lg={3}><select className=" w-100 float-right" id="cmbDryerMan"  >
                                        <option value="0">-Select Type-</option>
                                        <option value="0">Babu</option>
                                        <option value="0">Azmol</option>

                                    </select></Col>

                                </Row>

                                <Row  className="mt-2">
                                    <Col lg={3}><label className="customfontsize">Start Time</label></Col>
                                    <Col lg={3}><input id="txtStartTime" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}><label className="customfontsize">End Time</label></Col>
                                    <Col lg={3}><input id="txtEndTime" type="text"  className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Total Hour</label></Col>
                                    <Col lg={3}><input id="txtTotalHour" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}> <label className="customfontsize">Total Tray</label></Col>
                                    <Col lg={3}><input id="txtTotalTray" type="text"  className="w-100 customfontsize"/></Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col lg={3}><label className="customfontsize">Total Wood</label></Col>
                                    <Col lg={3}><input id="txtTotalWood" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}> <label className="customfontsize">Generator</label></Col>
                                    <Col lg={3}><input id="txtGenerator" type="text"  className="w-100 customfontsize"/></Col>
                                </Row>

                                <Row>
                                    <label className="customfontsize">Comments</label>
                                    <textarea id="txtComments" className="customfontsize"></textarea>
                                </Row>
                                <Row >
                                    <Col lg={12} md={6} sm={12}><Button onClick={saveDrying}  variant={BtnSave} className="w-100 mt-4"  >Submit</Button></Col>

                                </Row>

                            </Col>

                            <Col lg={7} md={3} sm={12}>
                                <div className="purchaseDiv text-center"> <label className="text-danger">Drying Detailes Report</label>

                                    <Row>
                                        <select className="m-4 w-50 float-right" id="cmbReportType"  >
                                            <option value="0">-Select Report-</option>
                                            <option value="0">Cutting Report</option>
                                            <option value="0">Drying report</option>

                                        </select>
                                    </Row>

                                    <Row>
                                        <Col lg={3}>
                                            <label>Date_From</label>
                                        </Col>
                                        <Col lg={4}>
                                            <DatePicker selected={startDateDetailsFrom}
                                                        onChange={(date) =>setStartDateDetailsFrom (date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col lg={3}>
                                            <label >Date To</label>
                                        </Col>
                                        <Col lg={4}>

                                            <DatePicker selected={startDateDetailesTo}
                                                        onChange={(date) => setStartDateDetailesTo(date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>





                                    </Row>


                                    <Row >
                                        <Col lg={12} md={6} sm={12}><Button onClick={getReport}  variant={BtnReport} className="w-75 m-4">Report</Button></Col>
                                    </Row>

                                    <div id="ddlDiv" className="overflow-scroll ">
                                        <div className="tableDiv">
                                            <BootstrapTable  keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                                        </div>

                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </Container>
                </div>


            </Fragment>
        )
    }

}