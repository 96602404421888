import {Fragment, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import Loading from "../Loading/Loading";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";

export default function Packageing(){

    let state=useSelector((state)=> state);
    let [Columns, setColumns]=useState([{}])

    let ColumnsPacking=[

        {dataField: 'Entry_Date', text: 'Pack_Date', sort: true},
        {dataField: 'id', text: 'ID', sort: true},
        {dataField: 'Production_No', text: 'P_No', sort: true},
        {dataField: 'Worker', text: 'Worker', sort: true},
        {dataField: 'Beema', text: 'Beema', sort: true},
        {dataField: 'Beema_Plus', text: 'Beema+ ', sort: true},
        {dataField: 'Farco', text: 'Farco', sort: true},
        {dataField: 'Farco_Plus', text: 'Farco+', sort: true},
        {dataField: 'Total_Coil', text: 'Total_Carton', sort: true},
        {dataField: 'Status', text: 'Statue', sort: true},
        {dataField: 'Comments', text: 'Comments_By_The_User', sort: true},

    ]
    let ColumnsDrying=[

        {dataField: 'Production_No', text: 'P_No', sort: true},
        {dataField: 'Entry_Date', text: 'Dry_Date', sort: true},
        {dataField: 'Coil_Type', text: 'Type', sort: true},
        {dataField: 'Dryer_Room', text: 'Dry_Room', sort: true},
        {dataField: 'Dryer_Man', text: 'Dry_Man', sort: true},
        {dataField: 'Total_Tray', text: 'Tray', sort: true},
        {dataField: 'Status', text: 'Status', sort: true},
        {dataField: 'Comments', text: 'Comments_by_the_user', sort: true},

    ]



    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());




    const [BtnSave,setBtnSave]=useState("success")
    const [BtnSaveFormula,setBtnSaveFormula]=useState("success")
    const [BtnReport,setBtnReport]=useState("success")



    const  Loader=false;


    const [Data, setData]=useState([]);

    const getDate=(date)=>{
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let sazzad=[year, month, day].join('-');
        return sazzad;
    }


    const savePacking=()=>{




        const CoilType = document.getElementById("cmbCoilType");
        const CoilTypeValue = CoilType.options[CoilType.selectedIndex].text;

        const ProductionNo=document.getElementById("txtProduction").value;
        const Fogzol=document.getElementById("txtFogzol").value;
        const DryOil=document.getElementById("txtDryOil").value;
        const Perfume=document.getElementById("txtPerfume").value;
        const Costape=document.getElementById("txtCostape").value;
        const Polithin=document.getElementById("txtPolithin").value;
        const Stand=document.getElementById("txtStand").value;
        const InnerCarton=document.getElementById("txtInnerCarton").value;
        const MasterCarton=document.getElementById("txtMasterCarton").value;
        const PolithinWastage=document.getElementById("txtPolithinWastage").value;
        const CoilWastage=document.getElementById("txtCoilWastage").value;
        const Worker=document.getElementById("txtWorker").value;
        const Beema=document.getElementById("txtBeema").value;
        const BeemaPlus=document.getElementById("txtBeemaPlus").value;
        const Farco=document.getElementById("txtFarco").value;
        const FarcoPlus=document.getElementById("txtFarcoPlus").value;
        const TotalCoil=document.getElementById("txtTotalCoil").value;
        const Comments=document.getElementById("txtComments").value;
        const User_Name=state.name;


        let jsonObject=
            {
                "sql":"INSERT INTO `Coil_Packaging` (`Id`, `Coil_Type`, `Production_No`, `Fogzol`, `Dry_Oil`, `Perfume`, `Costape`, `Polithin`,`Stand`,  `Inner_carton`, `Master_Carton`, `Polithin_wastage`, `Coil_Wastage`, `Worker`, `Beema`, `Beema_Plus`, `Farco`, `Farco_Plus`,`Total_Coil`, `Comments`,`Status`, `Entry_User`, `Entry_Date`)" +
                    " VALUES (NULL, '"+CoilTypeValue+"', '"+ProductionNo+"', '"+Fogzol+"', '"+DryOil+"', '"+Perfume+"', '"+Costape+"', '"+Polithin+"','"+Stand+"', '"+InnerCarton+"', '"+MasterCarton+"', '"+PolithinWastage+"', '"+CoilWastage+"', '"+Worker+"', '"+Beema+"', '"+BeemaPlus+"', '"+Farco+"', '"+FarcoPlus+"','"+TotalCoil+"', '"+Comments+"','No', '"+User_Name+"', current_timestamp()) "};

            if(CoilType.selectedIndex==0){cogoToast.error("Please insert Coil Type")}else{
            if(ProductionNo==""){cogoToast.error("Please insert Production No")}else{
                if(Fogzol==""){cogoToast.error("Please insert Fogzol")}else{
                    if(DryOil==""){cogoToast.error("Please insert Dry Oil")}else{
                        if(Perfume==""){cogoToast.error("Please insert Perfume")}else{
                            if(Costape==""){cogoToast.error("Please insert Costape")}else{
                                if(Polithin==""){cogoToast.error("Please insert Polithin")}else{
                                if(Stand==""){cogoToast.error("Please insert Stand")}else{
                                if(InnerCarton==""){cogoToast.error("Please insert Inner carton")}else{
                                    if(MasterCarton==""){cogoToast.error("Please insert Master Carton")}else{
                                        if(CoilWastage==""){cogoToast.error("Please insert CoilWastage")}else{
                                         if(PolithinWastage==""){cogoToast.error("Please insert PolithinWastage")}else{
                                             if(Beema==""){cogoToast.error("Please insert Beema")}else{
                                                 if(BeemaPlus==""){cogoToast.error("Please insert Beema Plus")}else{
                                                     if(Farco==""){cogoToast.error("Please insert Farco")}else{
                                                         if(FarcoPlus==""){cogoToast.error("Please insert farco Plus")}else{
                                                         if(TotalCoil==""){cogoToast.error("Please insert Total Coil")}else{


                                        if(Worker==""){cogoToast.error("Please insert Worker")}else{
                                            if(Comments==""){cogoToast.error("Please insert Comments")}else{

                                                setBtnSave("secondary")
                                                RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                                    if(result==1){
                                                        RestClient.PostRequest(AppUrl.getData, {"sql":"UPDATE Coil_Drying SET Status ='Yes' WHERE Production_No = '"+ProductionNo+"'"}).then(result=>{}).catch(error=>{});

                                                        let number="01789307792";
                                                        let sms="Coil Package: "+"Carton: "+TotalCoil+" wastage: "+ CoilWastage+" Kg"+" Worker: "+Worker+" Comments: "+Comments+" By: "+User_Name
                                                        AppUtility.sendSMS(sms,number)
                                                        cogoToast.success("Data saved successfully")
                                                        setBtnSave("success")
                                                    }else{cogoToast.success("Data  not Saved");
                                                        setBtnSave("success")}

                                                }).catch(error=>{
                                                    setBtnSave("success")
                                                    cogoToast.error(error);
                                                });


                                            }}}}}}}}}}}}}}}}}}}
    }


    const getReport=()=>{
        if(document.getElementById("cmbReportType").selectedIndex==0){cogoToast.error("Please select report type")}else{
            if(document.getElementById("cmbReportType").selectedIndex==1){getDryReport();}
            if(document.getElementById("cmbReportType").selectedIndex==2){getPackingReport();}

        }


    }
    const getDryReport=()=>{

        let DateDetailesFrom= getDate(startDateDetailsFrom);
        let DateDetailesTo= getDate(startDateDetailesTo);
        let jsonObjectProduction=
            {
                "sql":"select Entry_Date, Coil_Type,Production_No,Dryer_Room,Dryer_Man,Total_Tray,Comments,Status from Coil_Drying where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' and Status='No' "
              };
                    setBtnReport("secondary")
        RestClient.PostRequest(AppUrl.getData,jsonObjectProduction).then(result=>{
            if(result==""){cogoToast.error("Data not found");setBtnReport("success")}else{

                setColumns(ColumnsDrying)
                setData(result)
                setBtnReport("success")

            }

            console.log(result);setBtnReport("success")


        }).catch(error=>{
            cogoToast.error(error);

        });

    }
    const getPackingReport=()=>{

        let DateDetailesFrom= getDate(startDateDetailsFrom);
        let DateDetailesTo= getDate(startDateDetailesTo);
        let jsonObjectProduction=
            {
                "sql":"SELECT id,Entry_Date,Production_No,Worker,Beema,Beema_Plus,Farco,Farco_Plus,Total_Coil,Comments,Status FROM `Coil_Packaging`  where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
            };

        RestClient.PostRequest(AppUrl.getData,jsonObjectProduction).then(result=>{
            if(result==""){cogoToast.error("Data not found")}else{

                setColumns(ColumnsPacking)
                setData(result)

            }

            console.log(result)


        }).catch(error=>{
            cogoToast.error(error);

        });

    }



    if(Loader==true){
        return(
            <Fragment>

                <Loading/>



            </Fragment>
        )
    }else{
        return(
            <Fragment>


                <div className="purchaseDiv text-center">
                    <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                    <h3>Coil Packageing Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>

                            <Col lg={7} md={3} sm={12}>
                                <Row className="mt-2">
                                    <Col lg={12}><label className="customfontsize text-danger">Daily Packing Detailes</label></Col>



                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}><label className="customfontsize">Coil Type</label></Col>
                                    <Col lg={3}>
                                        <select className=" w-100 float-right" id="cmbCoilType"  >
                                        <option value="0">-Select Type-</option>
                                        <option value="0">Round</option>
                                        <option value="0">Angel</option>

                                    </select>
                                    </Col>
                                    <Col lg={3}><label className="customfontsize">Production No</label></Col>
                                    <Col lg={3}><input id="txtProduction" type="text" className="w-100 customfontsize"/></Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Fogzol</label></Col>
                                    <Col lg={3}><input id="txtFogzol" type="text" className="w-100 customfontsize"/></Col>
                                    <Col lg={3}><label className="customfontsize">Dry Oil</label></Col>
                                    <Col lg={3}><input id="txtDryOil" type="text" className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row  className="mt-2">
                                    <Col lg={3}><label className="customfontsize">Perfume</label></Col>
                                    <Col lg={3}><input id="txtPerfume" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}><label className="customfontsize">Costape</label></Col>
                                    <Col lg={3}><input id="txtCostape" type="text"  className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Polithin</label></Col>
                                    <Col lg={3}><input id="txtPolithin" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3} ><label className="customfontsize ">Stand</label></Col>
                                    <Col lg={3}><input id="txtStand" type="text"  className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Inner Carton</label></Col>
                                    <Col lg={3}><input id="txtInnerCarton" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}><label className="customfontsize">Maser carton</label></Col>
                                    <Col lg={3}><input id="txtMasterCarton" type="text"  className="w-100 customfontsize"/></Col>




                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}><label className="customfontsize">Coil wastage</label></Col>
                                    <Col lg={3}><input id="txtCoilWastage" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}> <label className="customfontsize">Polithin Wastage</label></Col>
                                    <Col lg={3}><input id="txtPolithinWastage" type="text"  className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Beema</label></Col>
                                    <Col lg={3}><input id="txtBeema" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}> <label className="customfontsize">Beema Plus</label></Col>
                                    <Col lg={3}><input id="txtBeemaPlus" type="text"  className="w-100 customfontsize"/></Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Farco</label></Col>
                                    <Col lg={3}><input id="txtFarco" type="text"  className="w-100 customfontsize"/></Col>
                                    <Col lg={3}> <label className="customfontsize">Farco Plus</label></Col>
                                    <Col lg={3}><input id="txtFarcoPlus" type="text"  className="w-100 customfontsize"/></Col>
                                </Row>
                                 <Row className="mt-2">
                                     <Col lg={3}> <label className="customfontsize">Worker</label></Col>
                                     <Col lg={3}><input id="txtWorker" type="text"  className="w-100 customfontsize"/></Col>
                                     <Col lg={3}> <label className="customfontsize">Total Coil</label></Col>
                                     <Col lg={3}><input id="txtTotalCoil" type="text"  className="w-100 customfontsize"/></Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col lg={3}> <label className="customfontsize">Comments</label></Col>
                                    <Col lg={9}><textarea id="txtComments" className="customfontsize w-100"></textarea></Col>
                                </Row>
                                <Row >
                                    <Col lg={12} md={6} sm={12}><Button onClick={savePacking}  variant={BtnSave} className="w-100 mt-4"  >Submit</Button></Col>

                                </Row>

                            </Col>
                            <Col lg={5} md={3} sm={12}>
                                <div className="purchaseDiv text-center"> <label className="text-danger">Stock Detailes Report</label>

                                    <Row>
                                        <select className="m-4 w-75 float-right" id="cmbReportType"  >
                                            <option value="0">-Select Report-</option>
                                            <option value="0">Drying Report</option>
                                            <option value="0">Packing report</option>

                                        </select>
                                    </Row>

                                    <Row>
                                        <Col lg={3}>
                                            <label>Date From</label>
                                        </Col>
                                        <Col lg={4}>
                                            <DatePicker selected={startDateDetailsFrom}
                                                        onChange={(date) =>setStartDateDetailsFrom (date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col lg={3}>
                                            <label >Date To</label>
                                        </Col>
                                        <Col lg={3}>

                                            <DatePicker selected={startDateDetailesTo}
                                                        onChange={(date) => setStartDateDetailesTo(date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>





                                    </Row>


                                    <Row >
                                        <Col lg={12} md={6} sm={12}><Button onClick={getReport}  variant={BtnReport} className="w-75 m-4">Report</Button></Col>
                                    </Row>

                                    <div id="ddlDiv" className="overflow-scroll ">
                                        <div className="tableDiv">
                                            <BootstrapTable  keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                                        </div>

                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </Container>
                </div>


            </Fragment>
        )
    }

}