import {Fragment, useRef, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
export default function Invoice(){
    let state=useSelector((state)=> state);
    const ref=useRef();
    const[Data, setData]=useState([]);
    const[Column, setColumn]=useState([{}]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const[OrderNo,setOrderNo]=useState();
    const[DeliveryNo,setDeliveryNo]=useState();
    const[DealerName,setDealerName]=useState();


    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setOrderNo(row["Order_No"]);
            setDeliveryNo(row["Delivery_No"]);
            setDealerName(row["Dealer"]);
           handleShow()


        }
    }

    const createInvoice=()=>{
        let InvoiceAmount=document.getElementById("txtInvoiceAmount").value;
        let InvoiceComments=document.getElementById("txtInvoiceComments").value;




        let jsonObject={"sql":"INSERT INTO `Invoices` (`Invoice_No`, `Dealer`, `Order_No`, `Delivery_No`, `Total_Amount`, `Entry_User`, `Entry_Date`, `Status`, `Comments`)" +
                " VALUES (NULL, '"+DealerName+"', '"+OrderNo+"', '"+DeliveryNo+"', '"+InvoiceAmount+"', '"+state.name+"', current_timestamp(), '0', '"+InvoiceComments+"')"}
        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=> {

            if (result ==0) {
                cogoToast.error("Data not Saved")
            } else {cogoToast.success("Data saved")
                RestClient.PostRequest(AppUrl.updateData,{"sql":"Update Delivery set Status=1 where Order_No='"+OrderNo+"' "}).then(result=> {

                    if (result ==0) {
                        cogoToast.error("Status not Changed ")
                    } else {cogoToast.success("Status Changed")
                        getData()
                    }


                }).catch(error=>{ cogoToast.error("Data not saved")});
            }


        }).catch(error=>{ cogoToast.error("Data not saved")});


    }

    const getData=()=>{

        let Order_No="Delivery.Order_No"
        let Dealer="Delivery.Dealer";
        let Farco="Delivery.Farco";
        let FarcoPrice="Sales_Demand.Farco_Price";
        let FarcoPlus="Delivery.Farco_Plus";
        let FarcoPlusPrice="Sales_Demand.Farco_Plus_Price";
        let Beema="Delivery.Beema";
        let BeemaPrice="Sales_Demand.Beema_Price";
        let BeemaPlus="Delivery.Beema_Plus";
        let BeemaPlusPrice="Sales_Demand.Beema_Plus_Price";
        let TotalPrice="Sales_Demand.Total_Price";
        let Delivery_date="Delivery.Entry_Date";
        let Delivery_No="Delivery.Id As Delivery_No";

       let InvoiceType=document.getElementById("cmbInvoiceType").selectedIndex;

        let  jsonObject="";
        if(InvoiceType==0){jsonObject={"sql":"select "+Order_No+","+Dealer+", "+Farco+","+FarcoPrice+","+FarcoPlus+","+FarcoPlusPrice+","+Beema+","+BeemaPrice+","+BeemaPlus+","+BeemaPlusPrice+","+TotalPrice+","+Delivery_date+","+Delivery_No+" from Delivery inner join Sales_Demand on Delivery.Order_No=Sales_Demand.Id where Delivery.Status=0"}
        }else{
            jsonObject={"sql":"Select * from Invoices where Status='0'"}

        }


        setData([])
        setColumn([{dataField:"NoData",text:"No Data found"}])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=> {

            if (result == null) {
                cogoToast.error("Data not found")
            } else {
            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)
        }

        }).catch(error=>{ });



    }



    return(
        <Fragment>
            <div className="purchaseLongDiv text-center">

                <Row>
                    <Col l={2}>
                        <Row>
                            <select className="m-2 w-75 " id="cmbInvoiceType" >
                                <option value="0">Pending Invoice</option>
                                <option value="0">Invoices</option>

                            </select>
                        </Row>
                        <Row> <Button className="m-2 w-75" onClick={getData}>Search</Button></Row>


                    </Col>
                    <Col lg={10}>
                        <div className="purchaseDiv overflow-scroll">
                            <BootstrapTable  keyField='Order_No' data={Data} columns={Column} selectRow={selectRow}  id="tbl" > </BootstrapTable>
                        </div>
                    </Col>
                </Row>


            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to Create Invoice ?</Modal.Title>


                </Modal.Header>
                <Modal.Body>


                    <label className="w-25 mt-2">Name:</label><input value={DealerName} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Amount:</label><input  id="txtInvoiceAmount" className="w-25"/><br/>
                    <label className="w-25 mt-2">Comments:</label><input id="txtInvoiceComments"  className="w-100"/><br/>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={createInvoice} >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}