import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Nav, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
import {NavLink} from "react-router-dom";
import AppUtility from "../../RestAPI/AppUtility";
export default function ExpensesIssue(){
    let state=useSelector((state)=> state);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[Data, setData]=useState([]);

    const[ID, setID]=useState();
    const[Name, setName]=useState();
    const[DealerMobile, setDealerMobile]=useState();
    const[Address, setAddress]=useState();
    const[Items, setItems]=useState();
    const[Amount, setAmount]=useState();
    const[Mobile, setMobile]=useState();
    const[Column, setColumn]=useState([{}]);





    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row["Name"]);
            setDealerMobile(row["Mobile"]);
            setID(row["Id"]);
            setAddress(row["Address"]);
            setItems(row["Type"]);
            setMobile(row["Mobile"]);
            setAmount(row["Amount"]);
            if(document.getElementById("cmbType").selectedIndex!=0 && state.access=="admin"){
                handleShow();
            }


        }
    }



    const getReceiverName=()=>{

        let Type=document.getElementById("cmbType").selectedIndex;

        if(Type==0){

            setData([]);setColumn([{dataField:"NoData",text:"No Data found"}])
            RestClient.PostRequest(AppUrl.getData,{"sql":"Select * From Supplier"}).then(result=>{
                if (result==null){cogoToast.error("No Data found")}else {
                    setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    setData(result)
                }
            }).catch(error=>{cogoToast.error(error)});
        }else{
            let Sql=""
            if(state.access=="admin"){Sql="Select * From Expenses  "}else{Sql="Select * From Expenses where   Entry_User='"+state.name+"' "}
           setData([{}])
            RestClient.PostRequest(AppUrl.getData,{"sql":Sql}).then(result=>{
                if (result==null){cogoToast.error("No Data found")}else {
                    setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    setData(result)
                }
            }).catch(error=>{cogoToast.error(error);});
        }

    }


    const saveExpenseIssue=()=>{

        let ExpenseName=document.getElementById("txtExpenseNoName").value
        let ExpenseId=document.getElementById("txtExpenseNoId").value
        let ExpenseAddress=document.getElementById("txtExpenseNoAddress").value
        let ExpenseMobile=document.getElementById("txtExpenseNoMobile").value
        let ExpenseItem=document.getElementById("txtExpenseItemName").value
        let ExpenseComments=document.getElementById("txtExpenseNoComments").value
        let ExpenseAmounts=document.getElementById("txtExpenseAmount").value

        let jsonObject={"sql":"INSERT INTO `Expenses` (`Id`, `Receiver_Id`, `Name`, `Address`, `Mobile`, `Item`, `Amount`, `Comments`, `Status`, `Entry_user`, `Entry_Date`, `P_Amount`, `P_Comments`, `P_Date`, `P_User`)" +
            " VALUES (NULL, '"+ExpenseId+"', '"+ExpenseName+"', '"+ExpenseAddress+"', '"+ExpenseMobile+"', '"+ExpenseItem+"', '"+ExpenseAmounts+"', '"+ExpenseComments+"', 'Pending', '"+state.name+"', current_timestamp(), '', '', NULL, '')"}
        if(ExpenseId==""){cogoToast.error("Please insert ID")}else{
            if(ExpenseName==""){cogoToast.error("Please insert Name")}else{
                if(ExpenseAddress==""){cogoToast.error("Please insert Address")}else{
                    if(ExpenseMobile==""){cogoToast.error("Please insert Mobile")}else{
                        if(ExpenseItem==""){cogoToast.error("Please insert Item")}else{
                            if(ExpenseAmounts==""){cogoToast.error("Please insert Amounts")}else{
                                if(ExpenseComments==""){cogoToast.error("Please insert Comments")}else{

                                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                        if(result==0){cogoToast.error("Data not saved")}else {
                                            cogoToast.success("Sent for Approval")
                                            let sms = ExpenseAmounts + " Taka" + " expnese issued to " + ExpenseName + " for " + ExpenseItem + " by " + state.name

                                            AppUtility.sendSMS(sms, "01789307792")
                                        }
                                    }).catch(error=>{cogoToast.error(error);});
                                }
                            }
                        }
                    }
                }
            }
        }



    }
    const approvedExpense=()=>{

        let confirmAmount=document.getElementById("txtConfirmAmount").value;
        let confirmStatus=document.getElementById("cmbConfirmStatus");
        let confirmStatusValue = confirmStatus.options[confirmStatus.selectedIndex].text;
        if(confirmAmount==""){cogoToast.error("Please Confirm amount")}else{

            RestClient.PostRequest(AppUrl.saveData,{"sql":"UPDATE `Expenses` set `Amount`='"+confirmAmount+"',`Status`='"+confirmStatusValue+"' WHERE Id='"+ID+"' "}).then(result=>{
                cogoToast.success(confirmStatusValue);
                AppUtility.sendSMS("You have a new payment advice: "+confirmAmount+" Taka for "+Name,"01737924639")
                handleClose();

            }).catch(error=>{cogoToast.error(error);});
        }



    }

    return(
        <Fragment>
            <div className="purchaseDiv text-center">

            <Container>
                <Row>
                    <Col lg={4} sm={12}>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Receiver ID</label></Col>
                            <Col lg={6}><input id="txtExpenseNoId" value={ID}/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Name</label></Col>
                            <Col lg={6}><input id="txtExpenseNoName" value={Name}/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Address</label></Col>
                            <Col lg={6}><input id="txtExpenseNoAddress" value={Address}/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Mobile</label></Col>
                            <Col lg={6}><input id="txtExpenseNoMobile" value={Mobile}/></Col>

                        </Row>


                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Item Name</label></Col>
                            <Col lg={6}> <input id="txtExpenseItemName" value={Items} type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Taka</label></Col>
                            <Col lg={6}> <input id="txtExpenseAmount" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Comments</label></Col>
                            <Col lg={6}><textarea id="txtExpenseNoComments"></textarea></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}> </Col>
                            <Col lg={6}><Button onClick={saveExpenseIssue} className="w-100">Save</Button></Col>
                        </Row>
                    </Col>
                    <Col lg={8} sm={12}>
                        <div className="purchaseDiv text-center">
                            <label className="text-danger">Getting Dealer and Supplier</label>
                            <Row className="mt-2 text-justify">
                                <Col lg={2}><label className="customfontsize">Type</label></Col>
                                <Col lg={10}>
                                    <select className=" w-25 " id="cmbType" >
                                        <option value="0">-Receiver-</option>
                                        <option value="0">-Expenses Issue-</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row >
                                <Col lg={2} md={6} sm={12}></Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Button onClick={getReceiverName}  className="w-100 m-4"  >Search</Button></Col>

                            </Row>
                            <div className="tableDiv overflow-scroll">
                                <BootstrapTable  keyField='Id' data={Data} columns={Column} selectRow={selectRow} id="tbl"> </BootstrapTable>
                            </div>


                        </div>
                    </Col>
                </Row>

            </Container>

            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to Approved ?</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label className="w-25">ID:</label><input value={ID} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Name:</label><input value={Name} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Amount:</label><input value={Amount}  className="w-25"/><br/>
                    <label className="w-25 mt-2">Confirm:</label><input id="txtConfirmAmount"   className="w-25"/><br/>
                    <label className="w-25 mt-2">Status:</label>
                    <select className=" w-25 " id="cmbConfirmStatus" >
                        <option value="0">Approved</option>
                        <option value="0">Rejected</option>

                    </select>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={approvedExpense} >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}