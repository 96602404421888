import {Fragment} from "react";
import Expenditure from "../components/Accounts/Expenditure";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import {Button, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import NewEmployee from "../components/HR/NewEmployee";
import DealerSupplier from "../components/HR/DealerSupplier";
import NewUser from "../components/HR/NewUser";
import ExpensesPayment from "../components/Accounts/ExpensesPayment";

export default function AccountPage(){


    return(
        <Fragment>
            <TopNavigation />
            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>
                <h3>Accounts management</h3>
                <hr/>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Home"><Expenditure/></Tab>
                    <Tab eventKey="expense" title="Expense"><ExpensesPayment/></Tab>


                </Tabs>
            </div>
        </Fragment>
    );
}