import {Fragment, useState} from "react";
import {NavLink} from "react-router-dom";
import {Button, Col, Container, Modal, Nav, Row} from "react-bootstrap";
import cogoToast from "cogo-toast";

import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";
export default function BhusiMill(){
    let state=useSelector((state)=> state);

    let audio=new Audio("/Button.mp3");
    const  playSound= ()=>{
        audio.play()
    }

    let smsSound=new Audio("/sms.mp3");
    const  PlaySms= ()=>{
        smsSound.play()
    }

    const [BtnSave, setBtnsave]=useState("success")
    const [BtnSummary, setBtnSummary]=useState("success")
    const [Btndetailes, setBtnDetailes]=useState("success")
    const [BtnBalance, setBtnBalance]=useState("success")
    const[ColumnDetailes, setColumnDetailes]=useState([{}]);




    const getDetails=()=>{

        const typeAccountsDetailes = document.getElementById("typeAccountsDetailes");
        const typeAccountsDetailesValue = typeAccountsDetailes.options[typeAccountsDetailes.selectedIndex].text;

        let DateDetailesFrom= AppUtility.getDate(startDateDetailsFrom);
        let DateDetailesTo= AppUtility.getDate(startDateDetailesTo);

        let jsonObject=
            {
                "sql":"SELECT Work_Type, Rm_Type,Quantity,Entry_Date FROM `BhusiMill`  where Work_Type='"+typeAccountsDetailesValue+"' and Entry_Date between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
            };

        if(typeAccountsDetailes.selectedIndex==0){cogoToast.error('Please Select Type');typeAccountsDetailes.focus()}else{
            if(startDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
                if(startDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{


                    setBtnDetailes("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                        if(result==""){
                            cogoToast.error("No Data Found")
                            setBtnDetailes("success")
                            setData( result)
                        }else{
                            setBtnDetailes("success")
                            setColumnDetailes(
                                Object.keys(result[0]).map((k) => {
                                    return {dataField: k, text: k, sort: true}
                                })
                            )
                            setData( result)
                        }
                    }).catch(error=>{
                        cogoToast.error(error);
                        setBtnDetailes("success")
                        setData( [])
                    });

                }}}

    }
    const getAmount=()=>{
        const typeAccountsSummary = document.getElementById("typeAccountsSummary");
        const typeAccountsSummaryValue = typeAccountsSummary.options[typeAccountsSummary.selectedIndex].text;

        let DateSummeryFrom= AppUtility.getDate(startDateSummeryFrom);
        let DateSummeryTo= AppUtility.getDate(startDateSummeryTo);
        let ProductionType="Production"
        let SQL="SELECT sum(Quantity)As Quantity  FROM `BhusiMill` WHERE Work_Type='"+ProductionType+"' and Entry_Date between '"+DateSummeryFrom+"' and '"+DateSummeryTo+"' "

        if(typeAccountsSummary.selectedIndex!=0){
            SQL="SELECT sum(Quantity)As Quantity  FROM `BhusiMill` WHERE Work_Type='"+ProductionType+"' and  Rm_Type='"+typeAccountsSummaryValue+"' and Entry_Date between '"+DateSummeryFrom+"' and '"+DateSummeryTo+"' "

        }


        let jsonObject=
            {
                "sql":SQL };





            if(startDateSummeryFrom==null){cogoToast.error('Please Select Date From');}else{
                if(startDateSummeryTo==null){cogoToast.error('Please Select Date To');}else{
                    setBtnSummary("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        if(result[0]["Quantity"]==null){document.getElementById("lblAmount").innerText="No Amount found";
                            cogoToast.error("No Data Found")
                            setBtnSummary("success")
                        }else{
                            document.getElementById("lblAmount").innerText=result[0]["Quantity"];
                            cogoToast.success("Data Found")
                            setBtnSummary("success")
                        }
                    }).catch(error=>{
                        cogoToast.error(error); setBtnSummary("success")
                    });
                }}
    }
    const getBalance=()=>{
        setBtnBalance("secondary")
        let jsonObject={"sql":"SELECT sum(Quantity)-(SELECT sum(Quantity) FROM `BhusiMill` WHERE Work_Type='Sent') As Total FROM `BhusiMill` WHERE Work_Type='Production' "};
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){document.getElementById("lblBalance").innerText="No Balance found";
                cogoToast.error("No Balance Found")
                setBtnBalance("success")
            }else{
                document.getElementById("lblBalance").innerText=result[0]["Total"];
                cogoToast.success("Data Found")
                setBtnBalance("success")
            }
        }).catch(error=>{
            cogoToast.error(error);
            setBtnBalance("success")
        });
    }

    const saveQuantity=()=>{
        const workType = document.getElementById("ddlWorkType");
        const workTypeValue = workType.options[workType.selectedIndex].text;

        const rmType = document.getElementById("ddlRmType");
        const rmTypeValue = rmType.options[rmType.selectedIndex].text;



        const Amount=document.getElementById("txtAmount").value;
        const Comments=document.getElementById("txtComments").value;

        let jsonObject=
            {
                "sql":"INSERT INTO `BhusiMill` (`Id`, `Work_Type`, `Rm_Type`, `Quantity`, `Comments`, `Entry_User`, `Entry_Date`) VALUES" +
                    " (NULL, '"+workTypeValue+"', '"+rmTypeValue+"', '"+Amount+"', '"+Comments+"', '"+state.name+"', current_timestamp())  "
            };

        if(workType.selectedIndex==0){cogoToast.error("Please select Work Type")}else{
            if(rmType.selectedIndex==0){cogoToast.error("Please select RM Type")}else{
                if(Amount==""){cogoToast.error("Please insert Amount")}else{
                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                        if(result==1){
                            cogoToast.success("Data Saved Successfully");
                            let number="01789307792"
                            let sms=workTypeValue+"-Total :"+Amount+" Sack- "+rmTypeValue
                            AppUtility.sendSMS(sms,number);

                        }else{
                            cogoToast.error("Data not Saved")

                        }

                    }).catch(error=>{
                        cogoToast.error(error);

                    });
                }
            }
        }


    }

    const [startDateSummeryFrom, setStartDateSummeryFrom] = useState(new Date());
    const [startDateSummeryTo, setStartDateSummeryTo] = useState(new Date());
    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());


    const [Data, setData]=useState([]);


    return(
        <Fragment>


            <Container>
                <Row>

                    <Col lg={3} md={3} sm={12}>
                        <Row>
                            <label className="customfontsize">Work Type</label>
                            <select className="float-start w-100" id="ddlWorkType"  >
                                <option value="0">Select</option>
                                <option value="0">Production</option>
                                <option value="0">Sent</option>
                                <option value="0">Buy</option>
                            </select>
                            <label className="customfontsize">RM Type</label>
                            <select className="float-start w-100" id="ddlRmType"  >
                                <option value="0">-Select-</option>
                                <option value="0">Bhusi</option>
                                <option value="0">Tetul</option>
                                <option value="0">Saw</option>
                            </select>
                        </Row>
                        <Row>
                            <label className="customfontsize">Sack</label>
                            <input id="txtAmount" type="text" className="w-100 customfontsize"/>
                        </Row>
                        <Row>

                        </Row>
                        <Row>
                            <label className="customfontsize">Comments</label>
                            <textarea id="txtComments" className="customfontsize"></textarea>
                        </Row>

                        <Row >
                            <Col lg={6} md={6} sm={12}><Button onClick={saveQuantity} variant={BtnSave} className="w-100 mt-4">Save</Button></Col>
                            <Col  lg={6} md={6} sm={12}><Button  variant="danger" className="w-100 mt-4" >Reset</Button></Col>
                        </Row>

                    </Col>
                    <Col lg={4} md={3} sm={12}>
                        <div className="purchaseDiv text-center"> <label>Production Summary</label>

                            <Row>
                                <select className="m-4 w-75 float-right" id="typeAccountsSummary"  >
                                    <option value="0">-Both Bhusi Tetul-</option>
                                    <option value="0">Bhusi</option>
                                    <option value="0">Tetul</option>
                                </select>
                            </Row>

                            <Row>
                                <Col lg={3}>
                                    <label>Date From</label>
                                </Col>
                                <Col lg={4}>
                                    <DatePicker selected={startDateSummeryFrom}
                                                onChange={(date) => setStartDateSummeryFrom(date)}
                                                dateFormat='dd-MM-yyyy'
                                                seletable
                                                maxDate={new Date()}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                    />
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}>
                                    <label >Date To</label>
                                </Col>
                                <Col lg={4}>
                                    <DatePicker selected={startDateSummeryTo}
                                                onChange={(date) => setStartDateSummeryTo(date)}
                                                dateFormat='dd-MM-yyyy'
                                                seletable
                                                maxDate={new Date()}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                    />
                                </Col>

                            </Row>

                            <Row className="mt-4">
                                <Col lg={5}>
                                    <label  >Total Production:</label>
                                </Col>
                                <Col lg={5} >
                                    <label className="customfontsize " id="lblAmount" >0</label>
                                </Col>
                                <Col lg={2}>
                                    <label >Sack</label>
                                </Col>
                            </Row>

                            <Row >
                                <Col lg={6} md={6} sm={12}><Button onClick={getAmount} variant={BtnSummary} className="w-75 m-4">Report</Button></Col>
                                <Col  lg={6} md={6} sm={12}><Button  variant="danger" className="w-75 mt-4" >Reset</Button></Col>
                            </Row>

                            <hr />
                            <label>Available Stock in Mill</label>

                            <Row className="mt-4">
                                <Col lg={5}>
                                    <label>Total Sack</label>
                                </Col>
                                <Col lg={5} >
                                    <label className="customfontsize " id="lblBalance" ></label>
                                </Col>

                            </Row>
                            <Row >
                                <Col lg={6} md={6} sm={12}><Button variant={BtnBalance} onClick={getBalance}   className="w-75 m-4">Search Stock</Button></Col>
                                <Col  lg={6} md={6} sm={12}><Button onClick={playSound}  variant="danger" className="w-75 mt-4" >Reset</Button></Col>
                            </Row>

                        </div>

                    </Col>
                    <Col lg={5} md={3} sm={12}>
                        <div className="purchaseDiv text-center"> <label>Bhusi Detailes Report</label>

                            <Row>
                                <select className="m-4 w-75 float-right" id="typeAccountsDetailes"  >
                                    <option value="0">-Select Type-</option>
                                    <option value="0">Production</option>
                                    <option value="0">Sent</option>
                                    <option value="0">Buy</option>
                                </select>
                            </Row>

                            <Row>
                                <Col lg={3}>
                                    <label>Date From</label>
                                </Col>
                                <Col lg={4}>
                                    <DatePicker selected={startDateDetailsFrom}
                                                onChange={(date) =>setStartDateDetailsFrom (date)}
                                                dateFormat='dd-MM-yyyy'
                                                seletable
                                                maxDate={new Date()}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                    />
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}>
                                    <label >Date To</label>
                                </Col>
                                <Col lg={3}>

                                    <DatePicker selected={startDateDetailesTo}
                                                onChange={(date) => setStartDateDetailesTo(date)}
                                                dateFormat='dd-MM-yyyy'
                                                seletable
                                                maxDate={new Date()}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                    />
                                </Col>





                            </Row>


                            <Row >
                                <Col lg={6} md={6} sm={12}><Button onClick={getDetails} variant={Btndetailes} className="w-75 m-4">Report</Button></Col>
                            </Row>

                            <div id="ddlDiv" className="overflow-scroll ">
                                <div className="tableDiv">
                                    <BootstrapTable  keyField='id' data={Data} columns={ColumnDetailes} > </BootstrapTable>
                                </div>

                            </div>

                        </div>

                    </Col>
                </Row>

            </Container>


        </Fragment>
    )
}
