import {createStore} from "redux";


let counterReducer=(state={name:"", access:"",district:[],upazila:[],union:[],bazar:[],sm:[],sr:[], production:[],hrCategory:[],supplier:[],connection:0 }, action)=>{

    if(action.type==="access"){ return { ...state,access:action.payload}}
    if(action.type==="name"){ return { ...state,name:action.payload}}
    if(action.type==="district"){return { ...state,district:action.payload }}
    if(action.type==="upazila"){return { ...state,upazila:action.payload}}
    if(action.type==="union"){ return { ...state,union:action.payload}}
    if(action.type==="bazar"){return { ...state,bazar:action.payload}}
    if(action.type==="sr"){ return {...state,sr:action.payload }}
    if(action.type==="sm"){ return { ...state,sm:action.payload}}
    if(action.type==="production"){return { ...state,production:action.payload } }
    if(action.type==="hrCategory"){return { ...state,hrCategory:action.payload } }
    if(action.type==="supplier"){return { ...state,supplier:action.payload } }
    if(action.type==="connection"){return { ...state,connection:action.payload } }


    return state;
}
let store =createStore(counterReducer);
export default store;