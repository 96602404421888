import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";

import Chemical from "../components/Production/Chemical";

export default function ChemicalPage(){

    return (

        <Fragment>
            <TopNavigation />
            <Chemical/>
        </Fragment>
    );
}