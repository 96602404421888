import {Fragment, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";

export default function DB(){
    let state=useSelector((state)=> state);
    const[Column, setColumn]=useState([{}]);
    const[Data, setData]=useState([]);

    const[TableColumn, setTableColumn]=useState([{}]);
    const[ColumnNameColumn, setColumnNameColumn]=useState([{}]);
    const[TableName, setTableName]=useState();
    const[TableData, setTableData]=useState([]);
    const[ColumnNameData, setColumnNameData]=useState([]);


    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setTableName(row["TablesName"]);




        }
    }
    const getData=()=>{

        let SqlType=document.getElementById("cmbSqlType").selectedIndex;


        let SQL=document.getElementById("txtSql").value;

        let jsonObject={"sql":""+SQL+""};



        if(SqlType==0){
            setData([])
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)

            }).catch(error=>{ });
        }else {
            if(SqlType==1){

                RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{

                    if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}

                }).catch(error=>{ });
            }else {
                if(SqlType==2){

                    RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{

                        if(result==1){cogoToast.success("Data Updated")}else{cogoToast.error("Data not Updated")}

                    }).catch(error=>{ });
                }else {
                    if(SqlType==3){

                        RestClient.PostRequest(AppUrl.deleteData,jsonObject).then(result=>{

                        if(result==1){cogoToast.success("Data deleted")}else{cogoToast.error("Data not deleted")}

                        }).catch(error=>{ });
                    }else {

                    }
                }
            }
        }






    }
    const getTableName=()=>{



        let jsonObject={"sql":"SELECT Table_name as TablesName from information_schema.tables where table_schema = 'farcochemical_dms'"};



        setTableData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setTableColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setTableData(result)

        }).catch(error=>{ });


    }
    const getColumnName=()=>{



        let jsonObject={"sql":"select column_name from information_schema.columns where table_schema='farcochemical_dms' and table_name='"+TableName+"'"};


        setColumnNameData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumnNameColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setColumnNameData(result)

        }).catch(error=>{ });


    }

    return(
        <Fragment>
            <Container className="purchaseDiv ">
                <Row>
                    <Col lg={2}>
                        <Row className="m-1"><Button onClick={getTableName}>Table</Button></Row>
                        <Row className="overflow-scroll"><BootstrapTable  keyField='TablesName' data={TableData} columns={TableColumn}  id="tbl" selectRow={selectRow} > </BootstrapTable></Row>
                    </Col>
                    <Col lg={2}>
                        <Row className="m-1"><Button onClick={getColumnName}>Column</Button></Row>
                        <Row className="overflow-scroll"><BootstrapTable  keyField='column_name' data={ColumnNameData} columns={ColumnNameColumn}  id="tbl"  > </BootstrapTable></Row>

                    </Col>
                    <Col lg={8}>
                        <Row className="m-1">
                            <Col lg={2}>
                                <select className=" w-100 " id="cmbSqlType" >
                                    <option value="1">getData</option>
                                    <option value="2">saveData</option>
                                    <option value="3">updateData</option>
                                    <option value="4">deleteData</option>
                                </select>
                            </Col>
                            <Col lg={9}><input className="w-100" type="text" id="txtSql"/></Col>
                            <Col lg={1}><Button className="w-10" onClick={getData}>OK</Button></Col>


                        </Row>
                        <Row>
                            <div className="purchaseDiv overflow-scroll">
                                <BootstrapTable  keyField='Id' data={Data} columns={Column}  id="tbl" > </BootstrapTable>
                            </div>
                        </Row>

                    </Col>
                </Row>

            </Container>
        </Fragment>
    )
}