import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Delivery from "../components/Delivery/Delivery";
import Order from "../components/Order/Order";

export default function OrderPage(){

    return(
        <Fragment>
            <TopNavigation />
            <Order/>

        </Fragment>
    )


}