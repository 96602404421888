import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Nav, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
import {NavLink} from "react-router-dom";
import AppUtility from "../../RestAPI/AppUtility";
export default function SalaryIssue(){
    let state=useSelector((state)=> state);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[Data, setData]=useState([]);
    const[Column, setColumn]=useState([{}]);

    const[ID, setID]=useState();
    const[Name, setName]=useState();
    const[Address, setAddress]=useState();
    const[Designation, setDesignation]=useState();
    const[Amount, setAmount]=useState();
    const[Mobile, setMobile]=useState();


    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row["Name"]);
            setID(row["Id"]);
            setAddress(row["Address"]);
            setDesignation(row["Type"]);
            setMobile(row["Mobile"]);
            setAmount(row["Due_Amount"]);
            if(document.getElementById("cmbSalaryType").selectedIndex!=0 && state.access=="admin"){
                handleShow();
            }



        }
    }



    const getEmpolyeeData=()=>{

        let TypeIndex=document.getElementById("cmbSalaryType").selectedIndex;

        let jsonObject="";

        if(TypeIndex==0){jsonObject={"sql":"select * from Employee Order by Name ASC"}}else{
            jsonObject={"sql":"select * from Employee_Salary Order by Name ASC"}
        }

            setData([])
        setColumn([{dataField:"NoData",text:"No Data found"}])
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setData(result)

            }).catch(error=>{ });


    }


    const saveSalaryIssue=()=>{

        let SalaryName=document.getElementById("txtSalaryName").value
        let SalaryId=document.getElementById("txtSalaryId").value
        let SalaryAddress=document.getElementById("txtSalaryAddress").value
        let SalaryMobile=document.getElementById("txtSalaryMobile").value
        let SalaryDesignation=document.getElementById("txtSalaryDesignation").value
        let SalaryMonthIndex=document.getElementById("cmbSalaryMonth");
        let SalaryMonth=SalaryMonthIndex.options[SalaryMonthIndex.selectedIndex].text
        let SalaryComments=document.getElementById("txtSalaryComments").value
        let SalaryAmounts=document.getElementById("txtSalaryAmount").value

        let jsonObject={"sql":"INSERT INTO `Employee_Salary` (`Id`, `Employee_Id`, `Name`, `Salary_Month`, `Due_Amount`, `Paid_Amount`, `Paid_Date`, `Comments`, `Status`, `Deduction_Amount`, `Entry_User`)" +
                " VALUES (NULL, '"+SalaryId+"', '"+SalaryName+"', '"+SalaryMonth+"', '"+SalaryAmounts+"', '0', NULL, '"+SalaryComments+"', 'Pending', '0', '"+state.name+"')"}
        if(SalaryId==""){cogoToast.error("Please insert ID")}else{
            if(SalaryName==""){cogoToast.error("Please insert Name")}else{
                if(SalaryAddress==""){cogoToast.error("Please insert Address")}else{
                    if(SalaryMobile==""){cogoToast.error("Please insert Mobile")}else{
                        if(SalaryDesignation==""){cogoToast.error("Please insert Designation")}else{
                        if(SalaryMonth==""){cogoToast.error("Please insert Month")}else{
                            if(SalaryAmounts==""){cogoToast.error("Please insert Amounts")}else{
                                if(SalaryComments==""){cogoToast.error("Please insert Comments")}else{

                                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                        cogoToast.success("Sent for Approval")
                                       // let sms=rAmounts+" Taka"+" expnese issued to "+SalaryId+" for "+rItem+" by "+state.name

                                        //AppUtility.sendSMS( sms,"01789307792")
                                    }).catch(error=>{cogoToast.error(error);});
                                }}
                            }
                        }
                    }
                }
            }
        }



    }
    const approvedExpense=()=>{

        let confirmAmount=document.getElementById("txtConfirmAmount").value;
        let confirmStatus=document.getElementById("cmbConfirmStatus");
        let confirmStatusValue = confirmStatus.options[confirmStatus.selectedIndex].text;
        if(confirmAmount==""){cogoToast.error("Please Confirm amount")}else{

            RestClient.PostRequest(AppUrl.saveData,{"sql":"UPDATE `Employee_Salary` set `Due_Amount`='"+confirmAmount+"',`Status`='"+confirmStatusValue+"' WHERE Id='"+ID+"' "}).then(result=>{
                cogoToast.success(confirmStatusValue);
                handleClose();

            }).catch(error=>{cogoToast.error(error);});
        }



    }

    return(
        <Fragment>
            <div className="purchaseDiv text-center">

                <Container>
                    <Row>
                        <Col lg={4} sm={12}>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Employee ID</label></Col>
                                <Col lg={6}><input id="txtSalaryId" value={ID}/></Col>

                            </Row>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Name</label></Col>
                                <Col lg={6}><input id="txtSalaryName" value={Name}/></Col>

                            </Row>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Address</label></Col>
                                <Col lg={6}><input id="txtSalaryAddress" value={Address}/></Col>

                            </Row>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Mobile</label></Col>
                                <Col lg={6}><input id="txtSalaryMobile" value={Mobile}/></Col>

                            </Row>


                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Designation</label></Col>
                                <Col lg={6}> <input id="txtSalaryDesignation" value={Designation} type="text" /></Col>

                            </Row>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Month</label></Col>
                                <Col lg={6}>  <select className=" w-100 " id="cmbSalaryMonth" >
                                    <option value="0">Select</option>
                                    <option value="0">January</option>
                                    <option value="0">February</option>
                                    <option value="0">March</option>
                                    <option value="0">April</option>
                                    <option value="0">May</option>
                                    <option value="0">June</option>
                                    <option value="0">July</option>
                                    <option value="0">August</option>
                                    <option value="0">September</option>
                                    <option value="0">October</option>
                                    <option value="0">December</option>

                                </select></Col>

                            </Row>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Amount</label></Col>
                                <Col lg={6}> <input id="txtSalaryAmount" type="text" /></Col>

                            </Row>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}><label className="customfontsize">Comments</label></Col>
                                <Col lg={6}><textarea id="txtSalaryComments"></textarea></Col>

                            </Row>
                            <Row className="mt-2 text-justify">
                                <Col lg={6}> </Col>
                                <Col lg={6}><Button onClick={saveSalaryIssue} className="w-100">Save</Button></Col>
                            </Row>
                        </Col>
                        <Col lg={8} sm={12}>
                            <div className="purchaseDiv text-center">
                                <label className="text-danger">Getting Dealer and Supplier</label>
                                <Row className="mt-2 text-justify">
                                    <Col lg={2}><label className="customfontsize">Type</label></Col>
                                    <Col lg={10}>
                                        <select className=" w-25 " id="cmbSalaryType" >
                                            <option value="0">-Employees-</option>
                                            <option value="1">-Salary Issued-</option>
                                        </select>
                                    </Col>

                                </Row>
                                <Row >
                                    <Col lg={2} md={6} sm={12}></Col>
                                    <Col lg={3} md={6} sm={12}>
                                        <Button onClick={getEmpolyeeData}  className="w-100 m-4"  >Search</Button></Col>

                                </Row>
                                <div className="tableDiv overflow-scroll">
                                    <BootstrapTable  keyField='Id' data={Data} columns={Column} selectRow={selectRow} id="tbl" > </BootstrapTable>
                                </div>


                            </div>
                        </Col>
                    </Row>

                </Container>

            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to Approved ?</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label className="w-25">ID:</label><input value={ID} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Name:</label><input value={Name} disabled={true} className="w-25"/><br/>
                    <label className="w-25 mt-2">Amount:</label><input value={Amount}  className="w-25"/><br/>
                    <label className="w-25 mt-2">Confirm:</label><input id="txtConfirmAmount"   className="w-25"/><br/>
                    <label className="w-25 mt-2">Status:</label>
                    <select className=" w-25 " id="cmbConfirmStatus" >
                        <option value="0">Approved</option>
                        <option value="0">Rejected</option>

                    </select>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={approvedExpense} >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}