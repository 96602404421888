import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Nav, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import Form from 'react-bootstrap/Form';
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
import {NavLink} from "react-router-dom";
import AppUtility from "../../RestAPI/AppUtility";
import DatePicker from "react-datepicker";
export default function ExpensesPayment(){
    let state=useSelector((state)=> state);

    const[Data, setData]=useState([]);

    const [StartDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [StartDateDetailesTo, setStartDateDetailesTo] = useState(new Date());
    const[ID, setID]=useState();
    const[Name, setName]=useState();
    const[Address, setAddress]=useState();
    const[Amount, setAmount]=useState();
    const[PaidTotal, setPaidTotal]=useState(0);
    const[Column, setColumn]=useState([{}]);


    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row["Name"]);

            setID(row["Id"]);
            setAddress(row["Address"]);

            setAmount(row["Amount"]);



        }
    }

const getTotal=()=>{



    RestClient.PostRequest(AppUrl.getData,{"sql":"Select Sum(P_Amount)As Total From Expenses where Status='Paid' and P_Date between '"+AppUtility.getDate(StartDateDetailesTo)+"' and '"+AppUtility.getDate(StartDateDetailsFrom)+"' " }).then(result=>{
        document.getElementById("lblPaidAmount").innerText=result[0]["Total"];

console.log(AppUtility.getDate(StartDateDetailsFrom))
console.log(AppUtility.getDate(StartDateDetailesTo))

    }).catch(error=>{cogoToast.error(error);});


}

    const getReceiverName=()=>{

        let Type=document.getElementById("cmbType").selectedIndex;

        if(Type==0){
            RestClient.PostRequest(AppUrl.getData,{"sql":"Select * From Expenses where Status='Approved'"}).then(result=>{
              if (result==null){cogoToast.error("No Data found")}else{
                  setColumn(
                      Object.keys(result[0]).map((k) => {
                          return {dataField: k, text: k, sort: true}
                      })
                  )
                  setData(result)
              }


            }).catch(error=>{cogoToast.error(error)});
        }else{

            RestClient.PostRequest(AppUrl.getData,{"sql":"Select * From Expenses where Status='Paid' "}).then(result=>{
                if (result==null){cogoToast.error("No Data found")}else{

                    setColumn(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                setData(result)
                }
                }).catch(error=>{cogoToast.error(error);});
        }

    }


    const saveExpenseIssue=()=>{

        let rName=document.getElementById("txtNoName").value

        let rAddress=document.getElementById("txtNoAddress").value

        let rComments=document.getElementById("txtNoComments").value
        let pAmounts=document.getElementById("txtNoAmount").value

        let jsonObject={"sql":"UPDATE `Expenses` SET `Status`='Paid', `P_Amount`='"+pAmounts+"',`P_Comments`='"+rComments+"',`P_Date`=current_timestamp(),`P_User`='"+state.name+"' WHERE Id='"+ID+"'"}
            if(rName==""){cogoToast.error("Please insert Name")}else{
                if(rAddress==""){cogoToast.error("Please insert Address")}else{
                            if(pAmounts==""){cogoToast.error("Please insert Amounts")}else{
                                if(rComments==""){cogoToast.error("Please insert Comments")}else{
                                    RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                                        cogoToast.success("Amount has been paid")


                                    }).catch(error=>{cogoToast.error(error);});
                                }
                            }
                        }
                    }




    }


    return(
        <Fragment>
            <div className="purchaseDiv text-center">


            <Container>
                <Row>
                    <Col lg={4} sm={12}>

                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Name</label></Col>
                            <Col lg={6}><input id="txtNoName" value={Name}/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Address</label></Col>
                            <Col lg={6}><input id="txtNoAddress" value={Address}/></Col>

                        </Row>


                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Taka</label></Col>
                            <Col lg={6}> <input id="txtNoAmount" type="text" /></Col>

                        </Row>
                        <Row className="mt-2 text-justify">

                            <Col lg={12}><textarea id="txtNoComments" className="w-100"></textarea></Col>

                        </Row>
                        <Row className="mt-2 text-justify">

                            <Col lg={12}><Button onClick={saveExpenseIssue} className="w-100">Save</Button></Col>
                        </Row>


                        <hr/>
                        <Row className="mt-2 text-justify">

                            <Col lg={12}><Button onClick={getTotal} variant="danger" className=" mt-4 w-100">Todays Total Paid</Button></Col>
                        </Row>
                        <Row className="mt-2 text-justify">
                            <label className="customfontsize">From Date</label>
                            <DatePicker selected={StartDateDetailsFrom}
                                        onChange={(date) =>setStartDateDetailsFrom (date)}
                                        dateFormat='dd-MM-yyyy'
                                        seletable
                                        maxDate={new Date()}
                                        isClearable
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                            />
                            <label className="customfontsize">To Date</label>
                            <DatePicker selected={StartDateDetailesTo}
                                        onChange={(date) => setStartDateDetailesTo(date)}
                                        dateFormat='dd-MM-yyyy'
                                        seletable
                                        maxDate={new Date()}
                                        isClearable
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                            />
                            <Col lg={2}><label id="lblPaidAmount" className=" customfontsize">Taka</label></Col>


                        </Row>



                    </Col>
                    <Col lg={8} sm={12}>
                        <div className="purchaseDiv text-center">
                            <Row className="mt-2 text-justify">
                                <Col lg={2}></Col>
                                <Col lg={10}>
                                    <select className=" w-25 " id="cmbType" >
                                        <option value="0">-Pending-</option>
                                        <option value="0">-Paid-</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row >
                                <Col lg={2} md={6} sm={12}></Col>
                                <Col lg={3} md={6} sm={12}>
                                    <Button onClick={getReceiverName}  className="w-100 m-4"  >Search</Button></Col>

                            </Row>
                            <div className="tableDiv overflow-scroll">
                                <BootstrapTable  keyField='Id' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                            </div>





                        </div>
                    </Col>
                </Row>

            </Container>

            </div>

        </Fragment>
    )
}