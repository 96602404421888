import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Report from "../components/Report/Report";
import RM from "../components/RM/RM";

export default function RmPage(){

    return(
        <Fragment>
            <TopNavigation />
            <RM/>

        </Fragment>
    )

}