import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import CoilCutting from "../components/Production/CoilCutting";

export default function CoilCuttingPage(){

    return(
        <Fragment>
            <TopNavigation />
            <CoilCutting/>

        </Fragment>
    )
}