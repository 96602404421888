import {Fragment, useState} from "react";
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import Loading from "../Loading/Loading";
import {useSelector} from "react-redux";
import AppUtility from "../../RestAPI/AppUtility";

export default function RM(){

    let state=useSelector((state)=> state);


    let Columns=[
        {dataField: 'Rm_Name', text: 'RM_Name', sort: true},
        {dataField: 'Qty', text: 'Qty', sort: true},
        {dataField: 'Qty_Unit', text: 'Unit', sort: true},
        {dataField: 'Price', text: 'Price', sort: true},
        {dataField: 'Seller', text: 'Source ', sort: true},
        {dataField: 'Entry_Date', text: 'Date', sort: true},
        {dataField: 'Comments', text: 'Comments', sort: true},

    ]



    const [startDateDetailsFrom, setStartDateDetailsFrom] = useState(new Date());
    const [startDateDetailesTo, setStartDateDetailesTo] = useState(new Date());

    const [totalStock, setTotalStock]=useState(0)
    const [totalAvailbleStcock, setTotalAvailbleStcock]=useState(0)

    const [BtnTotalStock,setBtnTotalStock]=useState("success")
    const [BtnSave,setBtnSave]=useState("success")
    const [BtnAvailableStock,setBtnAvailableStock]=useState("success")
    const [BtnAvailableStockPack,setBtnAvailableStockPack]=useState("success")
    const [BtnAvailablePack,setBtnAvailablePack]=useState("success")
    const [BtnStockDetailes,setBtnStockDetailes]=useState("success")


    const  Loader=false;

    const [Data, setData]=useState([]);

    const getDate=(date)=>{
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let sazzad=[year, month, day].join('-');
        return sazzad;
    }


    const saveRm=()=>{


        const Rm = document.getElementById("cmbRm");
        const RmName = Rm.options[Rm.selectedIndex].text;

        const Supplier = document.getElementById("cmbSupplier");
        const SupplierName = Supplier.options[Supplier.selectedIndex].text;

        const RmQty=document.getElementById("txtQty").value;

        const CmbUnit = document.getElementById("cmbUnit");
        const RmUnit = CmbUnit.options[CmbUnit.selectedIndex].text;



        const RmPrice=document.getElementById("txtPrice").value;

        const Comments=document.getElementById("txtComments").value;
        const User_Name=state.name;


        let jsonObject=
            {
                "sql":"INSERT INTO `Rm_Purchase` (`id`, `Rm_Name`, `Qty`, `Qty_Unit`, `Price`, `Seller`, `Comments`, `Entry_User`, `Entry_Date`, `Status`, `R_Qty`, `R_Comments`, `R_User`, `R_Date`) " +
                    "VALUES (NULL, '"+RmName+"', '"+RmQty+"', '"+RmUnit+"', '"+RmPrice+"', '"+SupplierName+"', '"+Comments+"', '"+User_Name+"',current_timestamp(), 'No', '', '', '', NULL)"
            };

        if(Rm.selectedIndex==0){cogoToast.error('Please Select Rawmaterials');Rm.focus()}else{
            if(RmQty==""){cogoToast.error('RM Qty  Empty');}else{
                if(CmbUnit.selectedIndex==0){cogoToast.error('Rm Unit  Empty');}else{
                if(RmPrice==""){cogoToast.error('Rm Price  Empty');}else{
                if(Supplier.selectedIndex==0){cogoToast.error('Rm Seller Empty');}else{

                if(Comments==""){cogoToast.error('Comments  Empty');}else{

                    setBtnSave("secondary")

                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                        if(result==1){
                            console.log(result)
                            setBtnSave("success")


                            cogoToast.success("RM Saved Successfully");
                            let sms="RM Purchased: "+RmName+" Qty: "+RmQty+" "+RmUnit+" Price: "+RmPrice+" Sent by "+User_Name
                            AppUtility.sendSMS(sms,"01789307792")
                        }else {
                            setBtnSave("success")
                            cogoToast.error("Stock not Saved");
                        }


                    }).catch(error=>{
                        cogoToast.error(error);
                        setBtnSave("success")
                    });
                }}}}}}

    }
    const getRmDetails=()=>{

        const Rm = document.getElementById("cmbRmDetails");
        const RmName = Rm.options[Rm.selectedIndex].text;

        let DateDetailesFrom= getDate(startDateDetailsFrom);
        let DateDetailesTo= getDate(startDateDetailesTo);

        let SQL="";

        if(Rm.selectedIndex==1){
            SQL="SELECT * FROM `Rm_Purchase` where Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
        }else{
            SQL="SELECT * FROM `Rm_Purchase` Where Rm_Name='"+RmName+"' and Entry_Date Between '"+DateDetailesFrom+"' and '"+DateDetailesTo+"' "
        }


        let jsonObject=
            {
                "sql":SQL
            };

        if(Rm.selectedIndex==0){cogoToast.error('Please Select Type');Rm.focus()}else{
            if(startDateDetailsFrom==null){cogoToast.error('Please Select Date From');}else{
                if(startDateDetailesTo==null){cogoToast.error('Please Select Date To');}else{

                    setBtnStockDetailes("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

                        setData( result)
                        setBtnStockDetailes("success")

                    }).catch(error=>{
                        setBtnStockDetailes("success")
                        cogoToast.error(error);
                    });

                }}}

    }

    const getPurchasedSummary=()=>{

        const RmSummary = document.getElementById("cmbRmSummary");
        const RmSummaryValue = RmSummary.options[RmSummary.selectedIndex].text;



        let jsonObject=
            {
                "sql":"select sum(Qty) As Total from Rm_Purchase where Rm_Name='"+RmSummaryValue+"'"
            };

        if(RmSummary.selectedIndex==0){cogoToast.error('Please Select RM');RmSummary.focus()}else{

            setBtnTotalStock("secondary")
                    RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                        console.log(result)
                        if(result[0]["Total"]==null){document.getElementById("lblRmSummary").innerText="No RM found";
                            cogoToast.error("No Data Found");
                            setBtnTotalStock("success")
                        }else{
                            document.getElementById("lblRmSummary").innerText=result[0]["Total"];
                            cogoToast.success("Data Found");
                            setBtnTotalStock("success")
                        }
                    }).catch(error=>{
                        setBtnTotalStock("success")
                        cogoToast.error(error);
                    });
                }
    }

    const getAvailableStock=()=>{
        setBtnAvailableStock("Wait...")
        const AvailableStock = document.getElementById("cmbAvailableStock");
        const AvailableStockValue = AvailableStock.options[AvailableStock.selectedIndex].text;

        let jsonObject={"sql":"SELECT sum(Qty)-(select Sum("+AvailableStockValue+") from Coil_Cutting) As Total FROM `Rm_Purchase` WHERE Rm_Name='"+AvailableStockValue+"'"};




        if(AvailableStock.selectedIndex==0){cogoToast.error('Please Select Type');AvailableStock.focus()}else{

            setBtnAvailableStock("secondary")
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                if(result[0]["Total"]==null){
                    cogoToast.error("No Data Found");
                    setBtnAvailableStock("success")
                    document.getElementById("lblAvailableStock").innerText="0";
                }else{
                    document.getElementById("lblAvailableStock").innerText= result[0]["Total"]
                    setBtnAvailableStock("success")

                }
            }).catch(error=>{
                cogoToast.error(error);
                setBtnAvailableStock("success")
            });

    }}
    const getAvailablePackStock=()=>{
        setBtnAvailablePack("Wait...")
        const AvailablePack = document.getElementById("cmbAvailablePack");
        const AvailablePackValue = AvailablePack.options[AvailablePack.selectedIndex].text;
        let jsonObject={"sql":"SELECT sum(Qty)-(select Sum("+AvailablePackValue+") from Coil_Packaging) As Total FROM `Rm_Purchase` WHERE Rm_Name='"+AvailablePackValue+"'"};

        if(AvailablePack.selectedIndex==0){cogoToast.error('Please Select Type');AvailablePack.focus()}else{

            setBtnAvailableStock("secondary")
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                if(result[0]["Total"]==null){
                    cogoToast.error("No Data Found");
                    setBtnAvailablePack("success")
                    document.getElementById("lblAvailableStockPack").innerText="0";
                }else{
                    document.getElementById("lblAvailableStockPack").innerText= result[0]["Total"]
                    setBtnAvailablePack("success")

                }
            }).catch(error=>{
                cogoToast.error(error);
                setBtnAvailablePack("success")
            });

        }}

    if(Loader==true){
        return(
            <Fragment>

                <Loading/>



            </Fragment>
        )
    }else{
        return(
            <Fragment>


                <div className="purchaseDiv text-center">
                    <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>

                    <h3>Raw Materials Entry Form</h3>
                    <hr/>

                    <Container>
                        <Row>

                            <Col lg={3} md={3} sm={12}>
                                <Row>
                                    <label className="customfontsize">Entry Type</label>
                                    <select className="float-start w-100" id="cmbRm"  >
                                        <option value="0">-Select RM-</option>
                                        <option value="0">Wood</option>
                                        <option value="0">CSP</option>
                                        <option value="0">Gum</option>
                                        <option value="0">Tabu</option>
                                        <option value="0">Starch</option>
                                        <option value="0">Color</option>
                                        <option value="0">Fogzol</option>
                                        <option value="0">Dry Oil</option>
                                        <option value="0">CF Oil</option>
                                        <option value="0">Master Carton</option>
                                        <option value="0">Inner Carton</option>
                                        <option value="0">Inner Poli</option>
                                        <option value="0">Stand</option>
                                        <option value="0">Costape</option>

                                    </select>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Quantity</label>
                                    <input id="txtQty" type="text" className="w-100 customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Unit</label>
                                    <select className="float-start w-100" id="cmbUnit"  >
                                        <option value="0">-Select RM-</option>
                                        <option value="0">Kg</option>
                                        <option value="0">Sack</option>
                                        <option value="0">Pcs</option>
                                        <option value="0">Gram</option>

                                    </select>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Price</label>
                                    <input id="txtPrice" type="text"  className="w-100 customfontsize"/>
                                </Row>
                                <Row>
                                    <label className="customfontsize">Seller</label>
                                    <select className=" w-100 float-right" id="cmbSupplier"  >
                                        <option value="0">-Select Type-</option>
                                        {
                                            state.supplier.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.id}>{ctr.Name}</option>
                                                )
                                            })

                                        }

                                    </select>
                                </Row>

                                <Row>
                                    <label className="customfontsize">Comments</label>
                                    <textarea id="txtComments" className="customfontsize"></textarea>
                                </Row>

                                <Row >
                                    <Col lg={12} md={12} sm={12}><Button onClick={saveRm}  variant={BtnSave} className="w-100 mt-4"  >Submit</Button></Col>

                                </Row>

                            </Col>
                            <Col lg={4} md={3} sm={12}>
                                <div className="purchaseDiv text-center">
                                    <label>Total Purchased Summary</label>
                                    <Row className="m-2">
                                        <Col lg={12} sm={12}> <select className="float-start w-50" id="cmbRmSummary"  >
                                            <option value="0">-Select RM-</option>
                                            <option value="0">Wood</option>
                                            <option value="0">CSP</option>
                                            <option value="0">Gum</option>
                                            <option value="0">Tabu</option>
                                            <option value="0">Starch</option>
                                            <option value="0">Color</option>
                                            <option value="0">Fogzol</option>
                                            <option value="0">Dry Oil</option>
                                            <option value="0">CF Oil</option>
                                            <option value="0">Master Carton</option>
                                            <option value="0">Inner Carton</option>
                                            <option value="0">Inner Poli</option>
                                            <option value="0">Stand</option>
                                            <option value="0">Costape small</option>
                                            <option value="0">Costape Large</option>
                                        </select></Col>

                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={5}>
                                            <label  >Total Purchased</label>
                                        </Col>
                                        <Col lg={5} >
                                            <label className="customfontsize " id="lblRmSummary" ></label>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={getPurchasedSummary} variant={BtnTotalStock}   className="w-75 m-4">Report</Button></Col>

                                    </Row>
                                    <hr />
                                    <label>Available RM Stock Summary</label>
                                    <Row className="m-2">
                                        <Col lg={12} sm={12}> <select className="float-start w-50" id="cmbAvailableStock"  >
                                            <option value="0">-Select RM-</option>
                                            <option value="0">Wood</option>
                                            <option value="0">CSP</option>
                                            <option value="0">Gum</option>
                                            <option value="0">Tabu</option>
                                            <option value="0">Starch</option>
                                            <option value="0">Color</option>

                                        </select></Col>

                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={5}>
                                            <label>{}</label>
                                        </Col>
                                        <Col lg={5} >
                                            <label className="customfontsize " id="lblAvailableStock" ></label>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={getAvailableStock} variant={BtnAvailableStock}   className="w-75">Available Stock</Button></Col>

                                    </Row>
                                    <hr />
                                    <label>Available Packing Materials</label>
                                    <Row className="m-2">
                                        <Col lg={12} sm={12}> <select className="float-start w-50" id="cmbAvailablePack"  >
                                            <option value="0">-Select RM-</option>
                                            <option value="0">Fogzol</option>
                                            <option value="0">Dry Oil</option>
                                            <option value="0">CF Oil</option>
                                            <option value="0">Master Carton</option>
                                            <option value="0">Inner Carton</option>
                                            <option value="0">Inner Poli</option>
                                            <option value="0">Stand</option>
                                            <option value="0">Costape</option>

                                        </select></Col>

                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg={5}>
                                            <label>Total RM</label>
                                        </Col>
                                        <Col lg={5} >
                                            <label className="customfontsize " id="lblAvailableStockPack" ></label>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={getAvailablePackStock} variant={BtnAvailableStockPack}   className="w-75">Available PCK</Button></Col>

                                    </Row>
                                </div>




                            </Col>
                            <Col lg={5} md={3} sm={12}>
                                <div className="purchaseDiv text-center"> <label>RM Purchased Detailes Report</label>

                                    <Row className="m-2">
                                        <Col lg={12} sm={12}> <select className="float-start w-50" id="cmbRmDetails"  >
                                            <option value="0">-Select RM-</option>
                                            <option value="0">All RM</option>
                                            <option value="0">Wood</option>
                                            <option value="0">CSP</option>
                                            <option value="0">Gum</option>
                                            <option value="0">Tabu</option>
                                            <option value="0">Starch</option>
                                            <option value="0">Color</option>
                                            <option value="0">Fogzol</option>
                                            <option value="0">Dry Oil</option>
                                            <option value="0">CF Oil</option>
                                            <option value="0">Master Carton</option>
                                            <option value="0">Inner Carton</option>
                                            <option value="0">Inner Poli</option>
                                            <option value="0">Stand</option>
                                            <option value="0">Costape</option>
                                        </select></Col>

                                    </Row>

                                    <Row>
                                        <Col lg={3}>
                                            <label>Date From</label>
                                        </Col>
                                        <Col lg={4}>
                                            <DatePicker selected={startDateDetailsFrom}
                                                        onChange={(date) =>setStartDateDetailsFrom (date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>

                                    </Row>
                                    <Row className="mt-2">
                                        <Col lg={3}>
                                            <label >Date To</label>
                                        </Col>
                                        <Col lg={3}>

                                            <DatePicker selected={startDateDetailesTo}
                                                        onChange={(date) => setStartDateDetailesTo(date)}
                                                        dateFormat='dd-MM-yyyy'
                                                        seletable
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        scrollableMonthYearDropdown
                                            />
                                        </Col>





                                    </Row>


                                    <Row >
                                        <Col lg={6} md={6} sm={12}><Button onClick={getRmDetails}   className="w-75 m-4">Report</Button></Col>
                                    </Row>

                                    <div id="ddlDiv" className="overflow-scroll ">
                                        <div className="tableDiv">
                                            <BootstrapTable  keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                                        </div>

                                    </div>

                                </div>

                            </Col>
                        </Row>

                    </Container>
                </div>


            </Fragment>
        )
    }

}