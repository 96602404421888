import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import {Button, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BhusiMill from "../components/BhusiMill/BhusiMill";

export default function BhusiMillPage(){


    return(
        <Fragment>
            <TopNavigation />
            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>
                <h3>Bhusi Mill management</h3>
                <hr/>
                <BhusiMill/>
            </div>
        </Fragment>
    );
}
