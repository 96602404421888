import {Fragment} from "react";
import Godown from "../components/Godown/Godown";
import TopNavigation from "../components/TopNavigation/TopNavigation";

export default function GodownPage(){

    return(
        <Fragment>
            <TopNavigation />
            <Godown/>
        </Fragment>
    )
}