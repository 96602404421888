import {useSelector} from "react-redux";
import {Fragment, useRef, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Col, Container, Row, Table,Nav} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {NavLink} from "react-router-dom";
import AppUtility from "../../RestAPI/AppUtility";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";


export default function Report(){
    const ref=useRef();
    let state=useSelector((state)=> state);

    const [Upazila, setUpazila]=useState([]);
    const [Union, setUnion]=useState([]);
    const [Bazar, setBazar]=useState([]);
    const [Columns, setColumns]=useState([{}]);
    const [Data, setData]=useState([]);
    const [BtnSearch, setBtnSearch]=useState("success");

    const getUpazila=()=>{


        const e = document.getElementById("ddlDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=state.upazila.filter(x => x.district_id==value);
        setUpazila(dt)
        setUnion([])
        setBazar([])

    }
    const getUnion=()=>{
        const e = document.getElementById("ddlUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=state.union.filter(x => x.upazila_id==value);
        setUnion(dt)
        setBazar([])
    }
    const getBazar=()=>{
        const e = document.getElementById("ddlUnion");
        const value = e.options[e.selectedIndex].value;
        const dt=state.bazar.filter(x => x.union_id==value);
        setBazar(dt)



    }

    const divShow=()=>{
        document.getElementById("ddlDiv").style.visibility = "visible";


    }
    const divHidden=()=>{
        document.getElementById("ddlDiv").style.visibility = "hidden";

    }
    const [startDateSummeryFrom, setStartDateSummeryFrom] = useState(new Date());
    const [startDateSummeryTo, setStartDateSummeryTo] = useState(new Date());

        let DetailesColumns=[
            {dataField: 'id', text: 'Order No', sort: true},
            {dataField: 'Order_Date', text: 'Order_Date', sort: true},
            {dataField: 'Customer_Name', text: 'Customer', sort: true},
            {dataField: 'Bazar_Name', text: 'Bazar', sort: true},
            {dataField: 'Sr_Name', text: 'SR', sort: true},
            {dataField: 'Beema', text: 'Beema', sort: true},
            {dataField: 'BeemaPlus', text: 'Beema+', sort: true},
            {dataField: 'Farco', text: 'Farco', sort: true},
            {dataField: 'FarcoPlus', text: 'Farco+', sort: true},
        ]


        let SummeryColumns=[

            {dataField: 'Beema', text: 'Beema Ctn', sort: true},
            {dataField: 'Beema_Price', text: 'Beema_Price', sort: true},

            {dataField: 'BeemaPlus', text: 'Beema+ Ctn', sort: true},
            {dataField: 'BeemaPlus_Price', text: 'Beema+ Price', sort: true},

            {dataField: 'Farco', text: 'Farco Qty', sort: true},
            {dataField: 'Farco_Price', text: 'Farco Price', sort: true},

            {dataField: 'FarcoPlus', text: 'Farco+ Ctn', sort: true},
            {dataField: 'FarcoPlus_Price', text: 'Farco+ Price', sort: true},


        ]


    const getReport=()=>{


        let ReportType = document.getElementById("cmbReportType").selectedIndex;
        let Sr = document.getElementById("cmbSr");
        let SrName=Sr.options[Sr.selectedIndex].text;

        let DateFrom=AppUtility.getDate(startDateSummeryFrom)
        let DateTo=AppUtility.getDate(startDateSummeryTo)

        let User_Entry="";
        if(state.access=="admin" || state.access=="sales" || state.access=="sm" ){
            if(Sr.selectedIndex==0){User_Entry="";}else{
                User_Entry=" And Sr_Name ='"+SrName+"' "
            }


        }else{
            if(state.access=="sr"){User_Entry=" And Sr_Name ='"+state.name+"' "}

        }

        if(ReportType==0){
            let jsonObject={"sql":"Select TRUNCATE(sum(Beema/30), 2)As Beema,sum(Beema_Price)As Beema_Price,TRUNCATE(sum(BeemaPlus/30), 2) As BeemaPlus,sum(BeemaPlus_Price)As BeemaPlus_Price,TRUNCATE(sum(Farco/30), 2)As Farco,sum(Farco_Price)As Farco_Price,TRUNCATE(sum(FarcoPlus/30), 2) As FarcoPlus,sum(FarcoPlus_Price)As FarcoPlus_Price  FROM Customer_Order where Order_Date Between '"+DateFrom+"' and '"+DateTo+"' "+User_Entry+" "};
            setBtnSearch("secondary");
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                setColumns(SummeryColumns)

                divShow()
                setData(result)
                setBtnSearch("success");
            }).catch(error=>{
                cogoToast.error(error);
                setBtnSearch("success");
            });
        }else{
            let jsonObject={"sql":"SELECT `id`,`Order_Date`,`Customer_Name`,`Bazar_Name`,`Sr_Name`,`Beema`,`BeemaPlus`,`Farco`,`FarcoPlus` FROM `Customer_Order` where Order_Date Between '"+DateFrom+"' and '"+DateTo+"' "+User_Entry+" "};
            setBtnSearch("secondary");
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                setColumns(DetailesColumns)

                divShow()
                setData(result)
                setBtnSearch("success");
            }).catch(error=>{
                cogoToast.error(error);
                setBtnSearch("success");
            });
        }


    }


    return (

        <Fragment>
            <Container >
                <div className="purchaseDiv text-center">
                    <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                    <h3>Order Report Form</h3>

                    <hr/>
                    <Container>
                        <Row>
                            <Col lg={3} md={3} sm={12}>
                                <Table>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlDistrict"  onChange={getUpazila} >
                                                <option value="0">-All District-</option>

                                                {

                                                    state.district.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlUpazila" onChange={getUnion}>
                                                <option value="0">-All Upazila-</option>

                                                {
                                                    Upazila.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })
                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlUnion"  onChange={getBazar} >
                                                <option>-All Union-</option>

                                                {

                                                    Union.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="ddlBazar"  >
                                                <option>-All Bazar-</option>

                                                {

                                                    Bazar.map((ctr,index)=>{
                                                        return (
                                                            <option key={index} value={ctr.id}>{ctr.name}</option>
                                                        )
                                                    })

                                                }


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="cmbReportType"  >
                                                <option>Summery Report</option>
                                                <option>Detailes Report</option>


                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select className="float-start w-100 customfontsize" id="cmbSr"  >
                                                <option>-All SR-</option>
                                                <option>sahin</option>
                                                <option>mehedi</option>
                                                <option>eusuf</option>

                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Row>
                                                <Col lg={3}>
                                                    <label>Date From</label>
                                                </Col>
                                                <Col lg={4}>
                                                    <DatePicker selected={startDateSummeryFrom}
                                                                onChange={(date) => setStartDateSummeryFrom(date)}
                                                                dateFormat='dd-MM-yyyy'
                                                                seletable
                                                                maxDate={new Date()}
                                                                isClearable
                                                                showYearDropdown
                                                                scrollableMonthYearDropdown
                                                    />
                                                </Col>

                                            </Row>
                                            <Row className="mt-2">
                                                <Col lg={3}>
                                                    <label >Date To</label>
                                                </Col>
                                                <Col lg={4}>
                                                    <DatePicker selected={startDateSummeryTo}
                                                                onChange={(date) => setStartDateSummeryTo(date)}
                                                                dateFormat='dd-MM-yyyy'
                                                                seletable
                                                                maxDate={new Date()}
                                                                isClearable
                                                                showYearDropdown
                                                                scrollableMonthYearDropdown
                                                    />
                                                </Col>

                                            </Row>


                                        </td>
                                    </tr>
                                </Table>

                            </Col>
                            <Col lg={3}>


                                <Row >
                                    <Col lg={6} md={6} sm={12}><Button onClick={getReport}   variant={BtnSearch} className="w-100 mt-4">Search</Button></Col>
                                    <Col lg={6} md={6} sm={12}>

                                    </Col>
                                </Row>

                            </Col>


                        </Row>

                    </Container>


                </div>


                <div id="ddlDiv" className="overflow-scroll VisibilityHidden">
                    <div className="tableDiv">
                        <Row>
                            <Col lg={2}>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="tbl"
                                    filename="Order Report"
                                    sheet="tablexls"
                                    buttonText="Download as XLS"/>
                            </Col>
                            <Col lg={1}>
                                <ReactToPrint   trigger={()=><Button className="w-100 mt-2">Print</Button>} content={()=>ref.current}  />
                            </Col>
                            <Col lg={6}>

                            </Col>
                        </Row>

                        <BootstrapTable ref={ref} id="tbl" keyField='id' data={Data} columns={Columns} > </BootstrapTable>
                    </div>

                </div>

            </Container>

        </Fragment>

    );

}