import TopNavigation from "../components/TopNavigation/TopNavigation";
import {Fragment} from "react";
import RmReceived from "../components/RM/RmReceived";

export default function RmReceivedPage(){

    return(
        <Fragment>
            <TopNavigation />
            <RmReceived/>

        </Fragment>
    )
}