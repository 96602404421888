import {useSelector} from "react-redux";
import {Fragment, useState} from "react";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {NavLink} from "react-router-dom";
import {Button, Col, Container, Nav, Row, Table} from "react-bootstrap";

export default function Customer(){


    let state=useSelector((state)=> state);

    const [Upazila, setUpazila]=useState([]);
    const [Union, setUnion]=useState([]);
    const [Bazar, setBazar]=useState([]);
    const [BtnSave, setBtnSave]=useState("success");

    const getUpazila=()=>{


        const e = document.getElementById("cmbDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=state.upazila.filter(x => x.district_id==value);
        setUpazila(dt)
        setUnion([])
        setBazar([])

    }
    const getUnion=()=>{
        const e = document.getElementById("cmbUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=state.union.filter(x => x.upazila_id==value);
        setUnion(dt)
        setBazar([])
    }
    const getBazar=()=>{
        const e = document.getElementById("cmbUnion");
        const value = e.options[e.selectedIndex].value;
        const dt=state.bazar.filter(x => x.union_id==value);
        setBazar(dt)



    }
   const saveNewCustomer=()=>{
        let district = document.getElementById("cmbDistrict");
        let upazila = document.getElementById("cmbUpazila");
        let union = document.getElementById("cmbUnion");
        let bazar = document.getElementById("cmbBazar");

        let ShopOwner = document.getElementById("txtShopOwner").value;
        let ShopName = document.getElementById("txtShopName").value;
        let Mobile = document.getElementById("txtMobile").value;

        let districtName=district.options[district.selectedIndex].text;
        let upazilaName=upazila.options[upazila.selectedIndex].text;
        let unionName=union.options[union.selectedIndex].text;
        let bazarName=bazar.options[bazar.selectedIndex].text;




        let jsonObject={
                "sql":"INSERT INTO `Customer` (`id`, `District`, `Upazila`, `Union`, `Bazar`, `Shop_Name`, `Shop_Owner`, `Mobile`, `Status`, `Sr_Name`, `Shop_Status`, `Comments`, `Entry_Date`)" +
                    " VALUES (NULL, '"+districtName+"', '"+upazilaName+"', '"+unionName+"', '"+bazarName+"', '"+ShopName+"', '"+ShopOwner+"', '"+Mobile+"', 'No', '', '', '', NULL) "

            };

        if(district.selectedIndex==0){cogoToast.error('Please Select District');}else{
            if(upazila.selectedIndex==0){cogoToast.error('Please Select Upazila');}else{
                if(union.selectedIndex==0){cogoToast.error('Please Select Union');}else{
                    if(bazar.selectedIndex==0){cogoToast.error('Please Select Bazar');}else{
                        if(ShopName==""){cogoToast.error('Shop Name  Empty');}else{
                            if(ShopOwner==""){cogoToast.error('Shop owner  Empty');}else{
                                if(Mobile==""){cogoToast.error('Mobile  Empty');}else{

                                        setBtnSave("secondary")
                                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                                        if(result==1){setBtnSave("success");cogoToast.success("Customer saved successfully")}else{
                                            setBtnSave("success");cogoToast.error("Customer not saved")}
                                    }).catch(error=>{
                                        cogoToast.error(error);setBtnSave("success")
                                    });



                                }} } }}}}

    }



    return (

        <Fragment>
            <div className="purchaseDiv text-center">
                <Nav.Link className="navItem "><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                <h3>New Customer  Entry Form</h3>
                <hr/>

                <Container>
                    <Row>
                        <Col lg={3} md={3} sm={12}>
                            <Table>
                                <tr>
                                    <td>
                                        <select className="float-start w-100 customfontsize" id="cmbDistrict"  onChange={getUpazila} >
                                            <option value="0">-Select District-</option>

                                            {


                                                state.district.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                    )
                                                })

                                            }


                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select className="float-start w-100 customfontsize" id="cmbUpazila" onChange={getUnion}>
                                            <option value="0">-Select Upazila-</option>

                                            {
                                                Upazila.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select className="float-start w-100 customfontsize" id="cmbUnion"  onChange={getBazar}  >
                                            <option>-Select Union-</option>

                                            {

                                                Union.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                    )
                                                })

                                            }


                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select className="float-start w-100 customfontsize" id="cmbBazar"  >
                                            <option>-Select Bazar-</option>

                                            {

                                                Bazar.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                    )
                                                })

                                            }


                                        </select>
                                    </td>
                                </tr>

                            </Table>

                        </Col>
                        <Col lg={3}>
                            <Row>
                                <label className="customfontsize">Shop name</label>
                                <input id="txtShopName" type="text" className="w-100 customfontsize"/>
                            </Row>
                            <Row>
                                <label className="customfontsize">Shop Owner</label>
                                <input id="txtShopOwner" type="text" className="w-100  customfontsize"/>
                            </Row>
                            <Row>
                                <label className="customfontsize">Mobile No</label>
                                <input id="txtMobile" type="text" className="w-100  customfontsize"/>
                            </Row>

                            <Row >
                                <Col lg={6} md={6} sm={12}><Button onClick={saveNewCustomer} variant={BtnSave} className="w-100 mt-4">Save</Button></Col>

                            </Row>

                        </Col>


                    </Row>

                </Container>

            </div>

        </Fragment>
    );

}