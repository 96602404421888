import {Fragment, useState} from "react";
import {Button, Col, Container, Row, Table,Nav} from "react-bootstrap";

import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
export default function Bazar(){
    let state=useSelector((state)=> state);
    const [Upazila, setUpazila]=useState([]);
    const [Union, setUnion]=useState([]);
    const [BtnSave, setBtnSave]=useState("success");


   const getUpazila=()=>{
        const e = document.getElementById("cmbDistrict");
        const value = e.options[e.selectedIndex].value;
        const dt=state.upazila.filter(x => x.district_id==value);
        setUpazila(dt)
        setUnion([])

    }
   const getUnion=()=>{
        const e = document.getElementById("cmbUpazila");
        const value = e.options[e.selectedIndex].value;
        const dt=state.union.filter(x => x.upazila_id==value);
        setUnion(dt)}
    const SaveNewBazar=()=>{
        const district = document.getElementById("cmbDistrict");

        const upazila = document.getElementById("cmbUpazila");

        const Union = document.getElementById("cmbUnion");
        const unionId = Union.options[Union.selectedIndex].value;


        let bazar = document.getElementById("txtBazar");

        let bazarName=bazar.value;




        let jsonObject=
            {
                "sql":"INSERT INTO `Bazar_Tbl` (`id`, `union_id`, `name`) VALUES (NULL, '"+unionId+"', '"+bazarName+"') "

            };

        if(district.selectedIndex==0){cogoToast.error('Please Select District');district.focus()}else{
            if(upazila.selectedIndex==0){cogoToast.error('Please Select Upazila');upazila.focus()}else{
                if(Union.selectedIndex==0){cogoToast.error('Please Select Union');Union.focus()}else{
                    if(bazarName==""){cogoToast.error('Bazar  Empty');bazar.focus()}else{
                        setBtnSave("secondary");
                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                           if(result==1){cogoToast.success("Bazar Saved Successfully");setBtnSave("success");}else{
                               cogoToast.error("Bazar not Saved");setBtnSave("success");
                           }

                        }).catch(error=>{
                            cogoToast.error("Data not saved");setBtnSave("success");
                        });


                    }} } }


    }

    return (
        <Fragment>
            <div className="purchaseDiv text-center">
                <Nav.Link ><NavLink to="/home"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                <h3>New Bazar  Entry Form</h3>
                <hr/>

                <Container>
                    <Row>
                        <Col lg={3} md={3} sm={12}>
                            <Table>
                                <tr>
                                    <td>
                                        <select className="float-start w-100" id="cmbDistrict"  onChange={getUpazila} >
                                            <option value="0">-Select District-</option>

                                            {


                                                state.district.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                    )
                                                })

                                            }


                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select className="float-start w-100" id="cmbUpazila" onChange={getUnion} >
                                            <option value="0">-Select Upazila-</option>

                                            {
                                                Upazila.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select className="float-start w-100" id="cmbUnion"  >
                                            <option>-Select Union-</option>

                                            {
                                                Union.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.id}>{ctr.name}</option>
                                                    )
                                                })
                                            }


                                        </select>
                                    </td>
                                </tr>

                            </Table>

                        </Col>
                        <Col lg={3}>
                            <label className="customfontsize">Bazar name</label>
                            <input type="text" className="w-100 customfontsize" id="txtBazar"/>
                            <Row >
                                <Col lg={6} md={6} sm={12}><Button onClick={SaveNewBazar}  variant={BtnSave} className="w-100 mt-4">Save</Button></Col>

                            </Row>

                        </Col>


                    </Row>

                </Container>

            </div>






        </Fragment>
    );

}