import {Fragment, useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";


export default function DealerDetailes(){

    const [DateFrom, setDateFrom] = useState(new Date());
    const [DateTo, setDateTo] = useState(new Date());


    const [TotalDelivery,setTotalDelivery]=useState()
    const [TotalPayment,setTotalPayment]=useState()
    const [TotalInvoice,setTotalInvoice]=useState()
    const [TotalBankAmount,setTotalBankAmount]=useState()



    const[DataBankPayment, setDataBankPayment]=useState([]);
    const[ColumBankPayment, setColumnBankPayment]=useState([{}]);



    const[DataDelivery, setDataDelivery]=useState([]);
    const[ColumnDelivery, setColumnDelivery]=useState([{}]);

    const[DataInvoice, setDataInvoice]=useState([]);
    const[ColumnInvoice, setColumnInvoice]=useState([{}]);

    const[DataPayment, setDataPayment]=useState([]);
    const[ColumnPayment, setColumnPayment]=useState([{}]);


    const [Dealer, setDealer]=useState([]);
    const getDealer=()=>{
        let jsonObject={"sql":"select Id, Name, Address, Mobile from Dealer Order by Name ASC"};

        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{setDealer(result)}).catch(error=>{cogoToast.error(error);});
    }

    useEffect(() => {
        getDealer();
    }, []);




    const getDelivery=()=>{

        let EntryDateFrom= AppUtility.getDate(DateFrom);
        let EntryDateTo= AppUtility.getDate(DateTo);


        const Dealer=document.getElementById("cmbDealerName");
        const DealerName=Dealer.options[Dealer.selectedIndex].text;
        let getDealer="";
        if(Dealer.selectedIndex !=0){getDealer=" And Dealer='"+DealerName+"' "}

        const jsonObject={"sql":"SELECT * FROM `Delivery`  where  Entry_date Between '"+EntryDateFrom+"' and '"+EntryDateTo+"' "+getDealer+" "}
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=> {


            if (result == null) {
                cogoToast.error("Data not found")
            } else {
                setColumnDelivery(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setDataDelivery(result)
                let sum=0
                for (let i = 0; i < result.length; i++) {
                    sum +=parseInt( result[i]["TQ"])
                }

                setTotalDelivery(sum)
            }


        }).catch(error=>{ cogoToast.error("Data not Found")});

    }
    const getInvoice=()=>{
        let EntryDateFrom= AppUtility.getDate(DateFrom);
        let EntryDateTo= AppUtility.getDate(DateTo);


        const Dealer=document.getElementById("cmbDealerName");
        const DealerName=Dealer.options[Dealer.selectedIndex].text;

        let getDealer="";
        if(Dealer.selectedIndex !=0){getDealer=" And Dealer='"+DealerName+"' "}

        const jsonObject={"sql":"SELECT * FROM `Invoices`  where  Entry_date Between '"+EntryDateFrom+"' and '"+EntryDateTo+"' "+getDealer+" "}
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=> {


            if (result == null) {
                cogoToast.error("Data not found")
            } else {
                setColumnInvoice(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setDataInvoice(result)
                let sum=0
                for (let i = 0; i < result.length; i++) {
                    sum += result[i]["Total_Amount"]
                }

                setTotalInvoice(sum)
            }


        }).catch(error=>{ cogoToast.error("Data not Found")});

    }
    const getPayment=()=>{
        let EntryDateFrom= AppUtility.getDate(DateFrom);
        let EntryDateTo= AppUtility.getDate(DateTo);


        const Dealer=document.getElementById("cmbDealerName");
        const DealerName=Dealer.options[Dealer.selectedIndex].text;
        let getDealer="";
        if(Dealer.selectedIndex !=0){getDealer=" And Dealer='"+DealerName+"' "}


        const jsonObject={"sql":"SELECT * FROM `Invoice_Payment` where  Entry_date Between '"+EntryDateFrom+"' and '"+EntryDateTo+"' "+getDealer+" "}
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=> {


            if (result == null) {
                cogoToast.error("Data not found")
            } else {
                setColumnPayment(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setDataPayment(result)
                let sum=0
                for (let i = 0; i < result.length; i++) {
                    sum += result[i]["Amount"]
                }

                setTotalPayment(sum)


            }


        }).catch(error=>{ cogoToast.error("Data not Found")});

    }
    const getBankPayment=()=>{
        let EntryDateFrom= AppUtility.getDate(DateFrom);
        let EntryDateTo= AppUtility.getDate(DateTo);



        const jsonObject={"sql":"SELECT * FROM `Accounts` where Type='Sales' and Entry_date Between '"+EntryDateFrom+"' and '"+EntryDateTo+"' "}
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=> {


            if (result == null) {
                cogoToast.error("Data not found")
            } else {
                setColumnBankPayment(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                setDataBankPayment(result)

                let sum=0
                for (let i = 0; i < result.length; i++) {
                    sum += parseInt(result[i]["Amount"])
                }

                setTotalBankAmount(sum)


            }


        }).catch(error=>{ cogoToast.error("Data not Found")});

    }


    return(

        <Fragment>
            <Container>
                <Row>
                    <Col lg={2}>
                        <select className=" w-75 float-right" id="cmbDealerName"  >
                            <option value="0">-Select Type-</option>
                            {


                                Dealer.map((ctr,index)=>{
                                    return (
                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                    )
                                })

                            }
                        </select>

                    </Col>

                    <Col lg={2}>
                        <label>Date From</label>
                    </Col>
                    <Col lg={2}>
                        <DatePicker selected={DateFrom}
                                    onChange={(date) =>setDateFrom(date)}
                                    dateFormat='dd-MM-yyyy'
                                    seletable
                                    maxDate={new Date()}
                                    isClearable
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                        />
                    </Col>


                    <Col lg={2}>
                        <label >Date To</label>
                    </Col>
                    <Col lg={2}>

                        <DatePicker selected={DateTo}
                                    onChange={(date) =>setDateTo(date)}
                                    dateFormat='dd-MM-yyyy'
                                    seletable
                                    maxDate={new Date()}
                                    isClearable
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                        />
                    </Col>



                </Row>




                <Row>
                    <Col lg={12}>
                        <div className="purchaseDiv overflow-scroll">
                            <hr/> <label className="font-weight-bold text-danger fs-4">Delivery Table</label> <Button onClick={getDelivery}>Delivery</Button>
                            <BootstrapTable  keyField='Id' data={DataDelivery} columns={ColumnDelivery}  > </BootstrapTable>
                            <label>Total Carton : {TotalDelivery}</label>
                           <hr/>   <label className="font-weight-bold text-danger fs-4">Invoice Table</label><Button onClick={getInvoice}>Invoice</Button>
                            <BootstrapTable  keyField='Invoice_No' data={DataInvoice} columns={ColumnInvoice}  > </BootstrapTable>
                            <label>Total Invoice Taka : {TotalInvoice}</label>
                            <hr/> <label className="font-weight-bold text-danger fs-4">Payment Table</label> <Button onClick={getPayment}>Payment</Button>
                            <BootstrapTable  keyField='Payment_No' data={DataPayment} columns={ColumnPayment} > </BootstrapTable>
                            <label>Total Taka Paid : {TotalPayment}</label>
                            <hr/> <label className="font-weight-bold text-danger fs-4">Bank Payment Table</label> <Button onClick={getBankPayment}>Bank Payment</Button>
                            <BootstrapTable  keyField='Id' data={DataBankPayment} columns={ColumBankPayment} > </BootstrapTable>

                            <label>Total Bank Taka Deposit : {TotalBankAmount}</label>
                        </div>
                    </Col>

                </Row>
            </Container>

        </Fragment>

    );
}
