import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Invoice from "../components/Invoice/Invoice";
import {Button, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Expenditure from "../components/Accounts/Expenditure";
import ExpensesPayment from "../components/Accounts/ExpensesPayment";
import InvoicePayment from "../components/Invoice/InvoicePayment";
import InvoiceWriteOff from "../components/Invoice/InvoiceWriteOff";
import DealerDetailes from "../components/Invoice/DealerDetailes";

export default function InvoicePage(){

    return(
        <Fragment>
            <TopNavigation />
            <div className="purchaseDiv text-center">
                <Nav className="navItem float-end"><NavLink to="/home"><Button variant="warning" >Close</Button></NavLink></Nav>
                <h3>Accounts management</h3>
                <hr/>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="invoice" title="New Invoice"><Invoice/></Tab>
                    <Tab eventKey="expense" title="Payment"><InvoicePayment/></Tab>
                    <Tab eventKey="invoiceOff" title="Invoice WriteOff"><InvoiceWriteOff/></Tab>
                    <Tab eventKey="dealerDetailes" title="Dealer Report"><DealerDetailes/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}
