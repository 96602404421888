import {Fragment, useEffect, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";


export default function ChemicalUse(){
    let state=useSelector((state)=> state);
    const [Data, setData]=useState([]);
    const [Columns, setColumns]=useState([{}]);
    const [ChemicalNo, setChemicalNo]=useState();
    const [EntryQty, setEntryQty]=useState();
    const [UseQty, setUseQty]= useState();
    const [LeftQty, setLeftQty]= useState();
    const [SelectKeyField, setSelectKeyField]=useState("Chemical_No");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    let ColumnsEntry=[
        {dataField: 'Chemical_No', text: 'Chemical_No', sort: true},
        {dataField: 'Qty', text: 'Qty', sort: true},
        {dataField: 'Drum_Colour', text: 'Drum_Colour', sort: true},
        {dataField: 'Entry_Date', text: 'Entry_Date', sort: true},
    ]
    let ColumnsUse=[
        {dataField: 'Production_No', text: 'Production_No', sort: true},
        {dataField: 'Chemical_Unit', text: 'Chemical_Unit', sort: true},
        {dataField: 'Chemical_No', text: 'Chemical_No', sort: true},
        {dataField: 'Chemical_Qty', text: 'Chemical_Qty', sort: true},
        {dataField: 'Use_Type', text: 'Use_Type', sort: true},
        {dataField: 'Total_Batch', text: 'Total_Batch', sort: true},
        {dataField: 'Entry_Date', text: 'Entry_Date', sort: true},
    ]
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{

            let Chemical_No=row['Chemical_No']
            setChemicalNo(Chemical_No);
            let ReportType = document.getElementById("cmbReportType").selectedIndex;

                if(ReportType==0){

            RestClient.PostRequest(AppUrl.getData, {"sql":"SELECT Qty FROM `Chemical_Entry` where Chemical_No='"+Chemical_No+"'  "}).then(result=>{
                if(result!=null){ setEntryQty(result[0]["Qty"]);  }else{cogoToast.error("No Data Found"); }

            }).catch(error=>{});

            RestClient.PostRequest(AppUrl.getData, {"sql":" SELECT Sum(Chemical_Qty)Qty FROM `Chemical_Use` where Chemical_No='"+Chemical_No+"' "}).then(result=>{
                if(result!=null){setUseQty(result[0]["Qty"]);handleShow();}else{cogoToast.error("No Data Found"); }

            }).catch(error=>{});

                    let lftQty=EntryQty-UseQty
                    setLeftQty(lftQty)


        }}
    }
    const getChemicalStock=()=>{

        const ReportType = document.getElementById("cmbReportType").selectedIndex;


        let jsonObject="";
        if(ReportType==0){
             jsonObject={"sql":"SELECT Chemical_No, Qty,Drum_Colour,Entry_Date FROM `Chemical_Entry` where Status='1'" };
            setColumns(ColumnsEntry)
            setData([])
            setSelectKeyField("Chemical_No")
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                setData(result)
            }).catch(error=>{ cogoToast.error(error);});

        } else{
             jsonObject={"sql":"SELECT * FROM `Chemical_Use`" };
            setColumns(ColumnsUse)
            setData([])
            setSelectKeyField("Production_No")
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
                setData(result)
            }).catch(error=>{ cogoToast.error(error);});


        }



    }
    const changePendingStatus=()=>{

        const EntryQty=document.getElementById("txtEntryQty").value;
        const UseQty=document.getElementById("txtUseQty").value;

        if(parseInt(EntryQty)== parseInt(UseQty)){
            RestClient.PostRequest(AppUrl.updateData, {"sql":"UPDATE `Chemical_Entry` SET `Status`='0' WHERE Chemical_No='"+ChemicalNo+"'"}).then(result=>{

                if(result==null){cogoToast.success("Entry Status not changed")}else{cogoToast.success("Purchased Status changed");handleClose()}
            }).catch(error=>{});

        }else{cogoToast.error("Entry and Use Qty does not matched")}

    }

    const saveUse=()=>{

        const UseType = document.getElementById("cmbUseType");
        const UseTypeValue = UseType.options[UseType.selectedIndex].text;

            const ChemicalNo=document.getElementById("txtChemicalNo").value;
            const Unit=document.getElementById("txtUnit").value;
            const Qty=document.getElementById("txtQty").value;
            const Batch=document.getElementById("txtBatch").value;
            const Comments=document.getElementById("txtComments").value;

        let jsonObjectProduction=
            {
                "sql":"INSERT INTO `Chemical_Use` (`Production_No`, `Chemical_Unit`, `Chemical_No`, `Chemical_Qty`, `Use_Type`, `Total_Batch`, `Entry_User`, `Entry_Date`)" +
                    " VALUES (NULL, '"+Unit+"', '"+ChemicalNo+"', '"+Qty+"', '"+UseTypeValue+"', '"+Batch+"', '"+state.name+"', current_timestamp()) "};


            if(UseType.selectedIndex==0){cogoToast.error("Please select Uses Type")}else{
                if(ChemicalNo==0){cogoToast.error("Please select Chemical No")}else{
                    if(Unit==0){cogoToast.error("Please select Unit")}else{
                        if(Qty==0){cogoToast.error("Please select Qty")}else{
                            if(Batch==0){cogoToast.error("Please select Batch")}else{
                                if(Comments==0){cogoToast.error("Please select Comments ")}else{
                                    RestClient.PostRequest(AppUrl.saveData,jsonObjectProduction).then(result=>{
                                        if(result==1){cogoToast.success("Data Saved")}else{cogoToast.error("Data not saved")}


                                    }).catch(error=>{
                                        cogoToast.error(error);

                                    });

                                }
                            }
                        }
                    }
                }
            }




    }
    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Container>
                    <Row>

                        <Col lg={5} md={3} sm={12}>
                            <Row className="mt-2">
                                <Col lg={12}><label className="customfontsize text-danger">Daily Chemical Use Detailes</label></Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}><label className="customfontsize">Type</label></Col>
                                <Col lg={9}><select className=" w-75 float-right" id="cmbUseType"  >
                                    <option value="0">-Select Type-</option>
                                    <option value="0">Pesting</option>
                                    <option value="0">Spray</option>

                                </select></Col>
                            </Row>
                            <Row className="mt-2">

                                <Col lg={3}><label className="customfontsize">Chemical_No</label></Col>
                                <Col lg={3}><input id="txtChemicalNo"  type="text" className="w-100 customfontsize"/></Col>
                                <Col lg={3}><label className="customfontsize">Unit</label></Col>
                                <Col lg={3}><input id="txtUnit" type="text" className="w-100 customfontsize"/></Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}><label className="customfontsize">Total Batch</label></Col>
                                <Col lg={3}><input id="txtBatch" type="text"  className="w-100 customfontsize"/></Col>
                                <Col lg={3}> <label className="customfontsize">Total Qty</label></Col>
                                <Col lg={3}><input id="txtQty" type="text"  className="w-100 customfontsize"/></Col>
                            </Row>
                            <Row>
                                <label className="customfontsize">Comments</label>
                                <textarea id="txtComments" className="customfontsize"></textarea>
                            </Row>
                            <Row >
                                <Col lg={12} md={6} sm={12}><Button onClick={saveUse} className="w-100 mt-4"  >Submit</Button></Col>

                            </Row>

                        </Col>

                        <Col lg={7} md={3} sm={12}>
                            <div className="purchaseDiv text-center"> <label className="text-danger">Drying Detailes Report</label>
                                <Row>
                                    <select className="m-4 w-50 float-right" id="cmbReportType"  >

                                        <option value="0">Entry Chemical</option>
                                        <option value="0">Use Chemical</option>
                                    </select>
                                </Row>



                                <Row >
                                    <Col lg={12} md={6} sm={12}><Button onClick={getChemicalStock}  className="w-75 m-4">Report</Button></Col>
                                </Row>

                                <div id="ddlDiv" className="overflow-scroll ">
                                    <div className="tableDiv">
                                        <BootstrapTable
                                           keyField={SelectKeyField}
                                            data={Data}
                                            columns={ Columns }
                                            selectRow={selectRow}


                                        >
                                        </BootstrapTable> </div>

                                </div>

                            </div>

                        </Col>
                    </Row>

                </Container>

            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Chemical Stock Uses</Modal.Title>


                </Modal.Header>
                <Modal.Body>

                    <label >Total Stock : </label><input id="txtEntryQty" value={EntryQty}  disabled={true} type="text" className=" w-25"/>

                    <label>Total Use :</label><input id="txtUseQty"  value={UseQty} disabled={true} type="text" className=" w-25"/>

                    <label className="mt-4">Remaining Quantity </label><input   value={LeftQty} disabled={true} type="text" className="text-danger w-50"/>





                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={changePendingStatus}  >
                        Save Order
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}